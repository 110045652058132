import {useState} from "react";
import RequestLoginForm from "./RequestLoginForm";
import SuccessRequest from "../SuccessPage";

const MasterRequestLoginForm = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    function submitForm() {
    return    setIsSubmitted(true);
      
    }
    
    return ( 
        isSubmitted === false ? (
            <RequestLoginForm submitForm={submitForm} />
            ):isSubmitted === true && (
           <SuccessRequest title="Your Request to Get a New Login Was Successfully Sent"/>
        )
    );
}
 
export default MasterRequestLoginForm;