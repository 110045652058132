/// <reference path="../../navbar.js" />
import {useState, useEffect, useRef } from "react";
import { FaBars } from "react-icons/fa";
import MenuItems from "./MenuItems";
import "./Authorized.scss"
import { Link } from "react-router-dom";
import { useAuth } from "../../../../utils/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import DateFormatter from "../../../Core/DateFormatter";
import StoryblokClient from  "storyblok-js-client"
import { ParseDateTime,BoundCheck, FormattedCurrentDate,TruncateString } from "../../../../Helpers/InputHelper";

const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
const storyblokToken = process.env.REACT_APP_STORYBLOK_TOKEN;

// init with access token
const Storyblok = new StoryblokClient({
  accessToken: storyblokToken,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
  })
const AdminNav = (props) => {

    const [dropdown, setDropdown] = useState(false);
    const [AlertAnnouncement, setAlertAnnouncement] = useState([]);

    let ref = useRef();
    let auth = useAuth();
    useEffect(() => {
      Storyblok.get('cdn/stories', {
          "token": `${storyblokToken}`,
          "starts_with": "lonestar/alerts-and-announcements/",
          "version":`${storyblokVersion}`
      })
      .then(response => {
          setAlertAnnouncement(response.data.stories);
          
      }).catch(error => { 
          console.log(error)
      })
  },[])
    useEffect(() => {
      const handler = (event) => {
        if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
      };
      document.addEventListener("mousedown", handler);
      document.addEventListener("touchstart", handler);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("touchstart", handler);
      };
    }, [dropdown]);

  
    let currentDate = FormattedCurrentDate();
    const onMouseEnter = () => {
      window.innerWidth < 1000 && setDropdown(true);
    };
  
    const onMouseLeave = () => {
      window.innerWidth > 1000 && setDropdown(false);
    }; 
    const handleLogout = () => {
        auth.logout();
    }
   return ( 
   <>
    <nav className=" nav-container">
      <div className={`navigation-bar ${props.isOpen ? 'position-none' : 'position-relative'}`}>
          <div className="nav-section">
                <div className="flex-basis-5 align-items-start justify-content-start align-content-start nav-company-logo">
                           <Link to="/ProducerPortal"><img className="nav-company-logo" alt="Lonestar MGA Company Logo" src={"https://cdn.lonestarmga.com/img/lonestarLogo.svg"}/></Link>
                </div>
                <div className="flex-basis-5 flex-end">
                  <button className="nav-drawer-button " onClick={props.toggleDrawer}><FaBars /></button>
                </div>
          </div>
          <div className="nav-routes-section " ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
                {props.routes.map((route) => {
                   return route.children_links.length !== 0 ? (
                        <MenuItems items={route} depthLevel={0} />
                   ):(
                      route.link_target === "_self" ? (
                            <Link className={((route.title === "Customer Login") || (route.title === "Auto Customer Login")) ? ("enhanced") : ("menu-items")} to={route.path}>{route.title}</Link>
                      ): (
                            <a className={((route.title === "Customer Login") || (route.title === "Auto Customer Login")) ? ("enhanced") : ("menu-items")} target={route.link_target} href={route.path}>{route.title}</a>
                      )
                   )
                })}
                <span className="dropdown-hc2">
                    <Link to="/Announcements" >
                      <span className="notification-bell">__</span>
                      </Link>
                      <div style={{width:"290px"}} className="dropdown-content2">
                        {AlertAnnouncement.map((data,index) => {
                             let startDate = ParseDateTime(data.content.start_date);
                             let endDate = ParseDateTime(data.content.end_date);
                        return index < 2 && 
                              BoundCheck(startDate, endDate,currentDate) &&
                              <>
                                <Link to="/Announcements" >
                                  <div ><span className="bullet-red">&#x2022;</span>{TruncateString(data.content.alert_title,20)}</div>
                                  <div className="alert-date"><DateFormatter startDate={data.content.start_date} /></div>
                                </Link>
                              </>
                         }
                        )}
                        {
                            AlertAnnouncement.length === 0 && (
                                  <Link to="/Announcements" >
                                    <div ><span className="bullet-red">&#x2022;</span>Alerts Coming Soon</div>
                                    <div className="alert-date">Tbd...</div>
                                  </Link> )
                         }
                        {AlertAnnouncement.length > 2 &&(
                           <Link to="/Announcements" className="view-all-button" style={{paddingTop:"6px",height:"40px",fontSize:"14px"}}>View All</Link>
                        )}
                      </div> 
                </span>
                <span className="nav-grey-divider"> | </span>
                   </div>
                <div className="auth-dropdown-container">
                       <button className="auth-dropdown">Welcome <span><FontAwesomeIcon className="arrow" icon={faSortDown} /></span>
                            <div><span className="admin-nav-label">Admin</span></div>
                       </button>
                       <div className="dropdown-content">
                           <button><Link to='/AddProducer'>Add Producer/ Check Producer</Link></button>
                           <button onClick={handleLogout}>Logout </button>
                       </div>
                </div>
           </div>
        </nav>
    </>
   )
}
export default AdminNav;