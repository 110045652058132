import { useStoryblok } from "@storyblok/react";
import { useAuth } from "../../utils/AuthProvider";
import { useState} from "react";
import Drawer from "./Drawer";
import ProducerDrawer from "./NavViews/Producer/Drawer";
import AdminDrawer from "./NavViews/Admin/Drawer";
import Navigation from "./NavViews/Visitor/Navigation";
import ProducerNav from "./NavViews/Producer/ProducerNav";
import AdminNav from "./NavViews/Admin/AdminNav";
import "./Navbar.scss"

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
    const slug = "lonestar/navigation";
    const auth = useAuth();
    const story = useStoryblok(slug, { version: storyblokVersion });

    if(!story?.content){
        return <div></div>
    }
    let data = story.content?.navigationLinks;
    const toggleDrawer = () => {
            setIsOpen(!isOpen);
     };
  return (
      <>
          {
              ((auth.NavigationView) === null || (auth.NavigationView <= -1))  ? (
                  <>
                        <Drawer view={["member","only_visitors"]} routes={data} isOpen={isOpen} toggleDrawer={toggleDrawer} />
                        <Navigation routes={data} isOpen={isOpen} toggleDrawer={toggleDrawer} />
                  </>
            ):(auth.NavigationView > -1 || auth.NavigationView !== null) ? (
                     (( localStorage.getItem("DATA").includes("ADMIN") && !JSON.parse(localStorage.getItem("DATA")).rtnval?.requirePasswordChange )  )? (
                            <>
                                <AdminDrawer view={["admin","member","producer"]} routes={data} isOpen={isOpen} toggleDrawer={toggleDrawer} />
                                <AdminNav routes={data} isOpen={isOpen} toggleDrawer={toggleDrawer}  /> 
                            </>
                      ) : (localStorage.getItem("DATA").includes("PRODUCER") && !JSON.parse(localStorage.getItem("DATA")).rtnval?.requirePasswordChange) ?(
                        <>
                          <ProducerDrawer view={["producer","member"]}routes={data} isOpen={isOpen} toggleDrawer={toggleDrawer} />
                          <ProducerNav routes={data} isOpen={isOpen} toggleDrawer={toggleDrawer}  /> 
                        </>
                          ) : ((localStorage.getItem("DATA").includes("ADMIN") || localStorage.getItem("DATA").includes("PRODUCER"))  && ( JSON.parse(localStorage.getItem("DATA")).rtnval?.requirePasswordChange))&&(
                              <>     
                                    <Drawer view={["member","only_visitor"]} routes={data} isOpen={isOpen} toggleDrawer={toggleDrawer} />
                                     <Navigation routes={data} isOpen={isOpen} toggleDrawer={toggleDrawer} />
                              </>
                          )
              ) : (
                    <>
                        <Drawer view={["member","only_visitor"]} routes={data} isOpen={isOpen} toggleDrawer={toggleDrawer} />
                        <Navigation routes={data} isOpen={isOpen}  toggleDrawer={toggleDrawer} />
                    </>
             )
        }
    </>
  )
}

export default Navbar;