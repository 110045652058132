import React from "react"
import Buttons from "./Buttons"

const ButtonConfig = ({data,button}) => {
    return ( 
        <>
         {data.map((items)=> {
                    return (
                        <div className=" no-gutters justify-content-center">
                            {items.headline && <div className={`mb-3 btn-config-header ${items.header_style} `} style={{ textAlign: `${items.headline_alignment}` }}>{items.headline}</div>}
                            {items.sub_headline && <h5>{items.sub_headline}</h5>}
                            <Buttons data={button} config={items.default_button_style}/>
                        </div>)               
                    })}
        </> 
    )
}
 
export default ButtonConfig 