const PolicyHolderCompany = [
    {
        index:0,
        label:"- Select -",
        value:"null"
    },
    {
        index:1,
        label:"First Chicago Insurance Company (policy prefix TXM)",
        value:"TXM"
    },{
        index:2,
        label:"Homestate County Mutual Insurance Company (policy prefix TPR)",
        value:"TPR"
    },{
        index:3,   
        label:"Old American County Mutual Insurance Company (policy prefix LSM or LSA)",
        value:"LSM/LSA"
    }
]
export default PolicyHolderCompany;