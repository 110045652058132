import MenuItems from "./MenuItems";
import '../../Navbar.scss';

const Dropdown = ({ submenus, dropdown, depthLevel }) => {

  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "dropdown";

  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu,index) => (
        (submenu.page_visibility.filter((results) => results === "member" || results === "only_visitors").length > 0) && (

       <div className="dropdown-item" key={index}> 
             <MenuItems items={submenu} depthLevel={depthLevel} />
          <br/>
       </div> 
        )
      ))}
    </ul>
  );
};

export default Dropdown;