
import { NODE_LI, NODE_PARAGRAPH, NODE_UL } from "storyblok-rich-text-react-renderer";
import { render } from 'storyblok-rich-text-react-renderer-ts';
import RichText from "./Core/RichText";
const SimpleMUCText = ({ data }) => {
    let Description = data.text_body;
    return (

        <div className="row offset-lg-1 pt-2">
            {data?.heading !== "" && <div className="col-lg-12"><RichText Description={data.header} ></RichText></div>}
            <div className="col-lg-12" style={{ fontFamily: "Poppins-Regular", fontSize: "15px" }}>
                {
                    render(Description, {
                        nodeResolvers: {
                            [NODE_LI]: (Description) => <><li>{Description}</li><br /></>,
                            [NODE_UL]: (Description) => <ul className="media-list">{Description}</ul>,
                            [NODE_PARAGRAPH]: (Description) => <p>{Description}</p>
                        }
                    })
                }
            </div><br />

        </div>


    );
}

export default SimpleMUCText;