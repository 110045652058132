import React, { useEffect, useState,useRef } from "react"
import Accordion from "./FAQobject";
import StoryblokClient from  "storyblok-js-client"
import { Link } from "react-router-dom";
import { LS_PHONENUMBER_FORMAT,LS_PHONENUMBER_UNFORMAT } from "../utils/Global";
const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
const storybloktoken = process.env.REACT_APP_STORYBLOK_TOKEN;
const Storyblok = new StoryblokClient({
    accessToken: storybloktoken,
    cache: {
      clear: 'auto',
      type: 'memory'
    }
    })
const FAQ = ({page}) => {

    const [FAQobject, setFAQobject] = useState([]);

    let ClaimsFAQS = [];
    var Claim_NoFAQS = useRef(false);

    let ProducerFAQS = [];
    var Producer_NoFAQS = useRef(false);

    let PaymentFAQS = [];
    var Payment_NoFAQS = useRef(false);

    let CustomerFAQS=[]
    var Customer_NoFAQS = useRef(false);

    let Category = [];
    
  
    useEffect (() => {
        Storyblok.get('cdn/stories', {
            "token": storybloktoken,
            "starts_with": "lonestar/faqs/",
            "version":storyblokVersion
        })
        .then(response => {
            setFAQobject(response.data.stories)
        }).catch(error => { 
            console.log(error)
        })
      },[])
   
      FAQobject.map((items) => {
                Category.push(items.content)
      })    

    return ( <>

        {
            page === "Payment" && (
            <>
                     {Category.map((items) => {
                        return(
                            items.component === "FAQ_Article" && (
                            <>
                            {
                                items.Category.filter(page => {
                                (page === "payment") && (
                                        PaymentFAQS.push(1)
                                )
                                })
                            }    
                            {
                                PaymentFAQS.length <= 0 ?(
                                    Payment_NoFAQS.current = true
                                ):PaymentFAQS.length > 0 && (
                                    Payment_NoFAQS.current = false,

                                    items.Category.map((page) => {
                                          return ( page === "payment" && 
                                            <> 
                                                <Accordion faq={items} />
                                            </>
                                          )
                                            })
                                )
                            }
                        
                            </>
                        ))
                        })}             
            </>
            )  
        }
        {
            page === "Contact" && (
                <>
                {Category.map((items) => {
                                return(
                                    items.component === "FAQ_Article" && (
                                    <>
                                    {
                                        items.Category.filter(page => {
                                        (page === "customer") && (
                                                CustomerFAQS.push(1)
                                        )
                                        })
                                    }    
                                    {
                                        CustomerFAQS.length <= 0 ?(
                                            Customer_NoFAQS.current = true
                                        ):CustomerFAQS.length > 0 && (
                                            Customer_NoFAQS.current = false,

                                            items.Category.map((page) => {
                                                return ( page === "customer" && 
                                                    <> 
                                                        <Accordion faq={items} />
                                                    </>
                                                )
                                                    })
                                        )
                                    }
                                
                                    </>
                                ))
                                })}             
            
                        </>
                    )  
        }
        {

page === "Customer" && (
    <>
        {Category.map((items) => {
                        return(
                            items.component === "FAQ_Article" && (
                            <>
                            {
                                items.Category.filter(page => {
                                (page === "customer") && (
                                        CustomerFAQS.push(1)
                                )
                                })
                            }    
                            {
                                CustomerFAQS.length <= 0 ?(
                                    Customer_NoFAQS.current = true
                                ):CustomerFAQS.length > 0 && (
                                    Customer_NoFAQS.current = false,

                                    items.Category.map((page) => {
                                          return ( page === "customer" && 
                                            <> 
                                                <Accordion faq={items} />
                                            </>
                                          )
                                            })
                                )
                            }
                        
                            </>
                        ))
                        })}             
    </>
)  
        }
        {
                page === "Claims" && (
                    <>
                        {Category.map((items) => {
                        return(
                            items.component === "FAQ_Article" && (
                            <>
                            {
                                items.Category.filter(page => {
                                (page === "claims") && (
                                        ClaimsFAQS.push(1)
                                )
                                })
                            }    
                            {
                                ClaimsFAQS.length <= 0 ?(
                                    Claim_NoFAQS.current = true
                                ):ClaimsFAQS.length > 0 && (
                                    Claim_NoFAQS.current = false,
                                    items.Category.map((page) => {
                                        return ( page === "claims" && 
                                          <> 
                                              <Accordion faq={items} />
                                          </>
                                        )
                                          })
                                )
                            }
                        
                            </>
                        ))
                        })}       
                    </>
                )  

        }
     {
                page === "Producer" && (
                    <>
                        {Category.map((items) => {
                        return(
                            items.component === "FAQ_Article" && (
                            <>
                            {
                                items.Category.filter(page => {
                                (page === "producer") && (
                                        ProducerFAQS.push(1)
                                )
                                })
                            }    
                            {
                                ProducerFAQS.length <= 0 ?(
                                    Producer_NoFAQS.current = true
                                ):ProducerFAQS.length > 0 && (
                                    Producer_NoFAQS.current = false,
                                    items.Category.map((page) => {
                                        return ( page === "producer" && 
                                          <> 
                                              <Accordion faq={items} />
                                          </>
                                        )
                                          })
                                )
                            }
                        
                            </>
                        ))
                        })}       
                    </>
                )  

        }
        {
        
            Claim_NoFAQS.current === true && 
            <>
                <div className="main-announcement-body">
                    <div className="filler-faq-title">More Content Coming Soon</div>
                    <p className="filler-faq-description">In the meantime, we can answer your questions about claims by:</p>
                    <div className="filler-faq-bullets">
                        <ul className="media-list">
                            <li className="filler-faq-bullets">Calling our phone number at &nbsp;<a className="pl-2 RichTxt-Hyperlink" href={`tel:2145708358`} >(214) 570-8358</a></li>
                            <li className="filler-faq-bullets">Sending your questions online through our &nbsp;<Link className="RichTxt-Hyperlink" to="/ContactUs">Contact Us Form</Link></li>

                        </ul>
                    </div>
                </div>
            </>
        }
        {Payment_NoFAQS.current === true  && 
          <>
                <div className="main-announcement-body">
                    <div className="filler-faq-title" >More Content Coming Soon</div>
                    <p className="filler-faq-description">In the meantime, we can answer your questions about payments by:</p>
                    <div className="filler-faq-bullets">
                        <ul className="media-list">
                            <li className="filler-faq-bullets">Calling our phone number at &nbsp;<a className="pl-2 RichTxt-Hyperlink" href={`tel:${LS_PHONENUMBER_UNFORMAT}`} >{LS_PHONENUMBER_FORMAT}</a></li>
                            <li className="filler-faq-bullets">Sending your questions online through our &nbsp;<Link className="RichTxt-Hyperlink" to="/ContactUs">Contact Us Form</Link></li>

                        </ul>
                    </div>
                </div>
            </>
        }
         {Producer_NoFAQS.current === true && 
          <>
                <div className="main-announcement-body">
                    <div className="filler-faq-title">More Content Coming Soon</div>
                    <p className="filler-faq-description">In the meantime, we can answer your questions by:</p>
                    <div className="filler-faq-bullets">
                        <ul className="media-list">
                        <li className="filler-faq-bullets">Calling our phone number at &nbsp;<a className="pl-2 RichTxt-Hyperlink" href={`tel:2145708358`} >(214) 570-8358</a></li>
                            <li className="filler-faq-bullets">Sending your questions online through our &nbsp;<Link className="RichTxt-Hyperlink" to="/ContactUs">Contact Us Form</Link></li>

                        </ul>
                    </div>
                </div>
            </>
        }
        {
            Customer_NoFAQS.current === true && 
            <>
                  <div className="main-announcement-body">
                      <div className="filler-faq-title">More Content Coming Soon</div>
                      <p className="filler-faq-description">In the meantime, we can answer your questions by:</p>
                      <div className="filler-faq-bullets">
                          <ul className="media-list">
                            <li className="filler-faq-bullets">Calling our phone number at &nbsp;<a className="pl-2 RichTxt-Hyperlink" href={`tel:2145708358`} >(214) 570-8358</a></li>
                              <li className="filler-faq-bullets">Sending your questions online through our &nbsp;<Link className="RichTxt-Hyperlink" to="/ContactUs">Contact Us Form</Link></li>
  
                          </ul>
                      </div>
                  </div>
              </>
        }
    </>
    )
}
 
export default FAQ