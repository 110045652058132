import { useStoryblok } from "@storyblok/react";

const Address = ({data}) => {
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const slug = "lonestar/lonestar-address-info";
    const story = useStoryblok(slug, { version: storyblokVersion });

    if(!story?.content){
        return <div></div>
    }
 


    return (
            
                data === story.uuid ? (
            <>
                <div className="">{story.content.company_name}</div>
                <div className="py-2">{story.content.address_line_1} {story.content.address_line_2} </div>
                <div>{story.content.city}, {story.content.state} {story.content.zip_code}</div>
                    
                </>) : (<>{data}</>) 

           
            
    )

}
 
export default Address