
import RichText from "../Core/RichText"
import ReactTooltip from "react-tooltip";
import HoursOfOperationGlobal from "./HoursOfOperationGlobal";
import ClaimBusinessHoursGlobal from "./ClaimBusinessHoursGlobal";
import PolicyHoursOfOperationGlobal from "./PolicyServiceHourofOperation";
import ReportHoursOfOperationGlobal from "./ReportNewClaimHours";
export default function SimpleTextSB({ blok }) {
   
    return (
        <div>
            <div className="pb-3">
                {blok.subheadline}
            </div>
            <div className="pb-3 simple-headline">
                {blok.headline }
            </div>
            <div className="pb-3">
                {blok.description && <div>
                    <RichText Description={blok?.description} />
                </div>}
            </div>
            <div>
                {blok.help_text && <div>
                    <div className="flex-start align-items-center py-2 business-hours">
                        <RichText Description={blok?.help_text} />
                           
                                <span className="help-icon" data-tip data-for={`registerTip${blok._uid}`} ></span>
                                <ReactTooltip id={`registerTip${blok._uid}`} place="top" className="tooltip" type="light" effect="solid">
                                    <div className="pt-2">
                                        {
                                            blok?.claim_hours === true? <ClaimBusinessHoursGlobal /> : blok?.policy_hours === true ? <PolicyHoursOfOperationGlobal /> : blok?.report_hours === true ? <ReportHoursOfOperationGlobal /> : <HoursOfOperationGlobal />
                                        }
                                    </div>
                                </ReactTooltip>
                          
                        

                        
                    </div>
                </div>}
            </div>
    </div>
  )
}
