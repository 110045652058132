/*
    limit()
    Limit takes in an input value, and a desired number
    to restrict user input on a field 
    Example: Will allow 2023
    onKeyUP = 3     
    onKeyDown = 2   
*/
export function limit(e,max_value) {
    var max_char = max_value;

    if(e.target.value.length > max_char){
          e.target.value = e.target.value.substr(0, max_char);
     }
}

export function acceptableYear(e) {
    var epoch = new Date(2016,2,1); // Will these be user input need to make sure evaluated as
    var now   = new Date();         // Create a date for the current instant
    now.setHours(0,0,0,0);          // Set time to 00:00:00.000
    
    alert((now < epoch? 'Before':'On or after') + ' 1 March, 2016');
}

/*
    formatPhoneNumber()
    This will format the phone number to have a styled format of
    000-000-0000
*/
export function formatPhoneNumber(e){
    if (e.key !== 'Backspace' && (e.target.value.length === 3 || e.target.value.length === 7)){
        e.target.value += '-';
        }
}

export function Over16(){
    var today = new Date();
 
    return today;
}

export function TodayDate () {
    var today = new Date().toISOString().split("T")[0];

    return today
}

export function ParseDateTime(data){
    const values = data.split(' ');
    return values;
}


export function BoundCheck(start,end,current){
    if((current >= start) && (current <= end) ){
        return true
    }else{
        return false
    }
}

/*
    FormattedCurrentDate() 
    Will format the date to 
    yyyy-mm-dd
*/
export function FormattedCurrentDate(){
    let date = new Date();
    
    let [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
    month = month + 1 ;
    
    let [hour, minutes, seconds] = [date.getHours(), date.getMinutes(), date.getSeconds()];
    let correctMin = String(date.getMinutes()).padStart(2, "0");
    let correctDay= String(date.getDate()).padStart(2, "0");

    let newMonth = String(month).padStart(2,"0"); 
  
    let currentDate = `${year}-${newMonth}-${correctDay}`;

    return currentDate
}


/*
    FormatDate()
    Will take in a date string from storyblok and return a formatted date
    as mm/dd/yyyy
    Used in Download Button
*/
export function FormatDate (string){
    let [year,month,day] = string.split("-");
    let [newDay] = day.split(" "); 
    return <>{month}/ {newDay} / {year}</>
}


/*
    TruncateString()
    If the string is over x number of characters(maxLength) it will truncate the string 
    and replace with ... This is used on the announcements for the navigation 
    bar.
*/
export function TruncateString(string,maxLength){
    if (string.length > maxLength) {
        return string.substring(0, 24) + "...";
    }
    return string
}

/*
    SpaceRemove()
    Takes in string ,If the string end with a space it will remove the 
    space. This is used in the login form
*/

export function SpaceRemove(string){
    if(string?.endsWith(" ")){
        string = string.replace(/\s+$/, "");
    }
    return string;
}

/*
    LinkParse(string)
    Takes in a string.
    The purpose of this function is to properly parse the youtube url an 
    editor would enter in storyblok.
    Returns: the ending of the url. This is the unique identifier youtube uses for the video.
*/
export function LinkParse (url){
    const ID = url.split("https://youtu.be/");
     const YTID = ID[1];
    return(YTID); 
}
