import states from "../../../Data/US-states";
import PreferredMethod from "../../../Data/PreferredMethod";
import PolicyHolderCompany from "../../../Data/PolicyHolderCompany";
import "../../../Assets/Style/FormsStyle.scss"
import { limit } from "../../../Helpers/InputHelper";
export const Inputs = (props) => {

    return (
        <>
            <input className={`form-input`}  id={`${(props.required === "Yes") ? `Yes` : `No`}`} onblur={`emptyField(${props.value})`}  onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>
            <span className={` ${(props.required === "Yes") ? `errorON` : `errorOFF`}`}></span>
        </>
    )
}
export const SetInputs = (props) =>{
    if( props.value === "FilerFirstName"){
    return (<input value={props.claimProps.FilerFirstName} className="form-input" id={(((props.claimProps.FilerFirstName.length > 0) || (props.claimProps.FilerLastName.length > 0)) && (props.required === "Dependent")) ? "Yes" : "No"} onblur={`emptyField(${props.value})`}  onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>)}
  if( props.value === "FilerLastName")  
     return (<input value={props.claimProps.FilerLastName} className="form-input " id={(((props.claimProps.FilerFirstName.length > 0) || (props.claimProps.FilerLastName.length > 0)) && (props.required === "Dependent")) ? "Yes" : "No"}  onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>)
  if(props.value === "FilerCompany")
  return (<input value={props.claimProps.FilerCompany} className="form-input " id={props.required} onblur={`emptyField(${props.value})`}  onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>)
  if(props.value === "FilerEmailAddress")
  return (<input value={props.claimProps.FilerEmailAddress} className="form-input" id={(((props.claimProps.FilerFirstName.length > 0) || (props.claimProps.FilerLastName.length > 0)) && (props.required === "Dependent")) ? "Yes" : "No"} onblur={`emptyField(${props.value})`}  onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>)
  if(props.value === "PolicyNumber")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.PolicyNumber} type="text" maxLength="256" name={props.value}/>
    if(props.value === "PolicyHolderCompany")
    return (<select className={`form-input pl-1`} value={props.claimProps.PolicyHolderCompany} onChange={props.handleChange} id="YesSelectCompany" name={props.value}>
            {PolicyHolderCompany.map(items => (
            <>
                <option key={items.index} value={items.label}>{items.label}</option>
            </>
    ))}</select>
    )
    if(props.value === "PolicyHolderFirstName")
    return(<>
          <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.PolicyHolderFirstName} type="text" maxLength="256" name={props.value}/>
    </>
          )
    if(props.value === "PolicyHolderLastName")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.PolicyHolderLastName} type="text" maxLength="256" name={props.value}/>
  if(props.value === "PolicyHolderAddress")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.PolicyHolderAddress} type="text" maxLength="256" name={props.value}/>
  if(props.value === "PolicyHolderCity")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.PolicyHolderCity} type="text" maxLength="256" name={props.value}/>
  if(props.value === "PolicyHolderZIPCode")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.PolicyHolderZIPCode} type="text" maxLength="5" name={props.value}/>
  if(props.value === "PolicyHolderHomePhone")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.PolicyHolderHomePhone}  onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : "NotRequired"} type="tel" onKeyDown={(e) => props.handleInput(e)} onKeyUp={(e) => props.handleInput(e)}  maxLength="12" name={props.value}/>
  if(props.value === "PolicyHolderCellPhone")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.PolicyHolderCellPhone} onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : "NotRequired"} type="tel" onKeyUp={(e) => props.handleInput(e)}  onKeyDown={(e) => props.handleInput(e)}   maxLength="12" name={props.value}/>
  if(props.value === "PolicyHolderBusinessPhone")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.PolicyHolderBusinessPhone} onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : "NotRequired"} type="tel" onKeyUp={(e) => props.handleInput(e)} onKeyDown={(e) => props.handleInput(e)}  maxLength="12" name={props.value}/>
  if(props.value === "PolicyHolderEmailAddress")
    return <input className={`form-input pl-1`} value={props.claimProps.PolicyHolderEmailAddress} onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : props.required} type="email"  name={props.value}/>
  if(props.value === "PolicyHolderState")
    return (<select className={`${props.inputStyle} test pl-1`} value={props.claimProps.PolicyHolderState} onChange={props.handleChange} id={props.required} name={props.value}>
            {states.map(items => (
                <option key={items.index} value={items.label}>{items.label}</option>
            ))}</select>
    )
    if(props.value==="PolicyHolderMethodContact")
    return (<select className={`${props.inputStyle} pl-1`} value={props.claimProps.PolicyHolderMethodContact} onChange={props.handleChange} id="YesSelect" name={props.value}>
            {PreferredMethod.map(items => (
                <option key={items.index} value={items.label}>{items.label}</option>
            ))}</select>
    )
    if(props.value === "DriverFirstName")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.DriverFirstName} type="text" maxLength="256" name={props.value}/>
    if(props.value === "DriverLastName")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.DriverLastName} type="text" maxLength="256" name={props.value}/>
    if(props.value === "DriverAddress")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.DriverAddress} type="text" maxLength="256" name={props.value}/>
  if(props.value === "DriverCity")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.DriverCity} type="text" maxLength="256" name={props.value}/>
  if(props.value === "DriverZIPCode")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.DriverZIPCode} type="number"                                     
                   onKeyUp={((e) => limit(e,5))} onKeyDown={((e) => limit(e,4))} name={props.value}/>
  if(props.value === "DriverHomePhone")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.DriverHomePhone}  onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : "NotRequired"} type="tel" onKeyUp={(e) => props.handleInput(e)} onKeyDown={(e) => props.handleInput(e)} maxLength="12" name={props.value}/>
  if(props.value === "DriverCellPhone")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.DriverCellPhone} onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : "NotRequired"} type="tel" onKeyUp={(e) => props.handleInput(e)} onKeyDown={(e) => props.handleInput(e)} maxLength="12" name={props.value}/>
  if(props.value === "DriverBusinessPhone")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.DriverBusinessPhone} onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : "NotRequired"} type="tel" onKeyUp={(e) => props.handleInput(e)} onKeyDown={(e) => props.handleInput(e)} maxLength="12" name={props.value}/>
  if(props.value === "DriverEmailAddress")
    return <input className={`form-input pl-1`} value={props.claimProps.DriverEmailAddress} onChange={props.handleChange} id={props.required} type="email" onKeyUp={(e) => props.handleInput(e)} name={props.value}/>
    if(props.value === "DriverState")
    return ( <select className={`${props.inputStyle} pl-1`} value={props.claimProps.DriverState} onChange={props.handleChange} id={props.required} name={props.value}>
            {states.map(items => (
                <option key={items.index} value={items.label}>{items.label}</option>
            ))}
            </select>)
    if(props.value==="DriverMethodContact")
    return ( <select className={`form-input pl-1`} value={props.claimProps.DriverMethodContact} onChange={props.handleChange} id="YesSelectInsuredContact" name={props.value}>
            {PreferredMethod.map(items => (
                <option key={items.index} value={items.label}>{items.label}</option>
            ))}</select>)
  if(props.value === "OtherDriverFirstName")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.OtherDriverFirstName} type="text" maxLength="256" name={props.value}/>
    if(props.value === "OtherDriverLastName")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.OtherDriverLastName} type="text" maxLength="256" name={props.value}/>
  if(props.value === "OtherDriverAddress")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.OtherDriverAddress} type="text" maxLength="256" name={props.value}/>
  if(props.value === "OtherDriverCity")
    return  <input className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.OtherDriverCity} type="text" maxLength="256" name={props.value}/>
  if(props.value === "OtherDriverZIPCode")
    return  <input onKeyUp={((e) => limit(e,5))} onKeyDown={ ((e) => limit(e,4))} 
                     className={`${props.inputStyle} pl-1`} onblur={`emptyField(${props.value})`} onChange={props.handleChange} id={props.required} value={props.claimProps.OtherDriverZIPCode} type="number" name={props.value}/>
  if(props.value === "OtherDriverHomePhone")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.OtherDriverHomePhone}  onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : props.required} type="tel" onKeyUp={(e) => props.handleInput(e)} onKeyDown={(e) => props.handleInput(e)}  maxLength="12" name={props.value}/>
  if(props.value === "OtherDriverCellPhone")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.OtherDriverCellPhone} onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : props.required} type="tel" onKeyUp={(e) => props.handleInput(e)} onKeyDown={(e) => props.handleInput(e)} maxLength="12" name={props.value}/>
  if(props.value === "OtherDriverBusinessPhone")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.OtherDriverBusinessPhone} onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : props.required} type="tel" onKeyUp={(e) => props.handleInput(e)} onKeyDown={(e) => props.handleInput(e)} maxLength="12" name={props.value}/>
  if(props.value === "OtherDriverEmailAddress")
    return <input className={`${props.inputStyle} pl-1`} value={props.claimProps.OtherDriverEmailAddress} onChange={props.handleChange} id={props.required === "Yes" ? "Yes" : ((props.title === props.claimVals) && (props.required === "Dependent")) ? "Yes" : props.required} type="text" maxLength="256" name={props.value}/>
  if(props.value === "OtherDriverState")
    return (<select className={`${props.inputStyle} pl-1`} value={props.claimProps.OtherDriverState} onChange={props.handleChange} id={props.required} name={props.value}>
            {states.map(items => (
                <option key={items.index} value={items.label}>{items.label}</option>
            ))}</select>)
   if(props.value === "InsuredVehicleMsg")
      return <textarea className={`${props.inputStyle}`}  onChange={props.handleChange} id={props.required} value={props.claimProps.InsuredVehicleMsg} name={props.value}/>
   if(props.value === "InsuredVehicleYear")
      return <input onKeyUp={((e) => limit(e,4))} onKeyDown={ ((e) => limit(e,3))} 
                     value={props.claimProps.InsuredVehicleYear} className={`${props.inputStyle} pl-1`} id={props.required} onChange={props.handleChange} type="number" name={props.value}/>
   if(props.value === "InsuredVehicleMake")
      return <input value={props.claimProps.InsuredVehicleMake} className={`${props.inputStyle} pl-1`} id={props.required} onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>
   if(props.value === "InsuredVehicleModel")
      return <input value={props.claimProps.InsuredVehicleModel} className={`${props.inputStyle} pl-1`} id={props.required}   onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>
   if(props.value === "OtherVehicleMsg")
      return <textarea className={`${props.inputStyle}`}  onChange={props.handleChange} id={props.required} value={props.claimProps.OtherVehicleMsg} name={props.value}/>
   if(props.value === "OtherVehicleYear")
      return <input onKeyUp={((e) => limit(e,4))} onKeyDown={ ((e) => limit(e,3))} value={props.claimProps.OtherVehicleYear} 
                    className={`${props.inputStyle} pl-1`} id={props.required} onChange={props.handleChange} type="number" name={props.value}/>
   if(props.value === "OtherVehicleMake")
      return <input value={props.claimProps.OtherVehicleMake} className={`${props.inputStyle} pl-1`} id={props.required} onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>
   if(props.value === "OtherVehicleModel")
      return <input value={props.claimProps.OtherVehicleModel} className={`${props.inputStyle} pl-1`} id={props.required}   onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>
   if(props.value ==="OtherVehicleCompanyName")
      return <input value={props.claimProps.OtherVehicleCompanyName} className={`${props.inputStyle} pl-1`} id={props.required}   onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>
   if(props.value ==="OtherVehiclePolicyNumber")
      return <input value={props.claimProps.OtherVehiclePolicyNumber} className={`${props.inputStyle} pl-1`} id={props.required}   onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>
   if(props.value ==="DateOfAccident")
      return <input max={new Date().toISOString().split('T')[0]} value={props.claimProps.DateOfAccident} className={`${props.inputStyle} pl-1`} id={props.required} onChange={props.handleChange} type="date" name={props.value}/>
   if(props.value ==="TimeOfAccident")
   return <input value={props.claimProps.TimeOfAccident} className={`${props.inputStyle} pl-1`} id={props.required} onChange={props.handleChange} type="time" name={props.value}/>
   if(props.value ==="LocationOfAccident")
   return <input value={props.claimProps.LocationOfAccident} className={`${props.inputStyle} pl-1`} id={props.required} onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>
   if(props.value ==="AccidentMsg")
   return <textarea className={`${props.inputStyle}`}  onChange={props.handleChange} id={props.required} value={props.claimProps.AccidentMsg} name={props.value}/>
   if(props.value === "PoliceDepartment")
   return <input value={props.claimProps.PoliceDepartment} className={`${props.inputStyle} pl-1`} id={props.required} onChange={props.handleChange} type="text" maxLength="1024" name={props.value}/>
   if(props.value === "PoliceReportNumber")
     return <input value={props.claimProps.PoliceReportNumber} className={`${props.inputStyle} pl-1`} id={props.required} onChange={props.handleChange} type="text" maxLength="256" name={props.value}/>
 }
export const FileClaimInputData = [{
    title:"First Name",
    value:"FilerFirstName",
    required:"Dependent",
    type:"text",
    style:"col-lg-6 needed",
    inputStyle:"form-input"
}, {
        title:"Last Name",
        value:"FilerLastName",
        required:"Dependent",
        type:"text",
        style:"col-lg-6 needed",
        inputStyle:"form-input"
    },
    {
        title:"Company",
        value:"FilerCompany",
        required:"No",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input"
    },
    {
        title:"Cell Phone",
        value:"FilerPhoneNumber",
        required:"Dependent",
        type:"tel",
        style:"col-lg-6",
        inputStyle:"form-input"
    },
    {
        title:"Email Address",
        value:"FilerEmailAddress",
        required:"Dependent",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input"
    },
    {
        title:"Consent to SMS?",
        value:"SMSconsent",
        required:"Dependent",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input"
    }
];
export const PolicyHolderInputs = [
    {
        category:"Policy Holder",
    },
    {   title:"Policy Number",
        value:"PolicyNumber",
        type:"text",
        required:"Yes",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{ 
        title:"Policy Company",
        value:"PolicyHolderCompany",
        type:"select",
        required:"Yes",
        style:"col-lg-6",
        inputStyle:"form-input-option"
    },{ 
        title:"First Name",
        value:"PolicyHolderFirstName",
        type:"text",
        required:"Yes",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{ 
        title:"Last Name",
        value:"PolicyHolderLastName",
        type:"text",
        required:"Yes",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Address",
        value:"PolicyHolderAddress",
        type:"text",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"City",
        value:"PolicyHolderCity",
        type:"text",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"State",
        value:"PolicyHolderState",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input",
        type:"select"
    },{
        title:"ZIP Code",
        value:"PolicyHolderZIPCode",
        required:"No",
        type:"number",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Preferred Phone Contact",
        value:"PolicyHolderMethodContact",
        required:"Yes",
        type:"select",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Home Phone",
        value:"PolicyHolderHomePhone",
        type:"tel",
        required:"Dependent",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Cell Phone",
        value:"PolicyHolderCellPhone",
        type:"tel",
        required:"Dependent",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Business Phone",
        value:"PolicyHolderBusinessPhone",
        type:"tel",
        required:"Dependent",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Email Address",
        value:"PolicyHolderEmailAddress",
        type:"text",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },
];


export const DriverInputs = [
    {
        category:"Insured Driver"
    },{
        title:"First Name",
        value:"DriverFirstName",
        required:"Yes",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Last Name",
        value:"DriverLastName",
        required:"Yes",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Address",
        value:"DriverAddress",
        type:"text",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"City",
        value:"DriverCity",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"State",
        value:"DriverState",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input",
        type:"select"
    },{
        title:"ZIP Code",
        value:"DriverZIPCode",
        type:"number",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Preferred Phone Contact",
        required:"Yes",
        value:"DriverMethodContact",
        type:"select",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Home Phone",
        required:"Dependent",
        value:"DriverHomePhone",
        type:"tel",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Cell Phone",
        required:"Dependent",
        value:"DriverCellPhone",
        type:"tel",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Business Phone",
        required:"Dependent",
        value:"DriverBusinessPhone",
        type:"tel",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Email Address",
        required:"No",
        value:"DriverEmailAddress",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input"
    },
];


export const OtherDriverInputs = [
    {
        category:"Other Driver"
    },
    {
        title:"First Name",
        value:"OtherDriverFirstName",
        required:"No",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input"

    },{
        title:"Last Name",
        value:"OtherDriverLastName",
        required:"No",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input"

    },{
        title:"Address",
        value:"OtherDriverAddress",
        type:"text",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"City",
        value:"OtherDriverCity",
        type:"text",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"State",
        value:"OtherDriverState",
        type:"select",
        style:"col-lg-6",
        inputStyle:"form-input",
        required:"No"
    },{
        title:"ZIP Code",
        value:"OtherDriverZIPCode",
        type:"number",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Home Phone",
        value:"OtherDriverHomePhone",
        type:"tel",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Cell Phone",
        value:"OtherDriverCellPhone",
        type:"tel",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    },{
        title:"Business Phone",
        value:"OtherDriverBusinessPhone",
        type:"tel",
        required:"No",
        style:"col-lg-6",
        inputStyle:"form-input"
    }
];


export const InsuredVehiclesInputs = [
    {category:"Insured Vehicle"},
    {
        title:"Year",
        value:"InsuredVehicleYear",
        type:"number",
        style:"col-lg-4 ",
        inputStyle:"form-input ",
        required:"Yes"
    },
    {
        title:"Make",
        value:"InsuredVehicleMake",
        type:"text",
        style:" col-lg-4 ",
        inputStyle:"form-input",
        required:"Yes"
    },
    {
        title:"Model",
        value:"InsuredVehicleModel",
        type:"text",
        style:"col-lg-4",
        inputStyle:"form-input",
        required:"Yes"
    },{
        title:"Damage to Vehicle",
        value:"InsuredVehicleMsg",
        type:"textarea",
        style:"col-lg-12 claim-txt-area",
        inputStyle:"form-input claim-txt-area needed",
        required:"Yes"
    }
];

export const OtherVehicleInputs = [
    {category:"Other Vehicle"},
    {
        title:"Year",
        value:"OtherVehicleYear",
        type:"number",
        style:"col-lg-4 ",
        inputStyle:"form-input",
        required:"No"
    },
    {
        title:"Make",
        value:"OtherVehicleMake",
        type:"text",
        style:"col-lg-4",
        inputStyle:"form-input",
        required:"No"
    },
    {
        title:"Model",
        value:"OtherVehicleModel",
        type:"text",
        style:"col-lg-4",
        inputStyle:"form-input",
        required:"No"
    },{
        title:"Damage to Vehicle",
        value:"OtherVehicleMsg",
        type:"textarea",
        style:"col-lg-6 ",
        inputStyle:"form-input claim-txt-area",
        required:"No"
    },{
        title:"Is the vehicle drivable?",
        value:"OtherVehicleDrivable",
        value1:"Yes",
        value2:"No",
        type:"button",
        style:"col-lg-6  ",
        inputStyle:` mb-4 col-5 mr-4 justify-content-end contactBtn `,
        required:"No"
    },{
        title:"Insurance Company Name",
        value:"OtherVehicleCompanyName",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input",
        required:"No"
    },{
        title:"Policy Number",
        value:"OtherVehiclePolicyNumber",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input",
        required:"No"
    }
];

export const AccidentInjury = [
    {category:"Accident"},
    {
        title:"Date of Accident",
        value:"DateOfAccident",
        type:"date",
        style:"col-lg-6",
        inputStyle:"form-input-date",
        required:"Yes"
    },
    {
        title:"Time of Accident",
        value:"TimeOfAccident",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input",
        required:"Yes"
    },
    {
        title:"Location of Accident",
        value:"LocationOfAccident",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input",
        required:"Yes"
    },{
        title:"How did the accident happen?",
        value:"AccidentMsg",
        type:"textarea",
        style:"col-lg-6  ",
        inputStyle:"form-input claim-txt-area needed",
        required:"Yes"
    },{
        title:"Is the vehicle drivable?",
        value:"VehicleDrivable",
        value1:"Yes",
        value2:"No",
        type:"button",
        style:"col-lg-6",
        inputStyle:` mb-4 col-5 mr-4 contactBtn `,
        required:"No"
    },{
        title:"Was your vehicle stolen?",
        value:"VehicleStolen",
        value1:"Yes",
        value2:"No",
        type:"button",
        style:"col-lg-6  ",
        inputStyle:` mb-4 col-5 mr-4  contactBtn `,
        required:"No"
    },{
        title:"Was the theft or accident reported to the Police?",
        value:"TheftWasReported",
        value1:"Yes",
        value2:"No",
        type:"button",
        style:"col-lg-6 pt-4",
        inputStyle:` mb-4 col-5 mr-4 justify-content-end contactBtn `,
        required:"Yes"
    }
];
export const Injury = [
    {category:"Injuries"},
    {
        title:"Were there any injuries?",
        value:"Injuries",
        value1:"Yes",
        value2:"No",
        type:"button",
        style:"col-lg-6  pt-4",
        inputStyle:` mb-4 col-5 mr-4 justify-content-end contactBtn btnYes `,
        required:"Yes"
    }
];

export const HiddenAccidentInjury = [
    {
        title:"Which Police Department?",
        value:"PoliceDepartment",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input",
        required:"Yes"
    },{
        title:"Police Report Number",
        value:"PoliceReportNumber",
        type:"text",
        style:"col-lg-6",
        inputStyle:"form-input",
        required:"Yes"
    }
];