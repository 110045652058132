import  { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TodayDate } from '../../../../Helpers/InputHelper';
import validateInfo from '../../Validation/DriverModal';

function EditDriver({id,FirstName,LastName,Birthdate,Accident,updateDriver}) {

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [birthdate,setBirthdate] = useState();
    const [accident,setAccident] = useState();

    const [show, setShow] = useState(false);

    const handleClose = (e) =>{e.preventDefault(); setShow(false)};
    const handleShow = (e) => {e.preventDefault(); setShow(true)};

    useEffect (() => {
         setFirstName(FirstName);
         setLastName(LastName);
         setBirthdate(Birthdate);
         setAccident(Accident);
    },[FirstName, LastName, Birthdate, Accident])



    let handleClick= (e) => {
        e.preventDefault();
        if(validateInfo({FirstName:FirstName,LastName:LastName,Birthdate:Birthdate,Accident:Accident}) <= 0){
            updateDriver(id, firstName, lastName, birthdate, accident);
            setShow(false);
        }
       
    }
    return (
        <>
            <button onClick={e => handleShow(e)}  style={{width:"auto",backgroundColor:"transparent"}}><div className='pencil-icon'/></button>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered dialogClassName="col-lg-6 pr-5 border-radius-2"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
                <div className="row ml-4 m-3">
              <div className="col-8"  style={{marginTop:"15px",marginLeft:"5%",marginBottom:"15px",fontFamily:'Poppins-Bold', fontSize:"150%"}}>
                {`Edit Driver ${id + 1}`}
              </div>
              <div className="col-1 mt-1 offset-lg-2">
                <button  onClick={handleClose} style={{backgroundColor:"transparent",fontFamily:"Poppins-Regular",color:"grey", fontSize:"180%"}}><FontAwesomeIcon icon={faXmark}/></button>
              </div>          
            </div><span className="header-divider"></span>
                <form  className='pt-4 pl-5 pr-5 pb-4' autoComplete='off' >
                <div className="row">
                        <div className="col-lg-6 mb-4 ">First Name<span className="required-asterisk"></span> <br />
                            <input
                                id='YesDriver'
                                maxLength="256"
                                className="form-input-sm px-2 "
                                name="firstName"
                                type="text"
                                value={firstName}
                                onChange={(e) => {setFirstName(e.target.value);}} />  
                                <br/>   
                         </div>
                         <div className="col-lg-6 mb-4">Last Name<span className="required-asterisk"></span><br />
                            <input
                                id='YesDriver'
                                maxLength="256"
                                className=" form-input-sm py-2 px-2"
                                name="lastName"
                                type="text"
                                value={lastName}
                                onChange={(e) => {setLastName(e.target.value);}} />
                                <br/> 
                         </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">Birthdate<span className="required-asterisk"></span><br />
                        <div style={{width:"70%"}}>
                                    <input
                                        maxLength="256"
                                        id='YesDriver'
                                        max={TodayDate()}
                                        className="form-input-lg"
                                        name="birthdate"
                                        type="date"
                                        value={birthdate}
                                        onChange={(e) => {setBirthdate(e.target.value);}} />
                                        <br/>     
                        </div>
                   </div>
                   <div className=" col-lg-6">
                            <div className='required-asterisk pb-1'>Has this Driver had any accidents in the last three years?</div>
                            <input type="button" id='YesDriverAccidentEDIT' className={`col-5 mr-3 justify-content-center contactBtn ${(accident === "Yes" ? ('onBtn') : ('offBtn'))}`} onClick={e => setAccident(e.target.value)} style={{width:"180px"}}  value={ `Yes`} />
                            <input type="button" id='YesDriverAccidentEDIT' className={`mb-4 col-5  justify-content-center contactBtn ${(accident === "No" ? ('onBtn') : ('offBtn'))}`} onClick={e => setAccident(e.target.value)}  style={{width:"180px"}}  value={`No`}  />    
                        
                   </div>
                </div>
                    <div className="row pb-4 pt-4 justify-content-center col-lg-12">
                    <button className="tealBtn" form="editmodal"  onClick={handleClick}>
                        {`Confirm Driver ${id + 1} Information`} 
                    </button>
                    </div>
                </form>
            
            </Modal>
        </>
    );
}

export default EditDriver;

