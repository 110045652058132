
import { StoryblokComponent, useStoryblok } from '@storyblok/react';
import { MDBSpinner } from 'mdb-react-ui-kit';

const HoursOfOperationGlobal = () => {
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const story = useStoryblok("lonestar/business-hours/hours-of-operation", { version: storyblokVersion });
    if (!story?.content) {
        return <div className="loader-section">
            <MDBSpinner color='danger' className="spinner"></MDBSpinner>
        </div>
    }
    return (
            <>
                <div className="Hours-Card-header ">Hours Of Operation</div>
                <div className="flex-center">{story.content.body[0].phone_number}</div>
                <div className="Operation-Day-txt pl-3">
                    {
                        story.content.body[0].monday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3"> Mon </div>
                                <div className="Hours-text col-8">
                                    {story.content.body[0].monday_hours_text}
                                </div>
                            </div>
                        )
                    }
                    {
                        story.content.body[0].tuesday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3">
                                    Tue
                                </div>
                                <div className="Hours-text col-8">
                                    {story.content.body[0].tuesday_hours_text}
                                </div>
                            </div>
                        )
                    }
                    {
                        story.content.body[0].wednesday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3">
                                    Wed
                                </div>
                                <div className="Hours-text col-8">
                                    {story.content.body[0].wednesday_hours_text}
                                </div>
                            </div>
                        )
                    }
                    {
                        story.content.body[0].thursday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3">
                                    Thur
                                </div>
                                <div className="Hours-text col-8">
                                    {story.content.body[0].thursday_hours_text}
                                </div>
                            </div>
                        )
                    }
                    {
                        story.content.body[0].friday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3">
                                    Fri
                                </div>
                                <div className="Hours-text col-8">
                                    {story.content.body[0].friday_hours_text}
                                </div>
                            </div>
                        )
                }
                {
                    story.content.body[0].saturday_hours_text !== "" && (
                        <div className="row pt-1 pb-1">
                            <div className="col-3">
                                Sat
                            </div>
                            <div className="Hours-text col-8">
                                {story.content.body[0].saturday_hours_text}
                            </div>
                        </div>
                    )
                }
                {
                    story.content.body[0].sunday_hours_text !== "" && (
                        <div className="row pt-1 pb-1">
                            <div className="col-3">
                                Sun
                            </div>
                            <div className="Hours-text col-8">
                                {story.content.body[0].sunday_hours_text}
                            </div>
                        </div>
                    )
                }

                    <div className="row pt-1 pb-1">
                        <div className="col-lg-12 Hours-Disclosure">{story.content.body[0].disclosure_text}</div>
                    </div>
                </div>
        </>
    )
}

export default HoursOfOperationGlobal