import "../../Assets/Style/Components.scss"
import { Link } from "react-router-dom";

const Basic = (props) => {
    return (
         <>
         {props.data.link_url === "" ? (
            <>
                <div style={{width:"auto",height:"auto"}}>
                        <div className="icon-cntr flex-center">
                            <img className="card-icon" alt="Icon" src={props.data.icon_image?.filename}></img>
                        </div>
                        <div className="col-text-cntr">
                                <h6 className="square-card-title">{props.data?.title_text}</h6>
                                { props.data?.hover_text.length > 0 && <>
                                    <p style={{fontSize:"15px"}}>{props.data?.hover_text}<br/>
                                    <a href={props.data?.link_url}>{props.data?.link_text}</a>
                                    </p>
                                    </>
                                }
                            
                        </div>
                    </div>
            </>
         ) : (
              <Link to={props.data?.link_url}>
                <div className={(props.data.hover_text.length > 0) ? ("icon-cntr flex-center") : ("")}>
                    <img className="card-icon" alt="Icon" src={props.data.icon_image?.filename}></img>
                </div>
                <div className={(props.data.hover_text.length > 0) ? ("col-text-cntr") : ("")}>
                        {(props.data.hover_text.length > 0) ? (<h6 className="square-card-title">{props.data?.title_text}</h6>) : 
                        (<div className="square-card-title">{props.data?.title_text}</div>)}
                        { props.data.hover_text.length > 0 && <>
                            <p style={{fontSize:"15px"}}>{props.data?.hover_text}<br/>
                            <a href={props.data?.link_url}>{props.data?.link_text}</a>
                            </p>
                            </>
                        }
                    
                </div>
            </Link>

         )}
           
        </> 
    );
}
 
export default Basic;