export default function ContactCardSB({blok}) {
  return (
        <>
            <div className="py-2">
              <div className="flex-basis-10">
                  <div style={{ paddingBottom: "10px", fontFamily: "Poppins-Semi-Bold", fontSize: "20px", display:"flex" }}>{blok.icon_image?.filename && (<img style={{ width: "40px", marginRight: "10px" }} alt="Icon" src={blok.icon_image?.filename} />)} {blok.title_text}</div>
                </div>
            </div>
            <div className="flex-basis-10">
                <div className="CT-txt">{blok.address_line_1} <br/> 
                   {blok.address_line_2}
                   <div>{blok.address_line_3}</div>
                </div>
            </div>
        </>
  )
}
