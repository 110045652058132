/// <reference path="authprovider.js" />
/// <reference path="../app.js" />
import { Navigate, useLocation} from 'react-router-dom';

export default function RequiredAuth({children}) {
    const location = useLocation();

    let obj = localStorage.getItem("DATA");
    let jsonResponse = JSON.parse(obj);

    if (jsonResponse?.rtnval?.id < 0 || JSON.parse(localStorage.getItem("DATA")).rtnval?.requirePasswordChange === true) {
        return <Navigate to='/ProducerLogin' state={{path: location.pathname}}/>
    }
    
    return children
}
