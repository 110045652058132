import Breadcrumb from '../../Components/Core/BreadCrumb';
import Banners from '../../Components/Core/Banners';
import MasterSuppliesForm from '../../Components/Forms/OrderSupplies/MasterForm';
import { Link } from 'react-router-dom';

const items = [
    { to: '/ProducerPortal', label: 'Home' },
    { to: '/OrderSupplies', label: 'Order Supplies' }
]

const OrderSupplies = () => {
    return ( 
        <>
            <Banners img={'https://cdn.lonestarmga.com/img/Banners/order-supplies_minibanner.svg'} mobileImg={'https://cdn.lonestarmga.com/img/Banners/mobile/order-supplies_390x275.svg'} category="Producers" page="Order Supplies" />
            <div style={{minHeight:"600px",paddingBottom:"5%",backgroundColor:"#F0F0F0"}}> 
                <div className="py-4 flex" style={{flexWrap:"wrap",justifyItems:"center",justifySelf:"center", alignContent:"center",alignItems:"center",justifyContent:"center"}} >
                    <div className='mobile-breadcrumb' style={{width:"1000px"}}>
                        <Breadcrumb>{
                            items.map(({to, label}) =>(           
                                <Link key={to} to={to}>{label}</Link>
                            ))}
                        </Breadcrumb>
                    </div>
                </div>
                    <div className='flex-center ' >
                        <div className='p-1' style={{maxWidth:"1005px"}}>
                            <MasterSuppliesForm />
                        </div>
                  </div>
            </div>
        
        </> 
    );
}
 
export default OrderSupplies;