import { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { formatPhoneNumber } from "../../../Helpers/InputHelper";
import validateInfo from "../Validation/FormValidation";
import axios from "axios";
import $ from "jquery";

const ContactUs = (props) => {
    const [FormErrors, setFormErrors] = useState(1);
    const [IsSubmitting, setIsSubmitting] = useState(false);
    const [Attempt, setAttempt] = useState(false);
    const [CaptchaRes, setCaptchaRes] = useState(0);

    const captchaRef = useRef(null);

    useEffect(() => {
        if (FormErrors === "Ready" && IsSubmitting) {
            props.submitForm();
            $.ajax({
                type: "POST",
                url: "/Email/SendContactFormEmail",
                data: JSON.stringify(props.state, function (k, v) { return v === undefined ? "" : v; }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                failure: function (response) {
                    alert(response.d);
                }
            });
        }
    }, [FormErrors, IsSubmitting]);
    useEffect(() => {
        if (Attempt === true) {
            setFormErrors(validateInfo(props.state));
            if (FormErrors > 1) {
                setIsSubmitting(false);
            } else if (FormErrors <= 0) {
                setFormErrors("Ready");
            }
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAttempt(true);
        setIsSubmitting(true);
        const token = captchaRef.current.getValue();

        if (token) {
            let valid_token = await verifyToken(token);
            if (valid_token.success) {
                setCaptchaRes(1);
            } else {
                setCaptchaRes(-1);
            }
        } else {
            setCaptchaRes(-1);
        }
    };
    const verifyToken = async (token) => {
        try {
            let response = await axios.post(`http://localhost:4000/verify-token`, {
                secret: process.env.REACT_APP_SECRET_KEY, token
            });
            return (response.data);
        } catch (error) {
            console.log("error ", error);
        }
    }
    return (
        <div className="flex-center">
            <form className="formLayout contact-us-form-body" noValidate autoComplete="off">
                <div className="pb-4">
                    <div className="contactTitle">Contact Us Form</div>
                    <div>Send us a message and we'll respond within 1 - 2 business days.</div>
                </div>
                <div className="pb-4 form-label">
                    <div className=" required-asterisk">I am a</div>
                    <select className=" form-input  pl-2 " id="YesSelect2" name="UserType" onChange={props.handleChange} defaultValue={props.state.userType}>
                        <option disabled selected hidden> Select</option>
                        <option value="Customer">Lonestar Customer</option>
                        <option value="Producer">Lonestar Producer</option>
                        <option value="ProspectiveIndependentProducer">Prospective Independent Producer</option>
                        <option value="Visitor">Visitor</option>
                    </select>
                </div>
                <div className="pb-4  form-label">
                    <div className="required-asterisk">First Name</div>
                    <input id="Yes" className="form-input pl-2" onChange={props.handleChange} type="text" maxLength="256" name="FirstName" />
                </div>
                <div className="pb-4 form-label">
                    <div className="required-asterisk">Last Name</div>
                    <input id="Yes" className="form-input pl-2" onChange={props.handleChange} type="text" maxLength="256" name="LastName" />
                </div>
                {(props.state.UserType === "Producer") && (<>
                    <div className="pb-4 form-label">
                        <div className="required-asterisk"> Producer ID </div>
                        <input id="Yes" className="form-input pl-2" onChange={props.handleChange} type="text" maxLength="256" name="ProducerID" />
                    </div>
                </>)}
                <div className="pb-4 form-label">
                    <div className="required-asterisk ">Subject</div>
                    <select className="form-input pl-2" id="YesSelect" name="Subject" onChange={props.handleChange}>
                        <option disabled selected hidden > Select</option>
                        <option name="Subject" value="about">About Joining Agency</option>
                        <option name="Subject" value="careers">Careers</option>
                        <option name="Subject" value="claimIssues">Claim Issues</option>
                        <option name="Subject" value="insuranceProducts">Insurance Products</option>
                        <option name="Subject" value="policyIssues">Policy Issues</option>
                        <option name="Subject" value="technicalIssues">Technical Issues</option>
                        <option name="Subject" value="other">Other</option>
                    </select>
                </div>
                {((props.state.UserType === "Visitor" && props.state.Subject === "claimIssues") || (props.state.UserType === "Customer")) && (
                    <>
                        <div className="pb-4 form-label">
                            <div className="required-asterisk">Policy Number</div>
                            <input className="form-input pl-2" onChange={props.handleChange} type="text" maxLength="256" name="PolicyNumber" defaultValue="" />
                        </div>
                        <div className="pb-4 form-label">
                            <div className="required-asterisk">Claims Number</div>
                            <input className="form-input pl-2" onChange={props.handleChange} type="text" maxLength="256" name="ClaimNumber" defaultValue="" />
                        </div>
                    </>)}
                <div className="pb-4 form-label">
                    <div className="required-asterisk">Message</div>
                    <textarea className="message-input form-input pl-2"
                        name="Message" id="Yes" onChange={props.handleChange} ></textarea>
                </div>
                <div> I prefer to be contacted by<span className="required-asterisk"></span><br />
                    <div className="flex mt-3">
                        <input name="ContactType" type="button" id="email" value="Email" onClick={props.handleChange} className={`optionBtn  mr-3  btnYes2 ${(props.state.ContactType === "Email") ? 'onBtn' : 'offBtn'}`} />
                        <input name="ContactType" type="button" id="phone" value="Phone" onClick={props.handleChange} className={`optionBtn btnYes2 ${(props.state.ContactType === "Phone") ? 'onBtn' : 'offBtn'}`} />
                    </div>
                </div>
                {props.state.ContactType === "Phone" ? (
                    <>
                        <div className="pt-4 form-label">
                            <div className="required-asterisk">Enter Phone Number</div>
                            <input className="form-input pl-2" id="Yes" onChange={props.handleChange} onKeyUp={(e) => formatPhoneNumber(e)} onKeyDown={(e) => formatPhoneNumber(e)} maxLength="12" type="tel" name="PhoneNumber" />
                        </div><br />
                    </>)
                    : props.state.ContactType === "Email" && (
                        <>
                            <div className="pt-4 form-label">
                                <div className="required-asterisk">Enter Email Address</div>
                                <input className="form-input pl-2" id="Yes" onChange={props.handleChange} type="email" maxLength="256" name="Email" defaultValue="" />
                            </div><br />
                        </>)}
                <div className="py-4">
                    <ReCAPTCHA size="normal" sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
                    {CaptchaRes === -1 && <span style={{ fontSize: "13px", color: "#B22727", fontFamily: "Poppins-Semi-Bold" }}>Required</span>}
                </div>
                <div className="row p-3 submit-position">
                    <button onClick={handleSubmit} className="submitBtn col-lg-12" type="submit"> Submit Form </button>
                </div>
            </form>
        </div>
    );
}

export default ContactUs;