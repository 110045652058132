import React from "react";

const DownloadButton = ({data,config}) => {
   
    
    function date (format){
        let [year,month,day] = format.split("-");
        let [newDay] = day.split(" "); 
        return <>{month}/{newDay}/{year}</>
    }
    
    return ( <>
    <div style={{marginBottom:"20px"}}>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <a className="align-items-center download-btn align-item-center " style={{display:"flex"}}  target="_blank" href={data.download_item.filename}>
            <div style={{flexBasis:"100%"}}>
            {data.download_title}{<br/ >}{data.download_version_date !== "" && (date(data.download_version_date))}
            </div>
            <div style={{fontSize:"20px"}}>
                <i></i>
            </div>
        </a>
    </div>
    </> );
}
 
export default DownloadButton;