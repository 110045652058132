/// <reference path="../visitor/menuitems.js" />
import {useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faSortDown } from '@fortawesome/free-solid-svg-icons'
import "../../Navbar.scss";
import Dropdown from "./Dropdown";
import { useAuth } from "../../../../utils/AuthProvider";
import { Link } from "react-router-dom";
const MenuItems = ({ items, depthLevel}) => {

  const [dropdown, setDropdown] = useState(false);
  const auth = useAuth();
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 1000 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 1000 && setDropdown(false);
  };
 //Where it will effect the navigation 
  return (
    
      ((items.page_visibility.includes("member") || items.page_visibility.includes("producer") || items.page_visibility.includes("admin")) && !items.page_visibility.includes("only_visitors")) && (
        
        <>
        
              <li  ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
      {items.title && depthLevel === 0   ? (
         
         <>
            <div className="menu-items">
            {items.link_target === "_self" ? (
                <>
                  <Link className="top-nav-route" style={{lineHeight:"100px"}} to={items.path} >{items.title} </Link>
                    {items.children_links.length > 0 &&(
                    <>
                      {<span><FontAwesomeIcon className= "arrow" icon={faSortDown} /></span>}
                      <Dropdown depthLevel={depthLevel}  submenus={items.children_links} dropdown={dropdown} />
                    </>
                    )}
                </>
                ):(
                <>
                  <a className="top-nav-route" style={{lineHeight:"100px"}}  target={items.link_target} href={`${items.path}`} >{items.title} </a>  
                    {items.children_links.length > 0 &&(
                    <>
                      {<span><FontAwesomeIcon className= "arrow" icon={faSortDown} /></span>}
                      <Dropdown depthLevel={depthLevel}  submenus={items.children_links} dropdown={dropdown} />
                    </>
                    )}
                  
                </>
              )}
           </div>
          <br/>
       </>     
         
        //Second Time it loops through 
      ) : items.title[1] &&           
      (
          <>
              <div className="sub-row" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"} onClick={() => setDropdown((prev) => !prev)}> 
                 <>
                    {items.link_target === "_self" ? (
                      
                      <Link className="test flex-start " to={items.path}>
                          {items.title}
                              {items.children_links.length > 0 && (
                          <FontAwesomeIcon className="subArrow" icon={faCaretRight} />
                        )}
                        </Link>
                      ):(items.link_target === "_blank" || items.link_target === "") &&(
                          <a className="test flex-start" href={`${items.path}`} target={items.link_target}>{items.title}
                           {items.children_links.length > 0 && (
                          <FontAwesomeIcon className="subArrow" icon={faCaretRight} />
                        )}</a>
                        )
                    }{items.children_links.length > 0 &&(
                              <>
                                <Dropdown depthLevel={depthLevel}  submenus={items.children_links} dropdown={dropdown} />
                              </>
                              )
                    }
                   
                  </>
                 
              </div>
        </>
      )
      }
    </li>
        </>

      )
    
    
  );
};

export default MenuItems;