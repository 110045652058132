import React from "react";
import ButtonConfig from "./ButtonConfig";
const ButtonGroup = ({data}) => {

    return (
        <>
            {
              data.map((items) => {
                  return (
                            <ButtonConfig data={items.configuration} button={items.buttons}/>
                    )
                })
            }
        </> 
    );


}
 
export default ButtonGroup;