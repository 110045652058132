import RichTextRevolver from 'storyblok-js-client/dist/rich-text-resolver.cjs';

const ContentHeaderOnly = (props) => {

    const resolver = new RichTextRevolver();
    var arr = props?.data?.header
   
    return (<>
            {
                arr.map((items) => {
             return(
                 <>
                     <div style={props?.headingPosition === null || items.headingPosition === "" ? { textAlign: `center` } : props.headingPosition ? { textAlign: `${props?.headingPosition}` } : items.headingPosition && { textAlign: `${items?.headingPosition}` }} className={`${items.headline_style} justify-content-center`}>
                            {items.subheadline !== "" && (<span className={`sub-headline`} >{items.subheadline}</span>)}
                                <div className={`heading`} >{items.headline}</div>
                                {items.description !== "" && (<div className="content-only-description" dangerouslySetInnerHTML={{__html:resolver.render(items.description)}}></div>)}
                        </div>
                    </>
                )
                })
    
            }
            
    </> );
}
 
export default ContentHeaderOnly;