/// <reference path="../components/forms/loginusers/changepasswordform.js" />
/// <reference path="adminauth.js" />
/// <reference path="../Components/Layout/NavViews/Visitor/Navigation.js" />
import {createContext, useContext, useState,useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import $ from "jquery";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
   
    const [systemUser, setSystemUser] = useState(null);
    const [callResponse, setCallResponse] = useState(null);
    const [NavigationView, setNavigationView] = useState(null);
    const [PasswordChangeModal, setPasswordChangeModal] = useState(false);
    const [NotificationMessage, setNotificationMessage] = useState("");
    const [MaintenanceMessage, setMaintanenceMessage] = useState({
        isDown: false,
        messageHTML:""
    });
    const navigate = useNavigate();
    useEffect(() => {
        checkNotification();
    }, [])

    const checkNotification = () => {

        $.ajax({
            type: "POST",
            url: "/Configuration/GetNotificationMessage",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                setNotificationMessage(result.response.messageHTML);
            },
            failure: function (response) {
                console.log(response);
            },
        });

        $.ajax({
            type: "POST",
            url: "/Configuration/GetMaintenanceMessage",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                setMaintanenceMessage({
                    isDown: result.response.isDown,
                    messageHTML: result.response.messageHTML
                });
                /*console.log(result);*/
            },
            failure: function (response) {
                console.log(response);
            },
        });

    }
    const checkToken = () => {
   //     console.log("check token called");
        $.ajax({
            type: "POST",
            url: "/AuthenticateUser/RolesPost",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                localStorage.setItem('DATA', JSON.stringify(response));
                setNavigationView(JSON.parse(localStorage.getItem("DATA")).rtnval?.id);
            },
            failure: function (response) {
                console.log(response);
            },
        });
    }
    checkToken();

    const login = (user) => {

        if (user.rtnval === '/ProducerPortal') {
            $.ajax({
                type: "POST",
                url: "/AuthenticateUser/RolesPost",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response) {
                    localStorage.setItem('DATA', JSON.stringify(response));
                    setCallResponse(localStorage.setItem('DATA', JSON.stringify(response)));
                    if (JSON.parse(localStorage.getItem("DATA")).rtnval?.requirePasswordChange === true) {
                        navigate('/ChangePassword', { replace: true });
                    } else {
                        if (JSON.parse(localStorage.getItem("_RNL")) && JSON.parse(localStorage.getItem("DATA"))?.rtnval.rolesPiped.includes('ADMIN')) {
                            navigate(`/AddProducer/${JSON.parse(localStorage.getItem("_RNL"))?.fn}/${JSON.parse(localStorage.getItem("_RNL"))?.ln}/${JSON.parse(localStorage.getItem("_RNL"))?.e}`, { replace: true });
                            localStorage.removeItem('_RNL');
                        } else {
                            localStorage.removeItem('_RNL');
                            navigate(`/ProducerPortal`, { replace: true });
                        }                    
                    }
                },
                failure: function (response) {
                    console.log(response);
                },
            });
            return setSystemUser(user.rtnval);
        }
        else if (user.rtnval === '/ProducerLogin') {
           
           return setSystemUser(null);
        }
      
    }
    const UpdateForgotPassword = () => {
        $.ajax({
            type: "POST",
            url: "/AuthenticateUser/UserLogout",
            success: function () {
               
            },
            failure: function (response) {
                console.log("something went wrong" + " " + response)
            },

        });
        setSystemUser(null);
    }
    const ChangedPassword = () => {
        $.ajax({
            type: "POST",
            url: "/AuthenticateUser/UserLogout",
            success: function () {
                localStorage.removeItem('DATA');
            }
        });
        setSystemUser(null);
    }
    const logout = () => {
        localStorage.removeItem('_RNL');
        $.ajax({
            type: "POST", 
            url: "/AuthenticateUser/UserLogout",
            success: function (response) {
                localStorage.removeItem('DATA');
                setNavigationView(null);
                navigate('/');
            },
            failure: function (response) {
                console.log("something went wrong" + " " + response)
            },

        });
        setSystemUser(null);
    }

    return <AuthContext.Provider value={{ NotificationMessage, MaintenanceMessage , systemUser, login, logout, ChangedPassword, UpdateForgotPassword, callResponse, NavigationView, setPasswordChangeModal, PasswordChangeModal }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}