import Banners from "../../Components/Core/Banners";
import { useStoryblok } from "@storyblok/react";
import Breadcrumb from "../../Components/Core/BreadCrumb";
import CardGrid from "../../Components/Cards/CardGrid";
import { Link } from "react-router-dom";
import { MDBSpinner } from 'mdb-react-ui-kit';

const items = [
    { to: '/', label: 'Home' },{ to: '/ClaimCenter', label: 'Claims Center' },
]

const ClaimCenter = () => {
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const Slug = "lonestar/claims-content/claims-actions";
    const Story = useStoryblok(Slug,{ version: storyblokVersion });
    const ContactSlug = "lonestar/claims-content/contact-our-claims-department";
    const ContactStory = useStoryblok(ContactSlug,{ version: storyblokVersion });

    if(!Story.content || !ContactStory.content){ return <div className="loader-section">
        <MDBSpinner color='danger' className="spinner"></MDBSpinner>
    </div>}
    return (
      <>
            <Banners img={"https://cdn.lonestarmga.com/img/Banners/claims-center-banner_1920x357.png"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/claim-center-banner_390x282.png"} page={"Claim Center"} category={"Services"}/>
            <div style={{minHeight:"1000px",backgroundColor:"#F0F0F0"}}> 
                    <div className="mobile-adjust-center crumb-position mobile-spacing-left pb-3">
                        <Breadcrumb>{
                                items.map(({to, label}) =>(           
                                    <Link key={to} to={to}>{label}</Link>
                                ))}
                         </Breadcrumb>
                    </div>
                <div className="flex-center">
                    <div className="claim-center-section" >
                        <div className="flex-center">
                            <div className="pt-4 px-4" style={{ maxWidth: "1300px" }}>
                                <CardGrid data={Story.content} />
                            </div>
                        </div>
                        <div className="flex-center wrap white-body-2">
                            <CardGrid data={ContactStory.content}/>   
                        </div>
                    </div>
                </div>
           </div>
      </>
    );
}
 
export default ClaimCenter;