import { FormatDate } from "../../Helpers/InputHelper";

export default function DownloadBtn(props) {
    return ( 
        <>
            {props.file === null? 
            (
                <>
                    <div style={{marginBottom:"20px"}}>
                        <div className="download-btn-coming-soon"><div className='flex-basis-10'>{props.title}<br/></div></div>
                    </div>
                </>
            ): (
                <>
                    <div style={{paddingBottom:"20px"}}>
                        <a target="_blank" href={props.file}><button className="download-btn"><div className='flex-basis-10'>{props.title}</div>{<br/ >}{props.date !== "" && (FormatDate(props.date))}<i></i></button></a>
                    </div>
                </>
            )}
        </> 
    )
}
