import Banners from "../../Components/Core/Banners";
import { Link } from "react-router-dom";
import Breadcrumb from "../../Components/Core/BreadCrumb";
import ForgotPasswordForm from "../../Components/Forms/LoginUsers/ForgotPasswordForm";

const items = [
    { to: '/', label: 'Home' },
    { to: '/ProducerLogin', label: 'Producer Login' },
    { to: '/ForgotPassword', label: 'Forgot Password?' }
]

const ForgotPassword = () => {
    return (
        <>
            <Banners img={'https://cdn.lonestarmga.com/img/Banners/forgetpassword_banner.svg'} mobileImg={'https://cdn.lonestarmga.com/img/Banners/mobile/forgot-pw_390x282.svg'} page={"Producers"} category={"Services"} />
            <div className='flex-center wrap white-smoke-background'>
                        <div className="pb-4 pt-3 login-breadcrumb">
                            <Breadcrumb>{
                                items.map(({to, label}) =>(           
                                    <Link key={to} to={to}>{label}</Link>
                                ))}
                            </Breadcrumb>
                        </div>  
            </div>
            <div className="flex-center white-smoke-background" style={{ paddingBottom: "5%" }}>
                <ForgotPasswordForm />
            </div>
         </>
    );
} 
export default ForgotPassword;