import styled from "styled-components";
import RichText from "./Core/RichText";


export default function FeaturedTopNews(props) {
    return (
        props?.data?.map((data,index) => {
            return index <= 1 &&
                <>
                 <BlogBody className="justify-content-center">
                    <div className="flex-center pt-3 px-3"><div className="flex-basis-5 newsletter-topic">{data.topic}</div><div className="flex-basis-5 newsletter-topic" style={{textAlign:"right"}}>{data.date}</div></div>
                    {data.image !== " " && (
                        <div className="flex-center">
                            <div className="section" >
                                <BlogImg src={data.image.filename} alt="Blog Icon"></BlogImg>
                            </div>
                        </div>
                    )}
                    <BlogTitle ><RichText Description={data.title} /></BlogTitle>
                    <Description ><RichText Description={data.description}/></Description>
                    <div className="offset-lg-8 pt-3 pb-3">
                        <a className="blog-card-link" href={data.link_url} target="_blank" >{data.link_text}</a>
                    </div>    
                  </BlogBody>  
            </>
        })
 
  )
}


const BlogImg = styled.img`
    justify-content:center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 4%;
    background:auto;
    @media only screen and (max-width:800px){
        max-width:300px;
        max-height:180px;
        padding-top: 3%;
        
    }
`;
const BlogTitle = styled.div `
    color: #B22727;
    font-family: "Poppins-Regular";
    letter-spacing: 0;
    text-align: center;
    justify-content:center;
    padding-top:15px;
    align-content:baseline;
    h4{
        font-size: 22px !important;
    }
    @media only screen and (max-width:800px){
        font-size: 100%;
        line-height: 15px;
    }
    `;

const Description = styled.div`
    text-align:left;
    justify-content:center;
    padding: 0% 4%;
`;

const BlogBody = styled.div `
    height: 100%;
    max-width: 500px;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 16px 0 rgba(141,141,141,0.5);
    margin-right:1%;
    padding: 1rem;
    @media only screen and (max-width:1000px){
        margin:5%;
        padding: 2% !important;
        height: auto !important;
        max-width: 400px;
        min-width: 350px;

    }
    @media only screen and (max-width:800px){
        margin:5%;
        padding: 4% !important;
        height: auto !important;
        max-width: 400px;
        min-width: 350px;

    }
`;
