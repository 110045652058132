import React from 'react';
import RichTextRevolver from 'storyblok-js-client/dist/rich-text-resolver.cjs';
import styled from "styled-components";

const OrderItem = ({data,num}) => {

    const resolver = new RichTextRevolver();
    let Description = data.description

    return ( 
        <>
                <div className="col-lg-12" >
                    {data.icon_image.filename != "" && (
                    <img style={{width:"65px"}} alt="icon" src={data.icon_image.filename}/>

                    )}
                 </div>
                 <div className="row OrderItem-Spacing" style={{display:"inline-flex",alignItems:"center"}}>
                        <div className='pr-4'>
                            <div className='OrderItem'>
                                <div className="OrderItemNum">{num + 1}</div>
                            </div>                         
                        </div>
                        <div className="col-10">      
                            <HeadingUI>{data.list_text}</HeadingUI>
                            <DescriptionUI dangerouslySetInnerHTML={{__html:resolver.render(Description)}}></DescriptionUI>
                        </div>
                        
                </div>
        </>
     )

}

export default OrderItem 
const DescriptionUI = styled.div`
    padding-top:5px; 
    p{font-size:22px;
        text-align: left;}
    font-family:"Poppins-Regular";

    @media only screen and (max-width:800px){
        font-size-18px !important;
        p{font-size:18px !important;
            text-align: left;}
    }
`;

const HeadingUI = styled.div`
   font-size:22px;
   line-height: 40px;
   font-family: 'Poppins-Regular';
   @media only screen and (max-width:800px){
    font-size:16px;
    line-height: 25px;
   }
   
`;