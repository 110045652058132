import { useStoryblok } from "@storyblok/react";
import ButtonGroup from "../../Components/Buttons/ButtonGroup";
import Breadcrumb from "../../Components/Core/BreadCrumb";
import Banners from "../../Components/Core/Banners";
import { MDBSpinner } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";

const crumbs = [
    { to: '/ProducerPortal', label: 'Home' },
    { to: '/DocumentsAndPublications', label: 'Documents and Publications' },
]

const DocumentPublication = () => {
    
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
    const story = useStoryblok("lonestar/producer/documents-and-publications", { version: storyblokVersion });

    if( !story?.content){
        return <div className="loader-section">
                 <MDBSpinner color='danger' className="spinner"></MDBSpinner>
               </div>
    }

    return ( 
            <>
            <Banners img={"https://cdn.lonestarmga.com/img/Banners/documentsandpublications.png"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/documents-publication_389x275.svg"} page={"Documents and Publications"} category={"Producers"}/>
                <div className="py-3 document-publication-page-body">
                    <div className="link-btn-sec">
                        <Breadcrumb >{
                            crumbs.map(({to, label}) =>(           
                                <Link key={to} to={to}>{label}</Link>
                            ))}
                        </Breadcrumb><br/>
                        <ButtonGroup data={[story.content]} />
                    </div>
                 </div>
            </>
     );
}
export default DocumentPublication;