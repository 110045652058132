import { useStoryblok } from "@storyblok/react";
import Breadcrumb from '../Components/Core/BreadCrumb'
import LoginObject from '../Components/LoginObject';
import ListObject from '../Components/ListObject';
import ContentHeader from '../Components/ContentHeader';
import ButtonGroup from '../Components/Buttons/ButtonGroup';
import CardGrid from '../Components/Cards/CardGrid';
import Banners from '../Components/Core/Banners';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import ContentObject2Bottom from "../Components/ContentObject2Bottom";

export default function Customers() {
    const crumbs = [
        { to: '/', label: 'Home' },
        { to: '/Customers', label: 'Customers' },
    ]
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const PortalStory = useStoryblok("lonestar/customer-content/account-portal-login", { version: storyblokVersion });
    const PortalFeatureStory = useStoryblok("lonestar/customer-content/account-portal-features", { version: storyblokVersion });
    const ResourceStory = useStoryblok("lonestar/customer-content/online-resources", { version: storyblokVersion });
    const BtnResource1Story = useStoryblok("lonestar/customer-content/online-resources-button-set-1", { version: storyblokVersion });
    const BtnResource2Story = useStoryblok("lonestar/customer-content/online-resources-button-set-2", { version: storyblokVersion });
    const PaymentClaimStory = useStoryblok("lonestar/customer-content/make-a-payment-file-a-claim-card-grid", { version: storyblokVersion });
    const MediaStory = useStoryblok("lonestar/what-we-offer", { version: storyblokVersion });
    const OfferBottom = useStoryblok("lonestar/what-we-offer-2",{version:storyblokVersion});

    if(!PortalStory?.content || !PortalFeatureStory?.content || !ResourceStory?.content || !OfferBottom?.content
        || !BtnResource1Story?.content || !BtnResource2Story?.content || !PaymentClaimStory?.content
        || !MediaStory?.content){
      return <div className="loader-section">
                <MDBSpinner color='danger' className="spinner"></MDBSpinner>
             </div>
    }
  return (
  <>
          <Banners img={'https://cdn.lonestarmga.com/img/Banners/customer_1920x357.png'} mobileImg={'https://cdn.lonestarmga.com/img/Banners/mobile/customer_390x282.png'} page={"Customers"} category={"Services"} />
          <div className="py-3">
              <div className="mobile-adjust-center crumb-position pb-3 mobile-spacing-left">
                    <Breadcrumb >{
                        crumbs.map(({to, label}) =>(           
                            <Link key={to} to={to}>{label}</Link>
                        ))}
                    </Breadcrumb>
              </div>
              <div className="flex-center">
                <div className="flex-center wrap align-items-center px-4 " style={{maxWidth:"1300px"}}>
                    <div className="flex-basis-5-full login-object-mobile p-1"> 
                        <LoginObject data={PortalStory.content}/>
                    </div>
                    <div className="flex-basis-5-full justify-content-center p-1">
                      <ListObject data={PortalFeatureStory.content} headingPosition={"left"} />
                    </div>
                </div>
              </div>
          </div>

          <div className="blok justify-content-center" style={{minHeight:"450px",backgroundColor:"#F8F8F8"}}>
                <div><br/><br/>
                  <ContentHeader data={ResourceStory.content.header} headingPosition="center"/>
                </div>
                <div className="flex-center gap-5 mobile-gap-none">
                  <div className="flex-center wrap-button-cols px-4" style={{width:"1300px"}}>
                      <div className="flex-basis-5-full">
                          <ButtonGroup data={[BtnResource1Story.content]}/>
                      </div>
                      <span className='break'></span>
                      <div className="flex-basis-5-full mobile-padding-bottom-10 ">
                        <ButtonGroup data={[BtnResource2Story.content]}/>
                      </div>
                  </div>
                </div>
          </div>
          <div className="grey-background-E8" style={{paddingBottom:"1%",minHeight:"600px",width:"100%",paddingTop:"1%", background:`url(${PaymentClaimStory.content.configuration[0].background_image.filename}) no-repeat bottom`,backgroundPosition:"right bottom",backgroundSize:"70%"}}>
                <div className='flex-center'>
                      <CardGrid data={PaymentClaimStory.content} />
                </div>
          </div>
          <div className='container p-5'>
            <ContentObject2Bottom data={OfferBottom.content} />
        </div>

  </>
)
}
