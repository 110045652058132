import MenuItems from './MenuItems';
import '../../Navbar.scss';

const Dropdown = ({ submenus, dropdown, depthLevel }) => {

  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "dropdown";

  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu,index) => (
          ((submenu.page_visibility.includes("member") || submenu.page_visibility.includes("producer") || submenu.page_visibility.includes("admin") ) && !submenu.page_visibility.includes("only_visitors")) && (
           <div className="dropdown-item" key={index}> 
              <MenuItems items={submenu} depthLevel={depthLevel} />
              <br/>
           </div> 
        )
      ))}
    </ul>
  );
}

export default Dropdown;