import { useEffect, useState } from "react";
import { formatPhoneNumber } from '../../../Helpers/InputHelper';
import validateInfo from "../Validation/FormValidation.js";
import styled from "styled-components";
import $ from "jquery";

const RequestLoginForm = ({submitForm}) => {
    const [FormErrors, setFormErrors] = useState(1);
    const [IsSubmitting,setSubmitting] = useState(false);
    const [Attempt,setAttempt] = useState(false);
    const [FormData, setFormData] = useState({
            FirstName: "",
            LastName: "",
            Email: "",
            PhoneNumber:"",
            Agency: "",
            ProducerCode:""
    });
    useEffect(() => {
        if (FormErrors === "Ready" && IsSubmitting) {
            submitForm();
            $.ajax({
                type: "POST",
                url: "/AuthenticateUser/RequestNewLogin",
                data: JSON.stringify({
                    FirstName: FormData.FirstName,
                    LastName: FormData.LastName,
                    Email: FormData.Email,
                    PhoneNumber: FormData.PhoneNumber,
                    Agency: FormData.Agency,
                    ProducerCode: FormData.ProducerCode
                }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                failure: function (response) {
                    alert(response);
                },
            });
        }
    }, [FormErrors, IsSubmitting]);
    useEffect(() => {
        if (Attempt === true) {
            setFormErrors(validateInfo(FormData));
            if (FormErrors > 1) {
                setSubmitting(false);
            } else if (FormErrors <= 0) {
                setFormErrors("Ready");
            }
        }
    })
  const handleChange = (e) => {
        e.preventDefault();
        const {name,value} = e.target;
        setFormData({...FormData, [name]: value});
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setAttempt(true);
    setSubmitting(true);
  }
    return ( <>
            <div className="grid lg-form-body">
                <FormHeading className="row justify-content-center">Request New Login</FormHeading>
                <div className="my-3 required-box">
                                    <div className="required-txt required-asterisk">Required</div>
                </div>
                <div className="py-3">First Name<span className="required-asterisk"></span> <br />
                    <input id="Yes" type="text"  onChange={handleChange} name="FirstName" maxLength="256"  className="form-input pt-4" autoComplete="off" style={{textTransform:"none", padding:"22.5px 14px",height:"1.4375em"}} />
                </div>
                <div className="py-3">Last Name<span className="required-asterisk"></span> <br />
                    <input id="Yes" type="text"  onChange={handleChange} name="LastName" maxLength="256"  className="form-input pt-4" autoComplete="off" style={{textTransform:"none", padding:"22.5px 14px",height:"1.4375em"}} />
                </div>
                <div className="py-3">Email<span className="required-asterisk"></span> <br />
                    <input id="YesEmail" type="text" onChange={handleChange} name="Email" maxLength="256"  className="form-input YesEmail pt-4" autoComplete="off" style={{textTransform:"none", padding:"22.5px 14px",height:"1.4375em"}} />
                </div>
                <div className="py-3">Phone Number<span className="required-asterisk"></span> <br />
                <input id="YesPhone" type="tel" onChange={handleChange} name="PhoneNumber" maxLength="12" className="form-input pt-4" autoComplete="off" onKeyUp={(e) => formatPhoneNumber(e)} onKeyDown={(e) => formatPhoneNumber(e)} style={{ textTransform: "none", padding: "22.5px 14px", height: "1.4375em" }} />
                </div>
                <div className="py-3">Agency<span className="required-asterisk"></span> <br />
                    <input id="Yes" type="text" onChange={handleChange} name="Agency" maxLength="256" className="form-input pt-4" autoComplete="off" style={{ textTransform: "none", padding: "22.5px 14px", height: "1.4375em" }} />
                </div>
                <div className="py-3">Producer Code<br />
                    <input id="No" type="text" onChange={handleChange} name="ProducerCode" maxLength="256" className="form-input pt-4" autoComplete="off" style={{ textTransform: "none", padding: "22.5px 14px", height: "1.4375em" }} />
                </div>
                <div className="flex-center">
                        <button className="primary-lg-pill-btn" onClick={handleSubmit}>Submit Form</button>
                </div>
            </div>
    </> );
}
 
export default RequestLoginForm;

const FormHeading = styled.div`
        color: #222222;
        font-family: Poppins-Semi-Bold;
        font-size: 2.5rem;
        letter-spacing: 0;
        line-height: 60px;  
        @media only screen and (max-width:800px){
            font-size: 1.5rem;
            line-height: 40px;
            padding-bottom:5%; 
        }
    
`;