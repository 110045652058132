import React from "react";
import { Link } from "react-router-dom";
import { useRef,useEffect } from "react";

const SuccessForm = () => {
   const formRef = useRef(null);

   useEffect(() => {
    const element = formRef.current;
    window.scrollTo(0, element);
  }, []);
 
    return ( 
        <div className="row justify-content-center">
            <div className="success-contact-body" ref={formRef}>
                
                <div>
                    <div className="title pl-4 pr-4" >
                        Contact Us Form Successfully Sent
                    </div>
                 </div>
                 <div className="py-4 description">
                    Someone from our contact team is going to reach out to you from 1 to 2 business days.
                 </div>
                 <div className= "px-5" >
                    <img alt="Succes Img" style={{ width: "100%" }} src={"https://cdn.lonestarmga.com/img/send-mailform_412x339.png"}/>
                </div>
                 <div className="submit-position">
                    <div className="successBtn">
                        <Link to="/" className="successBtn"> Go Back Home </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default SuccessForm 