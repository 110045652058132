import React from "react"

const ImageItem = ({data}) => {
  
    return ( 
        <>   
            {data.link_url !== '' ? (
                <a className="client" href={data.link_url} target={data.link_target}>
                    <img className="img-fluid ImageItem" src={data.image.filename}alt="Logo"/>
                </a>
            ):(
                <img className="img-fluid ImageItem" style={{border:"0px"}} src={data.image.filename} alt="Logo"/>
            )}
               
        </> 		
    )
}
 
export default ImageItem 