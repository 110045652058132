/// <reference path="../contact/mastercontactus.js" />
import { useEffect, useState, useReducer } from "react";
import OrderSuppliesForm from "./OrderSupplies";
import SuccessRequest from "./SuccessPage";
import { formReducer, INITIAL_STATE } from "./FormReducer";

const MasterSuppliesForm = () => {
    const [State, Dispatch] = useReducer(formReducer, INITIAL_STATE);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        Dispatch({type: ""});
    }, [State])
    const handleChange = (e) => {
        e.preventDefault();
        Dispatch({
            type: "CHANGE_INPUT",
            payload: { name: e.target.name, value: e.target.value }
        });
    }
    const handleAmountChange = (e, changeType) => {
        e.preventDefault();
        Dispatch({
            type: changeType,
            payload: { name: e.target.name, value: e.target.value }
        });
    }
    const submitForm = () => {
        return setIsSubmitted(true);
    }
    return ( 
        isSubmitted === false ? (
           <OrderSuppliesForm submitForm={submitForm} handleChange={handleChange} state={State} dispatch={Dispatch} handleAmountChange={handleAmountChange} />
        ):isSubmitted === true && (
           <SuccessRequest title="Your Request for this Order has Been Placed"/>
        )
    );
}
 
export default MasterSuppliesForm