import Breadcrumb from "../../Components/Core/BreadCrumb";
import Banners from "../../Components/Core/Banners";
import {useState,useEffect} from "react";
import Announcement from "../../Components/Announcement";
import StoryblokClient from  "storyblok-js-client"
import { Link } from "react-router-dom";
const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
const storybloktoken = process.env.REACT_APP_STORYBLOK_TOKEN;
const items = [
    { to: '/ProducerPortal', label: 'Home' },
    { to: '/Announcements', label: 'Announcements' }
]

// init with access token
const Storyblok = new StoryblokClient({
accessToken: storybloktoken,
cache: {
  clear: 'auto',
  type: 'memory'
}
})
const Announcements = () => {
    const [AlertAnnouncement, setAlertAnnouncement] = useState([]);
        useEffect(() => {
            Storyblok.get('cdn/stories', {
                "token": `${storybloktoken}`,
                "starts_with": "lonestar/alerts-and-announcements/",
                "version":`${storyblokVersion}`
            })
            .then(response => {
                setAlertAnnouncement(response.data.stories);
                
            }).catch(error => { 
                console.log(error)
            })
        },[])
    return (
        <>
            <Banners img={"https://cdn.lonestarmga.com/img/Banners/announcements.png"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/announcements_389x275.svg"} page={"Announcements"} category={"Lonestar MGA"} />
            <div className="announcement-page-body"> 
                <div className="flex-center" >
                    <div className="flex-start p-4 crumb-container">
                        <Breadcrumb>{
                            items.map(({to, label}) =>(           
                                <Link key={to} to={to}>{label}</Link>
                            ))}
                        </Breadcrumb>
                    </div>
                </div>
                <div className="container">
                    <div className="announcement-section">
                       {AlertAnnouncement.length === 0 ? (
                              <div className={`row main-announcement-body`} >
                                <div className='announcement-title main-title pb-2'>Coming Soon</div> 
                                <div className='announcement-text main-text'>Announcements</div>
                                <div className='announcement-description pt-4'>About: Informative highlights of the latest news/updates regarding Lonestar and its products.</div>
                              </div>
                            ): (
                              <Announcement data={AlertAnnouncement} landing="false" />
                            )
                        }
                    </div>
                </div>
            </div>
    </> );
}
 
export default Announcements;