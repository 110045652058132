import Breadcrumb from "../Components/Core/BreadCrumb";
import Banners from "../Components/Core/Banners";
import { Link } from "react-router-dom";
import CaPaymentForm from "../Components/Forms/CAMakePayment/CaPaymentForm";

const items = [
  { to: '/', label: 'Home' },
  { to: '/Customers', label: 'Customers' },
  { to: '/MakeaPayment', label: 'Make a Payment' },
  { to: '/CAPayment', label: 'Commercial Auto Payment' }
]

const CAPayment = () => {
  return (
    <>
          <Banners page="Make a Payment" category="Services" img={"https://cdn.lonestarmga.com/img/Banners/make-a-payment-banner_1920x357.png"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/make-a-payment-banner_390x282.png"} />
      <div className="white-smoke-background" style={{ minHeight: "700px" }}>
        <div className="py-4 mobile-adjust-center crumb-position pl-5" style={{ maxWidth: "1200px" }}>
          <Breadcrumb >{
            items.map(({ to, label }) => (
              <Link key={to} to={to}>{label}</Link>
            ))}
          </Breadcrumb>
        </div>
        <div className="flex-center">
          <div className="RQ-header" >
            Commercial Auto Online Payment
          </div>
        </div>
        <div className="py-3 flex-center p-2">
          <CaPaymentForm />
        </div>
      </div>
    </>
  )
}

export default CAPayment