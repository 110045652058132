import styled from "styled-components";
import {useState,useEffect,useRef} from "react";

import $ from "jquery";
const SummaryPage = (props) => {
    let startPageRef = useRef(null)
    useEffect(()=> {

        startPageRef.current.scrollIntoView({behavior: 'smooth', block: 'start' })
    },[])
    const [FormData,setFormData] = useState({
        "User":props.quote,
        "Vehicles":props.vehicles,
        "Drivers":props.drivers
    })
    const handleSubmit = e => {
        e.preventDefault();
        props.nextStep();
        // console.log(FormData)
        //console.log(JSON.stringify(FormData, function (k, v) { return v === undefined ? "" : v; }))

        $.ajax({
            type: "POST",
            url: "/Email/SendQuoteFormEmail",
            data: JSON.stringify(FormData, function (k, v) { return v === undefined ? "" : v; }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            failure: function (response) {
                alert(response.d);
            }
        });
      };
    return ( 
            <>
                    
                    <div  noValidate autoComplete="off">
                        <div ref={startPageRef} className="flex-start">
                                <div className=" py-4 ">
                                <div style={{lineHeight:"45px", color:"#222222",fontFamily:"Poppins-Regular", fontSize:"30px",textAlign:"left"}}>
                                                Auto Request Form Summary 
                                </div>
                            </div>
                        </div>
                        <FormSection>
                                <div className="flex-start gap-2 ">
                                    <BackBtn  type="button" onClick={props.prevStep} value="Go Back"/> 
                                    <input type="button" onClick={handleSubmit} className="summary-red-btn justify-content-center" value="Submit Form" />
                                </div>
                                <FormCategory className="pt-4  ">Personal Information </FormCategory> <br/>
                                <div className="flex">
                                    <Data className="pt-1 mb-4 flex-basis-5">First Name:</Data> 
                                    <Data className="pt-1 flex-basis-5">{props.quote.FirstName}</Data> 
                                </div>
                                <Divider className="mt-1 flex flex-basis-10" /><br/>
                            
                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">Last Name:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.LastName}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">Address:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.Address}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">City:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.City}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">State:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.State}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">ZIP Code:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.Zip}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">Preferred Method of Contact:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.ContactType}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">Phone Number:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.Phone}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">Email Address:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.Email}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">Birthdate:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.Birthdate}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">Are you currently insured ?:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.Insured}</Data> 
                                </div>
                                <Divider className="mt-1  flex" /><br/>

                                <div className="flex">
                                    <Data className="pt-1 mb-4  flex-basis-5">Current Insurance Company:</Data> 
                                    <Data className="pt-1  flex-basis-5">{props.quote.InsuranceName}</Data> 
                                </div>

                                <FormCategory className="pt-4  flex ">Vehicles </FormCategory> <br/><br/>
                                {props.vehicles.map((data,key) => {
                                    return (
                                        <>
                                            <div className="mb-4 " style={{fontFamily:"Poppins-Semi-Bold", fontSize:"120%"}}>Vehicle {key+1}</div>
                                            <div className="flex" key={key}>
                                                <Data className="pt-1 mb-4  flex-basis-5">Year:</Data> 
                                                <Data className="pt-1  flex-basis-5">{data.Year}</Data> 
                                            </div>
                                            <Divider className="mt-1  flex" /><br/>
                                            <div className="flex" key={key}>
                                                <Data className="pt-1 mb-4  flex-basis-5">Make:</Data> 
                                                <Data className="pt-1  flex-basis-5">{data.Make}</Data> 
                                            </div>
                                            <Divider className="mt-1  flex" /><br/>
                                            <div className="flex" key={key}>
                                                <Data className="pt-1 mb-4  flex-basis-5">Model:</Data> 
                                                <Data className="pt-1  flex-basis-5">{data.Model}</Data> 
                                            </div>
                                            <br/><br/>
                                        </>
                                    )
                                })}
                                
                                <FormCategory className="pt-4  flex ">Drivers </FormCategory> <br/><br/>
                                {props.drivers.map((data,key) => {
                                    return (
                                        <>
                                            <div className="mb-4 " style={{fontFamily:"Poppins-Semi-Bold", fontSize:"120%"}}>Driver {key+1}</div>
                                            <div className="flex" key={key}>
                                                <Data className="pt-1 mb-4  flex-basis-5">First Name:</Data> 
                                                <Data className="pt-1  flex-basis-5">{data.FirstName}</Data> 
                                            </div>
                                            <Divider className="mt-1  flex" /><br/>
                                            <div className="flex" key={key}>
                                                <Data className="pt-1 mb-4  flex-basis-5">Last Name:</Data> 
                                                <Data className="pt-1  flex-basis-5">{data.LastName}</Data> 
                                            </div>
                                            <Divider className="mt-1  flex" /><br/>
                                            <div className="flex" key={key}>
                                                <Data className="pt-1 mb-4  flex-basis-5">Birthdate:</Data> 
                                                <Data className="pt-1  flex-basis-5">{data.Birthdate}</Data> 
                                            </div>
                                            <Divider className="mt-1  flex" /><br/>
                                            <div className="flex pb-4" key={key}>
                                                <Data className="pt-1 mb-4  flex-basis-5">Has this driver had any accidents in the last 3 years?:</Data> 
                                                <Data className="pt-1  flex-basis-5">{data.Accident}</Data> 
                                            </div>
                                        </>
                                    )
                                })}
                            </FormSection> <br/><br/><br/>
                        <div className="align-items-center justify-content-center  flex-basis-10 gap-3 wrap flex-grid align-content-center " >
                            <button onClick={props.prevStep} className="flex-basis-4 white-sm-pill-btn justify-content-center ">
                                Previous
                            </button>
                            <div style={{width:"auto",textAlign:"center"}}>2 of 2</div>
                            <button  onClick={handleSubmit} className="primary-sm-pill-btn flex-basis-4 justify-content-center  ">
                               I'm Ready to Submit Form </button>
                        </div>
                    </div>
            </> 
    );
}
 
export default SummaryPage;

const FormSection = styled.div`
    min-height: 500px;
    padding-bottom:5%;
    padding-top:3%;
    border-radius: 40px;
    background-color: #FFFFFF;
    padding:8%;
    width:100%;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.23);
    @media only screen and (max-width:800px){
        padding:10%;
    }
`;
const Divider = styled.div`
        box-sizing: border-box;
        height: 1px;
        border: 1px solid #979797;
        max-width:100%;
        
`;
const Data = styled.div`
        min-height: 23px;
        color: #222222;
        font-family: Poppins;
        font-size: 120%;
        letter-spacing: 0;
        line-height: 25px
`;

const BackBtn = styled.input`
        background-color:#FFFFFF;
        box-sizing: border-box;
        width:150px;
        height:45px;
        border: 2px solid #222222;
        border-radius: 36px;
        font-size:110%;
        font-family:"Poppins-Bold";
        &:hover{
            border: 2px solid #222222 !important;
            color:#FFFFFF !important;
            background-color: #222222;
        }
`;

const FormCategory = styled.div `
        min-height: 33px;
        max-width: 456px;
        color: #222222;
        font-family: 'Poppins-Bold';
        font-size: 150%;
        letter-spacing: 0;
        line-height: 30px;
`;

