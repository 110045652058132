import TextSection from "../Core/TextSection"
const MediaObject2sb = ({ blok }) => {

    let Headline = blok?.headline;
    let SubHeadline = blok?.subheadline;
    let Description = blok?.description;
    let ButtonText = blok?.button_text;
    let ButtonStyle = blok?.button_style;
    let Image = blok?.image?.filename;
    let Alt = blok.image?.alt;
    let ImageBackground = blok?.image_background?.filename;
    let ImagePosition = blok?.image_position;
    let ImagePosition2 = blok?.Image_Position;
    let LinkURL = blok?.link_url;
    let LinkTarget = blok?.link_target;
    let BackgroundColor = blok?.background_color;
    let TextAlign = blok?.text_align;
    let img = blok?.background_image?.filename;
    return (
        <div className="flex-center">
            <div className="component-spacing" style={{ maxWidth: "1450px" }}>
            <div style={{ background: `url(${img}) center bottom`, backgroundSize: "cover", textAlign: TextAlign, margin: "0px auto" }}>
                <div className="flex-wrap flex">
                    {ImagePosition === "right" ? (
                        <>

                            <div className="col-lg-7 col-md-7 col-sm-12 align-items-center mo-text" style={{ display: "grid" }}>
                                <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} LinkURL={LinkURL}
                                    LinkTarget={LinkTarget} ButtonStyle={ButtonStyle} ButtonText={ButtonText} Story={blok}/>
                            </div>
                                <div className="col-lg-5 background-image-media-object align-items-center" style={{ backgroundImage: `url(${ImageBackground})`}}>
                                    <img src={Image} alt="Icon" className="image-media-object" />
                            </div>

                        </>
                    ) : ImagePosition === "top" ? (
                        <>
                            <div className="mobile-adjust grid">
                                <div className=" align-items-start py-2" style={{ justifyContent: "flex-center", display: "flex", backgroundImage: `url(${ImageBackground})`, backgroundRepeat: "no-repeat", width: "500px", height: "300px" }}>
                                    <img src={Image} alt="Icon" className="mobile-MO-img " style={{ maxWidth: "350px", borderRadius: "30px", backgroundSize: "60%" }} />
                                </div>
                            </div>
                            <div>
                                <div className="flex-start ">
                                    <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} LinkURL={LinkURL}
                                        LinkTarget={LinkTarget} ButtonStyle={ButtonStyle} ButtonText={ButtonText} Story={blok} />
                                </div>
                            </div>

                        </>
                    ) : ImagePosition === "bottom" ? (
                        <>
                            <div className="col-lg-6 col-md-7 col-sm-12 m-auto  mobile-spacing-cntr-m" style={{ paddingTop: "20px", paddingBottom: "0%" }}>
                                <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} LinkURL={LinkURL}
                                    LinkTarget={LinkTarget} ButtonStyle={ButtonStyle} ButtonText={ButtonText} Story={blok}/><br />
                                <div className="row justify-content-center media-object-background-section" style={{ backgroundImage: `url(${ImageBackground})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                                    <div>
                                        <img src={Image} alt={Alt} className="media-object-img-bottom" />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : ImagePosition === "left" && (
                        <>
                            <div className="container-flex ">
                                <div className="col-flex align-items-center justify-content-center parent m-auto p-2" style={{ maxWidth: "500px", display: "grid" }}>
                                    {blok.image_background.filename && (<img className="media-object-overlay image1 " alt={"Media Object Overlay"} src={ImageBackground} />)}
                                    {blok.image.filename && (<img className="media-object-img image2" alt="Media Object Main" src={Image} />)}
                                </div>
                                <div className="col-flex m-auto p-3" style={{ textAlign: "left" }}>
                                    <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} LinkURL={LinkURL}
                                        LinkTarget={LinkTarget} ButtonStyle={ButtonStyle} ButtonText={ButtonText} Story={blok}/>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                </div>
            </div>
        </div>
    )
}

export default MediaObject2sb;