import React, { useEffect, useState } from "react"

const CardCTA = ({data}) => {
    const [Icon,setIcon] = useState("");
    const [IconAlignment,setIconAlignment] = useState("");
    const [Title, setTitle] = useState("");
    const [TitleStyle, setTitleStyle] = useState("");
    const [Tagline, setTagline] = useState("");

    const [ButtonText, setButtonText] = useState("");
    const [ButtonStyle, setButtonStyle] = useState("");
    const [LinkTarget, setLinkTarget] = useState("");
    const [LinkURL, setLinkURL] = useState("");
    const [BackgroundImage, setBackgroundImage] = useState("");

    useEffect(() => {
        data.map((item) => {
            setIcon(item.icon_image?.filename);
            setIconAlignment(item.icon_alignment);
            setTitle(item.title_text);
            setTitleStyle(item.title_style);
            setTagline(item.tagline);
        
            setButtonText(item.button_text);
            setButtonStyle(item.button_style);
            setBackgroundImage(item.background_image?.filename);
            setLinkURL(item.link_url);
            setLinkTarget(item.link_target);
        });

    },[data])
    
    return ( 
        <>   

        <div style={{background: `url("${BackgroundImage}") no-repeat -4% 100%`,backgroundSize:"100px"}} >
           
                        {IconAlignment === "top" ? (
                            <>
                            
                                <div className="">
                                    <img className="ctaIcon mb-2" alt="Icon"src={Icon}></img>
                                </div>
                                <div className={TitleStyle} style={{fontFamily:"Poppins-Bold", fontSize :"17px"}} >{Title}</div>
                                <p className="  ctaTagline">{Tagline}</p>    
                                <div className="flex-center pt-2 pb-3">
                                    <a href={LinkURL} className={`${ButtonStyle}`} style={{height:"48px",marginBottom:"0%",width:"175px"}} target={LinkTarget} >{ButtonText}</a>
                                </div>
                            </>
                        ): IconAlignment !== "top" && (
                            <>
                                <div className= "row">
                                {IconAlignment === "left" && (
                                    <div className="col-lg-12">
                                        <div style={{fontFamily:"Poppins-Semi-Bold", fontSize:"20px"}}><img style={{width:"50px", marginRight:"40px"}}alt="Icon" src={Icon}/> {Title}</div>
                                        <p className="card-text">{Tagline}</p>
                                        <a href={LinkURL} className={ButtonStyle} target={LinkTarget} >{ButtonText}</a>
                                    </div>
                                )}    
                                
                                {IconAlignment === "right" && (
                                    <div className="col-md-2">
                                        <img className ="offset-lg-0 offset-md-4 offset-sm-8" alt ="Icon" src={Icon}/>
                                    </div>
                                )}
                                </div>
                              
                            </>
                        )}
                <br/>
            </div>
        </> 
    )
}
 
export default CardCTA