
import RichTextRevolver from 'storyblok-js-client/dist/rich-text-resolver.cjs';
import React from "react";
import { NODE_LI } from 'storyblok-rich-text-react-renderer';

const ProductCard = ({data}) => {
    const resolver = new RichTextRevolver();
    let rich = data.card_content;


    return ( 
       
        <>
                {/* <div className="col-lg-4 col-md-6 col-sm-12 no-of-package" style={{justifyContent:"center"}}> */}

                <div className="productCard" style={{width:"400px", textAlign:"center"}}>
                        <div className="package" style={{marginTop:"10px"}}>
                                <h5 style={{height:"200px"}}>{data.title_text}</h5>
                                    <div className="innerText" style={{width:"420px", marginTop:"-50px"}}>
                                            <ul className='innerText checkmark offset-lg-1' style={{fontFamily:"Poppins-Regular", width:"250px"}} dangerouslySetInnerHTML={{__html:resolver.render(rich, {
                                                markResolvers: {
                                                    [NODE_LI]: (children) => <><li>{children}</li><br/></>
                                                }
                                                })
                                        }}></ul><br/>
                        </div>
                                <a href={data.link_url} target={data.link_target} className={`btn btn-prim mt-lg-4 mt-md-3 mt-sm-3 ${data.button_style}`}>{data.button_text}</a>
                    </div>
                </div>    
            </> 
        );
}
 
export default ProductCard;