import ReactTooltip from "react-tooltip";
import HoursOfOperation2 from "./HoursOfOperation2";
import { useEffect, useState } from "react";

const ContentList = (props) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    return (
        <div className="contact-list-body">
                <div className="flex-start gap-1">
                    <img className="content-list-main-icon" src={props.data.Icon.filename} alt={props.data.Icon.alt} />
                    <div className="content-list-main-title">{props.data.Title}</div>
                </div><br/>
                <div>
                {
                    props.data.Item.map((item) => {
                        return (
                            <>
                                <div className="content-list-item-body" key={item._uid}>
                                    <div className="flex-basis-3">
                                        <div className="flex-center"><img className="content-list-icon" src={item.Icon.filename} alt={item.Icon.alt} /></div>
                                        <div className="content-list-text">{item.Title}</div>
                                    </div>
                                    {
                                        windowSize.innerWidth < 600 && (
                                            <div className="flex">
                                                <span className="divider"></span>
                                            </div>
                                        )
                                    }
                                        {
                                            item.Content.map((data,index) => {
                                                return (
                                                    <div key={data._uid} className="flex-basis-3 content-list-item">
                                                        <div className="content-list-item-title">{data.Title}</div>
                                                        <div className="py-1 content-list-item-subtitle">{data.Sub_Headline}</div>
                                                        <div>
                                                            <div className="flex-center py-1 help-text">
                                                                Hours Of Operation<span className="help-icon" data-tip data-for={`registerTip${data._uid}`}></span>
                                                                <ReactTooltip id={`registerTip${data._uid}`} place="top" className="tooltip" type="light" effect="solid">
                                                                    <div className="pt-2">
                                                                        {
                                                                            <HoursOfOperation2 data={data.Tool_Tip_Item} index={index} />
                                                                        }
                                                                    </div>
                                                                </ReactTooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                </div><br/>
                               
                            </>)
                    })
                }
                </div>
            </div>
    )
}

export default ContentList