import React,{useEffect,useState} from "react"
import OrderItem from "./OrderItem";
import UnOrderedItem from "./UnOrderedItem";

const ListConfiguration = ({data,list}) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    let IconPostition = data.icon_position;
    let Dividers = data.dividers;
    let Columns = data.number_of_columns;
    let OrderStyle = data.order_style;
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);
    data.map((items) => {
        IconPostition = items.icon_position;
        Dividers = items.dividers;
        Columns = items.number_of_columns;
        OrderStyle = items.order_style;

    }
    )
  
    const lastIndex = data.length - 1
    const spaceBetweenItems = 25; // edit to increase/decrease space between items

    const containerStyle = {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
      };
    
      const itemStyle = {
        display: "block",
        flex: "none",
        width: `${100 / Columns}%`,
        boxSizing: "border-box",     
      };
    
      const verticalStyle = {
        display: "block",
        flex: "none",
        width: `${100 / 1}%`,
        boxSizing: "border-box",
        padding: `${spaceBetweenItems * 0.5}px`,
        justifyContent: "center",
        minHeight:"120px",
        paddingBottom:"2%",
      }
      function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
    return ( <>
               <div style={containerStyle}>{ 
                  list.map((item,index) => {
                  return (<>
                          <div key={index} style={windowSize.innerWidth < 1100 ? verticalStyle : itemStyle}>
                             {item.component === "Ordered_Item" ? (             
                                  <OrderItem data={item} num={index}/>        
                              ): (
                                 <div key={index} className={list.length-1 !== index ?`UI-body align-items-center justify-content-center align-content-center m-1` : `UI-body-last`}>
                                    <UnOrderedItem data={item} key={index}/>
                                  </div>
                               )}                                    
                           </div>
                           </>
                          )
                        })
                      }
                </div>
             </>         
    )
}
export default ListConfiguration 