import { Link } from "react-router-dom"


export default function BasicCard_SB({blok,style}) {
  return (
        <div className={`${blok?.background_color} ${style} card card-spacing`}>
          <Link to={blok?.link_url}>
              <div className="flex-center">
                        <img className="icon-image"src={blok.icon_image?.filename} alt="icon"/>
                        <img className="hover-icon-image" src={blok.hover_icon_image?.filename} alt="hover-icon"/>
                </div>
                <div className={(blok.hover_text.length > 0) ? ("col-text-cntr") : ("")}>
                        {(blok.hover_text.length > 0) ? (<div className="title-text">{blok.title_text}</div>) : 
                        (<div className="square-card-title">{blok?.title_text}</div>)}
                        { blok.hover_text && <>
                            <div className="hover-text" style={{fontSize:"1em"}}>{blok?.hover_text}<br/>
                                <a href={blok?.link_url}>{blok?.link_text}</a>
                            </div>
                            </>
                        }
                    
                </div>
            </Link>
        </div>
  )
}
