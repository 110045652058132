import Breadcrumb from "../../../Components/Core/BreadCrumb";
import { useStoryblok } from "@storyblok/react";
import Banners from "../../../Components/Core/Banners";
import DownloadBtn from '../../../Components/Buttons/DownloadBtn';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const items = [
    { to:'/ProducerPortal',label:'Home'},
    { to: '/DocumentsAndPublications', label: '...' },
    { to: '/FormsAndGuidelines', label: 'Forms and Guidelines' },
    { to:'/TexasPointRider',label:'Lonestar Point Rider'}
]

export default function TexasPointRider() {
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const guidelineStory = useStoryblok("lonestar/producer/guidelines",{ version: storyblokVersion });
    const appFormStory = useStoryblok("lonestar/producer/applications-and-forms",{ version: storyblokVersion });
    const referenceStory = useStoryblok("lonestar/producer/references",{ version: storyblokVersion });
    if(!guidelineStory?.content || !appFormStory?.content || !referenceStory?.content ){
        return <div className="loader-section">
                <MDBSpinner color='danger' className="spinner"></MDBSpinner>
               </div>
    }

    return (
    <>
            <Banners img={"https://cdn.lonestarmga.com/img/Banners/formsandguidelines_borderless.svg"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/forms-and-guidelines_389x275.svg"} page={"Forms and Guidelines"} category={"Documents and Publications"} />      
            <div className="form-guide-page-body"> 
                <div className="link-btn-sec py-3">
                    <Breadcrumb>{
                        items.map(({to, label}) =>(           
                            <Link key={to} to={to}>{label}</Link>
                        ))}
                    </Breadcrumb>
                </div>
                <div className="col-lg-12 row justify-content-center py-2 form-guide-product-header ">
                    Texas - Lonestar MGA Point Rider<br />
                </div>
                <div className="flex-center justify-content-center py-1">
                    <img className="form-guide-PTS-logo" alt="pts logo" src={"https://cdn.lonestarmga.com/img/pts-logo_243x156.png"}></img>
                </div>
                <div className="flex-center py-3">
                    <a href="https://lonestar.live.ptsapp.com/logIn.cfm" target="_blank" className="primary-sm-pill-btn-skinny" > Access PTS </a>
                </div>
                <br />
                <div className="link-btn-sec">
                {
                    appFormStory.content.configuration.map((items) => {
                        return  <div className={`mb-3 mr-4 btn-config-header ${items.header_style} `} style={{textAlign:`${items.headline_alignment}`}}>{items.headline}</div> 
                    })
                }
                            {appFormStory.content.buttons.map((items) =>{
                                return items?.product !== undefined &&  
                                items.product.map((type) => {
                                   return <div>
                                    {type === 'lonestar-point-rider' && 
                                    <>
                                            <DownloadBtn date={items.download_version_date} title={items.download_title} file={items.download_item.filename}/>
                                    </>}</div>
                                })
                            })}
                            <br/>
                            {   referenceStory.content.configuration.map((items) => {
                                  return  <div className={`mb-3 mr-4 btn-config-header ${items.header_style} `} style={{textAlign:`${items.headline_alignment}`}}>{items.headline}</div> 
                                })
                            }
                            {referenceStory.content.buttons.map((items) =>{
                                return items?.product !== undefined &&  
                                items.product.map((type) => {
                                   return <div>
                                    {type === 'lonestar-point-rider' && 
                                    <>
                                            <DownloadBtn date={items.download_version_date} title={items.download_title}file={items.download_item.filename}/>
                                    </>}</div>
                                })
                            })}
                            <br/>
                            {guidelineStory.content.configuration.map((items) => {
                                  return  <div className={`mb-3 mr-4 btn-config-header ${items.header_style} `} style={{textAlign:`${items.headline_alignment}`}}>{items.headline}</div> 
                                })
                            }
                            {guidelineStory.content.buttons.map((items) =>{
                                return items?.product !== undefined &&  
                                items.product.map((type) => {
                                   return <div>
                                    {type === 'lonestar-point-rider' && 
                                    <>
                                            <DownloadBtn date={items.download_version_date} title={items.download_title}file={items.download_item.filename}/>
                                    </>}</div>
                                })
                            })}
                            <br/>
                </div>
        </div>
    </> 
  )
}
