import React from "react";
import styled from "styled-components";
import RichTextRevolver from 'storyblok-js-client/dist/rich-text-resolver.cjs';

const BlogCard = ({data}) => {

  
    return ( 
        <>
               {data.featured_image !== " " && (
                 <div className="flex-center">
                 <BlogImg src={data.featured_image.filename} alt="Blog Icon"></BlogImg>
                </div>
                )}
                <BlogTitle >{data.title_text}</BlogTitle>
                <Description >{data.excerpt_text}</Description>
                <div className="offset-lg-8 pt-3 pb-3">
                    <a className="blog-card-link" href={data.post_link} target="_blank" >{data.link_text}</a>
                </div>       
        </> 
    )
}
 
export default BlogCard

const BlogImg = styled.img`
    justify-content:center;
    max-width:90%;
    max-height:216px; 
    padding-top: 4%;
    
    @media only screen and (max-width:800px){
        max-width:300px;
        max-height:180px;
        padding-top: 3%;
        
    }
`;
const BlogTitle = styled.div `
    color: #B22727;
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
    justify-content:center;
    padding-top:15px;
    align-content:baseline;
    @media only screen and (max-width:800px){
        font-size: 100%;
        line-height: 15px;
    }
    `;

const Description = styled.div`
    text-align:left;
    justify-content:center;
    padding:4%;
`;