import React, { useState } from "react";
import styled from "styled-components";
import { FaAngleDown,FaAngleUp } from "react-icons/fa";
import SubDropdown from "./subdropDown";

const ExpandMenu = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
      <>
        <div className="menu-button" onClick={toggleMenu}><a target={props.route.link_target} href={props.route.path}>
                {props.route.title}</a> {isMenuOpen ?  <FaAngleUp /> : <FaAngleDown /> }
        </div>
        <SubRoutesContainer isOpen={isMenuOpen}>
          {props.route.children_links.map((subRoute,index) => {
            let StoryBlockView = subRoute.page_visibility.sort();
            let CurrentView = props.view.sort();
            let isMatch = StoryBlockView.some((otherItem) =>  CurrentView.includes(otherItem));
            
            return (
              <>
                  {(isMatch) && (
                    subRoute?.children_links != "" ?(
                       <SubDropdown view={CurrentView} route={subRoute} key={index}/>
                    ): (
                       <a className="sub-route" key={index} target={subRoute.link_target} href={subRoute.path}>{subRoute.title}</a>
                    )
                  )}
              </>
            )
          })}
        </SubRoutesContainer>
      </>
  );
};

export default ExpandMenu;
const SubRoutesContainer = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  padding: 1rem;
`;
