import RichText from '../Core/RichText';
import { LinkParse } from '../../Helpers/InputHelper';
import { useEffect, useState } from "react"

export default function SB_TrainingVideo({ blok }) {

    const [YoutubeURL, setYouTubeURL] = useState(LinkParse(blok?.Video_Link?.url));
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
  return (
      <div className="container-training-video">
          <a href={`https://www.youtube.com/embed/${YoutubeURL}`} style={windowSize.innerWidth < 800 ? { backgroundImage: `url(${blok.Thumbnail.filename})`, maxWidth: "300px", height: "200px", backgroundPosition: "center", backgroundSize: "contain" }: { backgroundImage: `url(${blok.Thumbnail.filename})`, width:"350px",height:"200px", backgroundPosition: "center", backgroundSize: "contain" }} target="_blank" className="align-content-center align-items-center corner-wrapper">
        </a><br/>
        <h5>{blok?.Title}</h5>
        <RichText Description={blok?.Description} />
    </div>
  )
}
