import React, { useState, useEffect } from 'react';
import { useAuth } from "../utils/AuthProvider"
const TimeoutLogout = () => {
    const [logout, setLogout] = useState(false);
    const auth = useAuth();

    useEffect(() => {
        let timeoutId;
        const resetTimeout = () => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setLogout(true);
            }, 5340000); // 5 minutes timeout //30000 = 30 seconds //300000 = 5 minutes
        };

        const handleActivity = () => {
            resetTimeout();
            //console.log("activity reset");
        };

        //console.log("ran this useeffect");
        resetTimeout();
        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('keypress', handleActivity);
        return () => {
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keypress', handleActivity);
            clearTimeout(timeoutId);
        };
    });


   
    useEffect(() => {
        if (logout && (JSON.parse(localStorage.getItem("DATA")).rtnval?.id >= -1 ))
        {
            // Perform logout actions here
            // For example:
            auth.logout();
            setLogout(false);
            //console.log("Logout bc" + logout + auth.NavigationView)
        }
    }, [logout, auth.NavigationView]);
    return <></>; // This component doesn't render anything visible
};
export default TimeoutLogout;
