

import React from "react"
import TextSection from "./Core/TextSection";
const MediaObject = ({data}) => {
  
    let Headline = data?.headline;
    let SubHeadline = data?.subheadline;
    let Description = data?.description;
    let ButtonText = data?.button_text;
    let ButtonStyle = data?.button_style;
    let Image = data?.image?.filename;
    let Alt = data.image?.alt;
    let ImageBackground = data?.image_background?.filename;
    let ImagePosition = data?.image_position;
    let LinkURL = data?.link_url;
    let LinkTarget = data?.link_target;
    let BackgroundColor = data?.background_color;
    let TextAlign = data?.text_align;
    let img = data?.background_image?.filename;
    
    return ( 
        <>
        <div style ={{background:`url(${img}) center bottom`, backgroundSize:"cover" , textAlign:TextAlign }}> 
    
                    <div className="flex-wrap" style={{display:"flex"}}>
                    {  ImagePosition === "right"  ? (
                            <>     
                            
                                    <div className="col-lg-7 col-md-7 col-sm-12 mo-text">
                                        <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} LinkURL={LinkURL}
                                                    LinkTarget={LinkTarget} ButtonStyle={ButtonStyle} ButtonText={ButtonText} Story={data}/>
                                     </div>
                                    <div className="col-lg-5 background-image-media-object align-items-center" style={{backgroundImage:`url(${ImageBackground})`}}>                                                                                                              
                                         <img src={Image} alt="icon from story block" className="image-media-object"/>
                                     </div>
                                         
                            </>    
                        ): ImagePosition === "top"  ? (
                            <>  
                                <div className="mobile-adjust grid">
                                    <div className=" align-items-start py-2" style={{justifyContent:"flex-center",display:"flex",backgroundImage:`url(${ImageBackground})`,backgroundRepeat:"no-repeat",width:"500px",height:"300px"}}>                                                                                                              
                                             <img src={Image} alt="Icon" className="image-media-object"/>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex-start ">
                                        <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} LinkURL={LinkURL}
                                            LinkTarget={LinkTarget} ButtonStyle={ButtonStyle} ButtonText={ButtonText} Story={data}/>
                                    </div>
                                </div>
                                        
                            </>
                        ): ImagePosition === "bottom" ? (
                            <>
                                <div className="col-lg-6 col-md-7 col-sm-12 m-auto  mobile-spacing-cntr-m" style={{paddingTop:"20px",paddingBottom:"0%"}}> 
                                        <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} LinkURL={LinkURL}
                                                    LinkTarget={LinkTarget} ButtonStyle={ButtonStyle} ButtonText={ButtonText} Story={data}/><br/> 
                                    <div className="row justify-content-center media-object-background-section"style={{ backgroundImage:`url(${ImageBackground})`,backgroundRepeat:"no-repeat",backgroundPosition:"center",backgroundSize:"cover"}}>
                                         <div>
                                            <img src={Image} alt={Alt} className="media-object-img-bottom"/>   
                                        </div>
                                    </div>
                                </div>
                            </>
                        ): ImagePosition === "left" && (
                            <>
                                    <div className="container-flex ">
                                        <div className="col-flex align-items-center justify-content-center parent m-auto p-2" style={{maxWidth:"500px"}}>

                                            {data.image_background.filename  && ( <img className="media-object-overlay image1 " alt={"Media Object Overlay"} src={ImageBackground}/>  ) }             
                                             {data.image.filename && (<img className="media-object-img image2" alt="Media Object Main" src={Image} /> )}
                                        </div>
                                        <div className="col-flex m-auto p-3" style={{textAlign:"left"}}>
                                            <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} LinkURL={LinkURL}
                                                    LinkTarget={LinkTarget} ButtonStyle={ButtonStyle} ButtonText={ButtonText} Story={data}/>
                                        </div>
                                    </div>
                            </>
                        )}  
                            </div>
                        </div>
              
        </>
     )
}
 
export default MediaObject;