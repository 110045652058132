import ReactTooltip from "react-tooltip";
import { useEffect, useState,useRef } from "react"
import { Link } from "react-router-dom";
import $ from "jquery";
import ReportHoursOfOperationGlobal from "./ReportNewClaimHours";
import ClaimBusinessHoursGlobal from "./ClaimBusinessHoursGlobal";
import PolicyHoursOfOperationGlobal from "./PolicyServiceHourofOperation";
import HoursOfOperationGlobal from "./HoursOfOperationGlobal";
import { useAuth } from "../../utils/AuthProvider";

export default function ButtonSB({ blok }) {
    const [PermissionGranted, setPermissionGranted] = useState(false);
    const [Passed, setPassed] = useState(false);
    let RedirectRes = useRef(null);
    let ExternalId = useRef(null);
    let Token = useRef(null);
    const auth = useAuth();

    useEffect(() => {
        let producerId = JSON.parse(localStorage.getItem("DATA"))?.rtnval?.externalId;
      
        if(producerId === null){
            setPermissionGranted(false);
           // console.log("in the if for undefned: producer portal external ID: ButtonSB.js");
        }else{
            setPermissionGranted(true);
           // console.log("in the if for YES: producer portal external ID: ButtonSB.js");
        }
    });

    useEffect(() => {
        if (Passed) {
            //console.log("Passed = " + Passed);
            document.getElementById("submitForm").click();
            setPassed(false);
        }
    }, [Passed]);

    const AjaxEncodeInfo = (e) => {
        let producerId = JSON.parse(localStorage.getItem("DATA"))?.rtnval?.externalId;

        e.preventDefault(e);
        $.ajax({
            type: "POST",
            url: "/Commercial/AjaxEncodeInfo",
            data: JSON.stringify({ ExternalId: producerId }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
               // console.log(response)
                window.open(`${response}`, '_self');
            }
        });
    }
    const AjaxEncodeIDCards = (e) => {
        let producerId = JSON.parse(localStorage.getItem("DATA"))?.rtnval?.externalId;

        e.preventDefault(e);
        $.ajax({
                type: "POST",
            url: "/Commercial/AjaxEncodeIDCards",
            data: JSON.stringify({ ExternalId: producerId }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response) {
                    window.open(`${response}`, '_self');
                }
                });
    }
    const AjaxEncodeEndorsement = (e) => {
        e.preventDefault(e);
        let producerId = JSON.parse(localStorage.getItem("DATA"))?.rtnval?.externalId;

        $.ajax({
            type: "POST",
            url: "/Commercial/AjaxEncodeEndorsement",
            data: JSON.stringify({ ExternalId: producerId }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                window.open(`${response}`, '_self');
            },
            failure: function (response) {
                console.log(response);
            },
        });
    }
    const AjaxPolicyPayment = (e) => {
        e.preventDefault(e);
        let producerId = JSON.parse(localStorage.getItem("DATA"))?.rtnval?.externalId;
      //  console.log(producerId);
        $.ajax({
            type: "POST",
            url: "/Jwalk/StageJwalk",
            data: JSON.stringify({ ExternalId: producerId}),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                //console.log(response)
                Token.current = response.token;
                RedirectRes.current = response.url;
                ExternalId.current = producerId;
                if (response.url === "NO_REDIRECT") {
                    setPassed(false);
                } else {
               //     console.log("Setting passed to true");
                    setPassed(true);
                }
            },
            failure: function (response) {
                console.log(response);
            },
        });
    }
  return (
    <>
         {
           blok.link_target === "_self" ? ( 
                  <Link key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center ${blok.button_style}`} to={blok.link_url}>
                      <div className="flex-basis-10">{blok.button_text}</div>
                  </Link> 
           ) : blok.link_target === "_Phone" ? (
                <a key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center ${blok.button_style}`} href={blok.link_url} target="_self">{blok.button_text}</a>
           ) : blok.link_target === "_function" ? (
                blok.link_url === "IDCards" ? (
                        (PermissionGranted && (auth.MaintenanceMessage.isDown === false)) ? (
                                  <a key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center enable-pointer ${blok.button_style}`} onClick={AjaxEncodeIDCards}>
                                      <div className="flex-basis-10">{blok.button_text}</div>
                                  </a>
                        ):(
                            <div key={blok._uid} className={`row ${blok?.align_content} align-content-center align-item-center disabled-basic-grey-border-btn`}>
                                <div className="flex-basis-10">{blok.button_text}</div><br/>
                                          {((auth.MaintenanceMessage.isDown === true) && PermissionGranted) ? <span>Down for Maintenance</span> : <span>No Access: Please reach out to your marketing representative.</span>}
                            </div>
                        )
                ): blok.link_url === "EndorsementRequest" ? (
                        (PermissionGranted && (auth.MaintenanceMessage.isDown === false)) ? (
                                      <a key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center enable-pointer ${blok.button_style}`} onClick={AjaxEncodeEndorsement}>
                                          <div className="flex-basis-10">{blok.button_text}</div>
                                      </a>
                        ):(
                            <div key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center disabled-basic-grey-border-btn`}>
                                <div className="flex-basis-10">{blok.button_text}</div><br/>
                                {((auth.MaintenanceMessage.isDown === true) && PermissionGranted) ? <span>Down for Maintenance</span> : <span>No Access: Please reach out to your marketing representative.</span>}
                            </div>
                          )
                ) : blok.link_url === "QuotePOSInfo" ? (
                       (PermissionGranted && (auth.MaintenanceMessage.isDown === false)) ? (
                                          <a key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center enable-pointer ${blok.button_style}`} onClick={AjaxEncodeInfo}>
                                              <div className="flex-basis-10">{blok.button_text}</div>
                                          </a>
                        ):(
                            <div key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center disabled-basic-grey-border-btn`}>
                              <div className="flex-basis-10">{blok.button_text}</div><br />
                              {((auth.MaintenanceMessage.isDown === true) && PermissionGranted) ? <span>Down for Maintenance</span> : <span>No Access: Please reach out to your marketing representative.</span>}
                            </div>
                        )
                ): blok.link_url === "PolicyInquiryPostPayment" && (
                    (PermissionGranted && (auth.MaintenanceMessage.isDown === false)) ?
                        <>
                                                  <a key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center enable-pointer ${blok.button_style}`} onClick={AjaxPolicyPayment}>
                                                      <div className="flex-basis-10">{blok.button_text}</div>
                                                  </a>
                                                  <form method='post' action={RedirectRes.current} style={{ backgroundColor:"transparent", width:"100px" }} >
                                            <input id="user" name="user" type='hidden' value={ExternalId.current} />
                                            <input id="InToken" name="InToken" type='hidden' value={Token.current} />
                                            <input id="InpState" name="Carrier" type='hidden' value="Lonestar" />
                                                      <button type="submit" id="submitForm" style={{ backgroundColor: "transparent" }} ></button>
                                        </form>
                           
                       </>
                      :(
                          <div key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center disabled-basic-grey-border-btn`}>
                                <div className="flex-basis-10">{blok.button_text}</div><br />
                                {((auth.MaintenanceMessage.isDown === true) && PermissionGranted) ? <span>Down for Maintenance</span> : <span>No Access: Please reach out to your marketing representative.</span>}
                          </div>
                      )
                )
           ) : ( // target={_blank} = default cause 
                 <a key={blok._uid} className={` row ${blok?.align_content} align-content-center align-item-center ${blok.button_style}`} href={blok.link_url} target={blok.link_target}>
                     <div className="flex-basis-10">{blok.button_text}</div><br />
                 </a>
           )
         }
         {
              blok.help_text !== "" && (
                <> 
                    <div  className="row justify-content-center help-text" style={{width: "-webkit-fill-available"}}>
                        {blok.help_text}<span className="help-icon" data-tip data-for={`registerTip${blok._uid}`}></span>
                        <ReactTooltip id={`registerTip${blok._uid}`} place="top" className="tooltip" type="light" effect="solid">
                            <div className="pt-2">
                            {
                                blok?.claim_hours ? <ClaimBusinessHoursGlobal /> : blok?.policy_hours ? <PolicyHoursOfOperationGlobal /> : blok?.report_hours ? <ReportHoursOfOperationGlobal /> : <HoursOfOperationGlobal />
                            }
                            </div>
                        </ReactTooltip>
                    </div>
                </>
              )
          }
    </>
  )
}
