import { useStoryblok } from "@storyblok/react";
import Phone from "../../Core/Phone";
import Address from "../../Core/Address";
import { Link } from "react-router-dom";

const Footer = () => {
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const slug = "lonestar/footer/company-footer";
    const story = useStoryblok(slug, { version: storyblokVersion });

    const FooterNavslug = "lonestar/footer/footer-navigation";
    const FooterStory = useStoryblok(FooterNavslug, { version: storyblokVersion });

    const BottomFooterSlug = "lonestar/footer/bottom-footer";
    const BottomFooterStory = useStoryblok(BottomFooterSlug, { version: storyblokVersion });

    if(!story?.content || !FooterStory?.content || !BottomFooterStory?.content){
        return <div></div>
    }

    const currentUrl = window.location.href;
    const CCRULink = currentUrl.includes("qa") || currentUrl.includes("dev") || currentUrl.includes("localhost") ? process.env.REACT_APP_DEVCONS_URL : process.env.REACT_APP_PRODCONS_URL;

    let data = story.content;
    let DataFooter = FooterStory.content.navigationLinks;
    let BottomFooterData = BottomFooterStory.content.navigationLinks;

    return (
        <>
            <div className="section-padding-none">
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 footer-sec">
                                <h5 style={{fontFamily:"Poppins-Bold"}}>Company</h5>
                                <ul>
                                    <div style={{fontFamily:"Poppins-Regular"}}>
                                        <div style={{color:"white"}}>
                                        { <Address data={data.address_info}/>}
                                        </div>
                                    </div>
                                </ul>
                                <ul>
                                    <div>
                                        <div style = {{color:"white",fontFamily:"Poppins-Bold"}}>  
                                            { <Phone/> }
                                        </div>
                                    </div>
                                    <br/>
                                    {data.footer_navigation_links.map((item,index) => {
                                        return( <div key={index}> 
                                                    <div >
                                                        {item.link_target === "_self" ? (<>
                                                            <Link key={index} to={item.path}>{item.title}</Link>
                                                        </>) : (<><a key={index} target={item.link_target} href={item.path}>{item.title}</a></>)}
                                                            
                                                    </div><br/> 
                                        </div>)
                                        })}
                                </ul>
                            </div>
                            {
                        DataFooter.map((items,index) => {
                        return(
                            <div key={index} className="col-lg-3 col-md-3 col-sm-6 footer-sec">
                                <h5 style={{fontFamily:"Poppins-Bold"}}>{items.title}</h5>
                                {
                                    items.children_links.map((item,index) => {
                                    return (<div key={index}> 
                                                <div key={index} >
                                                    {item.link_target === "_self" ? (
                                                        <Link key={index} to={item.path}>{item.title}</Link>
                                                           
                                                    ) : (
                                                        <a key={index} target={item.link_target} href={item.path}>{item.title}</a>
                                                        )}
                                                </div><br/> 
                                            </div>)
                                    })
                                }
                                {items.title === "Products" ? (
                                    <>
                                        <a className="flex-start" target="_blank" rel="noopener noreferrer" href={CCRULink}><img alt="icon" src={"https://cdn.lonestarmga.com/img/CIRU-Final.png"} style={{ width: "200px" }}></img></a>
                                        <br />
                                    </>
                                ):(<></>)
                                }
                            </div>
                            )
                        })
                    }
                </div> 
                    </div>
                <div className="bottom">
                        <br/>
                    <div className="flex-center align-items-center align-content-center ">
                            { BottomFooterData.map((item,index) => {
                                    return (
                                        <div key={index} className="p-2 pt-3">
                                            {item.link_target === "_self" ? (<>
                                                <Link key={index} to={item.path}>{item.title}</Link>
                                            </>) : (<><a key={index} target={item.link_target} href={item.path}>{item.title}</a></>)}
                                        </div>
                                    )
                                })}
                    </div>
                </div>
                <div className="container-fluid copyright" style={{justifyContet:"center", textAlign:"center"}}>
                    <div className="container">
                        <div className="row">
                            <div className=" col-lg-12 col-md-6">
                                <p>Copyright &copy; {(new Date().getFullYear())} All rights reserved to this company.</p>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </> )
}
 
export default Footer 