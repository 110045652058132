import React, { useEffect,  useState } from "react";
import { Link } from "react-router-dom";
import {NODE_LI, NODE_PARAGRAPH, NODE_UL,MARK_CODE, MARK_LINK,NODE_OL} from "storyblok-rich-text-react-renderer";
import {render} from 'storyblok-rich-text-react-renderer-ts';

const RichText = (props) => {

    const [Description, setDescription] = useState('')

    useEffect(() => {
        setDescription(props.Description)
    }, [props.Description])

    return ( 
        render(Description,{
            nodeResolvers: {
                textStyle: (Description, attrs) => <span  style={{ color: attrs?.color }}>
                    {Description}
                </span>,
                [NODE_LI]: (Description) => <><li key={`${Description}`}>{Description}</li><br /></>,
                [NODE_OL]: (Description) => <><ol key={`${Description}`}>{Description}</ol><br /></>,
                [NODE_UL]: (Description) => <><ul className="media-list">{Description}</ul></>,
                [NODE_PARAGRAPH]: (Description) =>
                    <>
                        <p style={{ color: Description?.attrs?.color }} className="paragraph-text">{Description} </p>
                    </>
                
            },
            markResolvers: {
                textStyle: (Description, attrs) => <span style={{ color:attrs?.color }}>
                    {Description}
                </span>,
                superscript: (children) => <sup>{children}</sup>,
                subscript: (children) => <sub>{children}</sub>,
                [MARK_CODE]: (Description) => <span style={{color:"blue", fontFamily:"Poppins-Bold"}}>{Description === "City" ? props.city : props.state}</span>,
                [MARK_LINK]: (children,props) => {
                    const {linktype,href,target} = props;
                    if(linktype === 'email') {
                        return <a className="RichTxt-Hyperlink" href={`mailto: ${href}`}>{children}</a>;
                    }
                    if (href.match(/^(https?:)?\/\//)) {
                        // External links: map to <a>
                        return <a href={href} className="RichTxt-Hyperlink" target={target}>{children}</a>;
                    }
                    if (href.match(/^(tel?:)/)) {
                        // External links: map to <a>
                        return <a href={`${href}`} className="RichTxt-Hyperlink" target={target}>{children}</a>;
                    }
                    
                        return <><Link to={href} className="RichTxt-Hyperlink"><a>{children}</a></Link></>
                }
            }
        })
    )
}
export default RichText;