import { Link } from "react-router-dom";

const LinkButton = ({data,config,style}) => {
  
    return ( 
    <>  <div className="flex-center align-items-center ">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
       {data.link_target === "_self" ? <><Link className={` ${style === "" ? ("default-link-btn"):(style)}`} to={data.link_url} >{data.link_text}<i/></Link><br/></>:
         <a href={data.link_url}target={data.link_target}><button className={` ${style === "" ? ("default-link-btn"):(style)}`} >{data.link_text} <i></i></button></a>}
        </div>
    </>
    );
}
 
export default LinkButton;