/// <reference path='components/forms/requestlogin/masterrequestlogin.js' />
//Pages
import Home from './Pages/Home';
import AboutUs from './Pages/About';
import CustomerFAQ from './Pages/CustomerFAQ';
import PaymentFAQ from './Pages/PaymentFAQ';
import PersonalAuto from './Pages/PersonalAuto';
import TermsofUse from './Pages/TermsofUse';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import MakeaPayment from './Pages/MakeaPayment';
import ContactUs from './Pages/ContactUs';
import Customers from './Pages/Customers'
import RequestAQuote from './Pages/RequestAQuote';
import Progress from './Pages/Progress';
import ClaimCenter from './Pages/ClaimCenter/ClaimCenter';
import ClaimFAQ from './Pages/ClaimCenter/ClaimFaq';
import ClaimStatus from './Pages/ClaimCenter/ClaimStatus';
import RequestDoc from './Pages/ClaimCenter/RequestDocs';
import FileClaim from './Pages/ClaimCenter/FileClaim';
import InvolvedAccident from './Pages/ClaimCenter/Accident';
import ProducerLogin from './Pages/Producers/Login';
import RequestLogin from './Pages/Producers/RequestLogin';
import ForgotPassword from './Pages/Producers/ForgotPassword';
import CommercialAuto from './Pages/CommercialAuto';
import CAPayment from './Pages/CAPayment';

 export const AppRoutes = [
     {path:'/', element: <Home/> },
     {path:'/AboutUs', element: <AboutUs/>},
     {path:'/CustomerFAQ', element: <CustomerFAQ />},
     {path:'/PaymentFAQ', element: <PaymentFAQ />},
     {path: '/PersonalAuto', element: <PersonalAuto /> },
     {path:'/CommercialAuto', element: <CommercialAuto />},
     {path:'/TermsofUse', element: <TermsofUse />},
     {path:'/PrivacyPolicy', element: <PrivacyPolicy />},
     {path:'/MakeaPayment', element: <MakeaPayment/>},
     {path:'/ContactUs',element: <ContactUs />},
     {path:'/Customers',element: <Customers />},
     {path:'/CustomerFAQ',element: <CustomerFAQ />},
     {path:'/RequestAQuote',element: <RequestAQuote />},
     {path:'/ClaimCenter',element: <ClaimCenter />},
     {path:'/ClaimFAQ',element: <ClaimFAQ/>},
     {path:'/RequestedDocs',element: <RequestDoc />},
     {path:'/Construction',element: <Progress />},
     {path:'/FileClaim',element: <FileClaim />},
     {path:'/Accident', element: <InvolvedAccident /> },  
     {path: '/ProducerLogin', element: <ProducerLogin /> },
     {path:'ProducerLogin/:email', element: <ProducerLogin /> },
     {path:'/ForgotPassword',element:<ForgotPassword />},
     {path:'/RequestLogin', element: <RequestLogin /> },
     {path:'RequestLogin/:token', element:<RequestLogin />},
     {path:'/CAPayment',element:<CAPayment />}
 ];


