import LoginForm from '../../Components/Forms/LoginUsers/LoginForm';
import Breadcrumb from '../../Components/Core/BreadCrumb';
import { StoryblokComponent, useStoryblok } from '@storyblok/react';
import ListObject from '../../Components/ListObject';
import styled from "styled-components";
import DependentMediaObject from '../../Components/DependentMediaObject';
import ContentObject from '../../Components/ContentObject';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

const items = [
    { to: '/', label: 'Home' },{ to: '/ProducerLogin', label: 'Producers' },
]
const Divider = styled.div`
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid #979797;
`;
export default function Login() {


    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const BannerSection = {
        background: `url(${'https://cdn.lonestarmga.com/img/Banners/new producer img.svg'}) no-repeat`,
        height: "250px",
        width: "100%",
        backgroundSize:"100% 116%"
    };
    const LargeBannerSection = {
        background: `url(${'https://cdn.lonestarmga.com/img/Banners/new producer img.svg'}) no-repeat`,
        height: "250px",
        backgroundSize: "contain",
        width: "100%",
        backgroundSize:"100% 116%"
    };
    const MobileBannerSection = {
        background: `url(${'https://cdn.lonestarmga.com/img/Banners/mobile/producer-banner_390x282px.png'}) no-repeat center bottom`
    }
    let { email } = useParams();
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const FeaturesListslug = "lonestar/producer/producer-portal-features-list"
    const FeaturesListStory = useStoryblok(FeaturesListslug, { version: storyblokVersion });

    const systemAccessSlug = "lonestar/producer/systems-access";
    const systemAccessStory = useStoryblok(systemAccessSlug,{ version: storyblokVersion });
    const PTSslug = "lonestar/producer/what-is-pts";
    const PTSstory = useStoryblok(PTSslug,{ version: storyblokVersion });
    const ResourceSlug = "lonestar/customer-content/online-resources"
    const ResourceStory = useStoryblok(ResourceSlug,{ version: storyblokVersion });
    const BtnResource1Slug = "lonestar/producer/online-resources-button-set-1"
    const BtnResource1Story = useStoryblok(BtnResource1Slug,{ version: storyblokVersion });
    const BtnResource2Slug = "lonestar/producer/online-resources-button-set-2"
    const BtnResource2Story = useStoryblok(BtnResource2Slug,{ version: storyblokVersion });
    const AboutLicensingSlug = "lonestar/producer/about-us-licensing-appts"
    const AboutLicensingStory = useStoryblok(AboutLicensingSlug,{ version: storyblokVersion });

    const story = useStoryblok("lonestar/producer/producer-login",{version: storyblokVersion});
    if(!story?.content || !FeaturesListStory?.content || !systemAccessStory?.content || !PTSstory?.content || !ResourceStory?.content
        || !BtnResource1Story?.content || !BtnResource2Story?.content || !AboutLicensingStory?.content)
        {return <div className="loader-section">
                    <MDBSpinner color='danger' className="spinner"></MDBSpinner>
                </div>
    }
    return (
        <>
            <div className='flex-center '>
                <div className='producer-portal-background producer-portal-size'>
                    <div className='flex-center wrap'>
                        <div className="pb-4 pt-3 pl-4 login-breadcrumb" style={{width:"1135px"}}>
                            <Breadcrumb>{
                            items.map(({to, label}) =>(           
                                <Link key={to} to={to}>{label}</Link>
                            ))}
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className='flex-center' > 
                        <div className={`container-flex flex-center ${windowSize.innerWidth < 850 ? 'gap-2' : 'gap-10'}`}>
                            <div className='col-flex login-form-position' >
                                <LoginForm params={email}/>
                            </div>
                            <div className="col-flex list-object-login-page" >
                                <ListObject data={FeaturesListStory.content} headingPosition={"start"} />
                            </div>
                    </div>
                    </div>
                </div>
            </div><br/>
            <div className='flex-center' >
                <div className={`banner-sub-heading ${windowSize.innerWidth < 850 && 'py-3'}`} style={{maxWidth:'1300px',paddingLeft:'2.4%'}}>
                    System Access
                </div>
            </div>
                <div className='flex-center wrap pb-4'>
                    <div className='white-body-accordion'>
                        <DependentMediaObject data={systemAccessStory.content} />
                            <Divider/>
                        <ContentObject data={PTSstory.content} />
                    </div>
                </div>
            <StoryblokComponent blok={story.content} />
    </>
    )
}


