import CardGrid from '../Components/Cards/CardGrid';
import Header from '../Components/Header';
import { useStoryblok, StoryblokComponent } from "@storyblok/react";
import ImageList from '../Components/ImageList';
import { MDBSpinner } from 'mdb-react-ui-kit';
import ContentObject2 from '../Components/ContentObject2';
import ContentObject2Bottom from '../Components/ContentObject2Bottom';

const Home = () => {
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
    const MediaStory = useStoryblok("lonestar/what-we-offer", { version: storyblokVersion });
    const HeadersStory = useStoryblok("lonestar/header", { version: storyblokVersion });
    const QuickLinkStory = useStoryblok("lonestar/quick-links", { version: storyblokVersion });
    const PWYGoStory = useStoryblok("lonestar/protection-wherever-you-go", { version: storyblokVersion });
    const PartnerStory = useStoryblok("lonestar/our-partners", { version: storyblokVersion });
    const HelpJoinStory = useStoryblok("lonestar/need-help-join-us", { version: storyblokVersion });
    const PWYGTextStory = useStoryblok("lonestar/protection-wherever-you-go-text", { version: storyblokVersion });
    const PWYG2Story = useStoryblok("lonestar/protection-wherever-you-go-text-2", { version: storyblokVersion });
    const OfferBottom = useStoryblok("lonestar/what-we-offer-2", { version: storyblokVersion });
    const story = useStoryblok("lonestar/pages/home", { version: storyblokVersion });

    if (!MediaStory?.content || !HeadersStory?.content || !QuickLinkStory?.content
        || !PWYGoStory?.content || !PartnerStory?.content || !HelpJoinStory?.content || !OfferBottom?.content
        || !PWYGTextStory?.content || !story?.content || !PWYG2Story?.content) {
        return <div className="loader-section">
            <MDBSpinner color='danger' className="spinner"></MDBSpinner>
        </div>
    }
    let background = PWYGoStory.content.configuration;
    let BackgroundImage = "";

    background.map((item) => {
        return BackgroundImage = item.background_image.filename
    })

    return (
        <>
            <div className="white-smoke-background">
                <div className="row justify-content-center no-gutters">
                    <div className="col-lg-12">
                        <Header data={HeadersStory.content} />
                    </div>
                    <div className="col-lg-12 section-2 row justify-content-center">
                        <CardGrid data={QuickLinkStory.content} />
                    </div>
                </div>
            </div>
            <div className='container p-5-full'>
                <ContentObject2Bottom data={OfferBottom.content} />
            </div>
            <div className="section-4 white-smoke-background" style={{ background: `url(${BackgroundImage}) no-repeat 100% top`, backgroundSize: "18%" }}>
                <div className="container">
                    <div className='row justify-content-center align-items-center' >
                        <div className='col-lg-6 col-md-12' >
                            <CardGrid data={PWYGoStory.content} />
                        </div>
                        <div className="col-lg-6 col-md-12" >
                            <ContentObject2 data={PWYG2Story.content} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                        <StoryblokComponent blok={story.content} />
                </div>
            </div>
            <div className="grey-gradient-background">
                <div className="container help-join-padding" >
                    <CardGrid data={HelpJoinStory.content} />
                </div>
            </div>
                <div className="our-partners-container">
                <ImageList data={PartnerStory.content} />
            </div>
        </>
    );
}
export default Home;


