import React from 'react';
import styled from "styled-components";
import RichText from './Core/RichText';

const UnOrderedItem = ({data}) => {

    let Description = data.description

    return ( 
        <>  
            <div className='flex-center '>
                    <div className="">
                        {data.icon_image.filename !== "" ? (
                        <img style={{width:"65px", paddingRight:"10px"}} alt="icon" src={data.icon_image.filename}/>
                        ): (<><div style={{width:"65px"}}></div></>)}
                    </div>
                    <div className="col-flex" style={{width:"100%"}}>                              
                            <HeadingUI>{data.list_text}</HeadingUI>
                            <div className='flex-wrap' style={{Width:"100%"}}>
                            <RichText Description={Description} />
                            </div>
                    </div>
            </div>
        </>
     )

}

export default UnOrderedItem 

const HeadingUI = styled.div`
   font-size:20px;
   height: 100%;
   line-height:30px;
   width:100%;
   font-family: 'Poppins-Semi-Bold';

   
`;