/// <reference path="../fileclaim/fileaclaim.js" />
import React, { useEffect, useState } from "react";

import EditVehicle from "./Modals/VehicleEdit";
import styled from "styled-components";
import states from "../../../Data/US-states";
import validate from "../Validation/FormValidation"

//Modal
import VehicleModal from "./Modals/VehicleModal";
import DriverModal from "./Modals/driverModal";

import "../../../Assets/Style/FormsStyle.scss"
import EditDriver from "./Modals/editDriverModal";
import RemoveModal from "./Modals/removeInputModal";

import { limit, formatPhoneNumber, TodayDate } from "../../../Helpers/InputHelper";

const RequestQuoteForm = (props) => {
    const [formErrors, setFormErrors] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [addVehicles, setAddVehicles] = useState(false);
    const [addDrivers, setAddDrivers] = useState(false);
    const [confirmVehc, setConfirm] = useState(false);
    const [confirmDriv, setConfirmDriver] = useState(false);
    const [Attempt, setAttempt] = useState(false);

    useEffect(() => {
        if (confirmVehc === true && addVehicles === true) {
            props.setVehicles([...props.vehicles, { Year: '', Make: '', Model: '' }]);
            setConfirm(false)
        }
    });
    useEffect(() => {
        if (formErrors === "Ready" && isSubmitting) {
            props.nextStep()
        }
    }, [formErrors, isSubmitting]);
    useEffect(() => {
        if (Attempt === true) {
            setFormErrors(validate(props.quote))
            if (formErrors > 1 || formErrors === "not ready") {
                setIsSubmitting(false);
                let erroed = document.querySelectorAll("[style*='background-color: white; border: 3px solid rgb(178, 39, 39);']")
                //erroed[0]?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            } else if (formErrors <= 0) {
                setFormErrors("Ready");
                setAttempt(false);
            }
        }
    })

    useEffect(() => {
        if (confirmDriv === true && addDrivers === true) {
            props.setDrivers([...props.drivers, { FirstName: '', LastName: '', Birthdate: '', Accident: 'null' }]);
            setConfirmDriver(false)
        }
    });

    function updateVehicle(id, newYear, newMake, newModel) {
        const updateVehicles = props.vehicles.map((car, index) => {
            if (id === index) {
                return { ...props.vehicles, Year: newYear, Make: newMake, Model: newModel };
            }
            return car;
        });
        props.setVehicles(updateVehicles);
    }
    function updateDriver(id, newName, newLastName, newBirthdate, newAccident) {

        const updatedDrivers = props.drivers.map((driver, index) => {
            if (id === index) {
                return {
                    ...props.drivers, FirstName: newName, LastName: newLastName,
                    Birthdate: newBirthdate, Accident: newAccident
                }
            }
            return driver;
        });
        props.setDrivers(updatedDrivers)
    }
    function openDriverModal() {
        setAddDrivers(!addDrivers);
    }
    function openModal() {
        setAddVehicles(!addVehicles);
    }
    const handleSubmit = e => {
        e.preventDefault();
        setAttempt(true);
        setIsSubmitting(true);
    };

    return (
        <>
            <form noValidate autoComplete="off">
                <div className="form-body-pos">
                    <div className=" py-4 justify-content-center">
                        <div className="form-title"> Tell Us About Yourself</div>
                    </div>
                    <div className="form-section">
                        <div className=" ">
                            <div className="p-3 container-flex ">
                                <FormCategory className="flex-basis-10">Personal Information </FormCategory>
                                <div className=" required-box">
                                    <div className="required-txt required-asterisk ">Required</div>
                                </div>
                            </div>
                            <div className="container-flex">
                                <div className="flex-basis-5 p-3"> First Name<span className="required-asterisk"></span>
                                    <input className="form-input-sm auto-capitalize" maxLength="256" onChange={props.handleChange} value={props.quote.FirstName} type="text" id="Yes" name="FirstName" />
                                </div>
                                <div className="flex-basis-5 p-3"> Last Name <span className="required-asterisk"></span>
                                    <input className="form-input-sm auto-capitalize " maxLength="256" id="Yes" onChange={props.handleChange} value={props.quote.LastName} type="text" name="LastName" />
                                </div>
                            </div>
                            <div className="container-flex">
                                <div className="flex-basis-10 p-3">Address<span className="required-asterisk"></span>
                                    <input className="form-input-sm pl-2 auto-capitalize " id="Yes" maxLength="256" onChange={props.handleChange} value={props.quote.Address} type="text" name="Address" />
                                </div>
                            </div>
                            <div className="container-flex">
                                <div className="flex-basis-5 p-3">City<span className="required-asterisk"></span>
                                    <input maxLength="256" className="form-input-sm auto-capitalize" id="Yes" onChange={props.handleChange} value={props.quote.City} type="text" name="City" />
                                </div>
                                <div className="flex-basis-2 p-3">State<span className="required-asterisk"></span>
                                    <select className="form-input-sm " value={props.quote.State} onChange={props.handleChange} id="YesSelect" name="State" style={{ width: "250px" }}>
                                        {states.map(option => (
                                            <option key={option.index} value={option.State}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex-basis-1 pr-3 pt-3 pb-3 RQ-zip">ZIP Code<span className="required-asterisk"></span>
                                    <input className="form-input-sm" value={props.quote.Zip} id="Yes" onChange={props.handleChange} onKeyUp={(e) => limit(e, 5)} onKeyDown={(e) => limit(e, 4)} style={{ width: "170px" }} type="number" min="0" max="5" name="Zip" />
                                </div>
                            </div>
                            <div className="container-flex">
                                <div className="flex-basis-5 pt-3 pb-3">
                                    <div className="pl-3 pr-3">Preferred method of contact<span className="required-asterisk"></span></div>
                                    <input type="button" className={` btnYes2 flex-basis-2 contactBtn mr-3 ml-3 ${(props.quote.ContactType === "Email") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="ContactType" id="Email" value={`Email`} />
                                    <input type="button" className={` btnYes2 flex-basis-2 contactBtn  mr-3 ${(props.quote.ContactType === "Phone") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="ContactType" id="Phone" value={`Phone`} />
                                </div>
                                <div className="flex-basis-5 p-3"> Phone Number{props.quote.ContactType === "Phone" && (<span className="required-asterisk"></span>)}<br />
                                    <input id={(("Phone" === props.quote.ContactType)) ? "Yes" : "NotRequired"} className=" form-input-sm " value={props.quote.Phone} onChange={props.handleChange} onKeyUp={(e) => formatPhoneNumber(e)} onKeyDown={(e) => formatPhoneNumber(e)} maxLength="12" type="tel" name="Phone" />
                                    <br />
                                </div>
                            </div>
                            <div className="container-flex">
                                <div className="flex-basis-10 p-3">Email Address{props.quote.ContactType === "Email" && (<span className="required-asterisk"></span>)}<br />
                                    <input maxLength="256" id={(("Email" === props.quote.ContactType)) ? "Yes" : "NotRequired"} className="form-input pl-2" onChange={props.handleChange} value={props.quote.Email} type="text" name="Email" /><br />
                                </div>
                            </div>
                            <div className="container-flex" >
                                <div className="flex-basis-5">
                                    <div className="flex-basis-10 pl-3 pt-3">Birthdate<span className="required-asterisk"></span></div>
                                    <input className="input-date ml-3 mr-3 flex-basis-2" id="16Required" max={TodayDate()} onChange={props.handleChange} value={props.quote.Birthdate} style={{ width: "170px" }} type="date" name="Birthdate" />
                                    <span className=" ml-3 flex-start" style={{ fontSize: "12px", color: "#B22727" }}>Must Be 16</span>
                                </div>
                                <div className="flex-basis-5 p-3">
                                    Are you currently insured?<span className="required-asterisk"></span>
                                    <div className=" ">
                                        <input type="button" className={`mt-1 mb-4  mr-3 btnInsured justify-content-center contactBtn ${(props.quote.Insured === "Yes") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="Insured" value={`Yes`} />
                                        <input type="button" className={`mt-1 mb-4  btnInsured justify-content-center contactBtn ${(props.quote.Insured === "No") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="Insured" value={`No`} />
                                    </div>
                                </div>
                            </div>
                            {props.quote.Insured === "Yes" && (<>
                                <div className="container-flex">
                                    <div className="flex-basis-10 p-3"> Current Insurance Company<span className="required-asterisk"></span><br />
                                        <input id="Yes" maxLength="256" className="form-input-sm auto-capitalize" onChange={props.handleChange} value={props.quote.InsuranceName} type="text" name="InsuranceName" />
                                    </div><br />
                                </div>
                            </>
                            )
                            }
                        </div>
                    </div>
                    <br />
                    <div className=" py-4 col-lg-12 justify-content-center">
                        <div style={{ lineHeight: "45px", color: "#222222", fontFamily: "Poppins-Regular", fontSize: "30px", textAlign: "left" }}>
                            Add Up to Three Vehicles
                        </div>
                    </div>
                    <div className="py-4 col-lg-12 justify-content-center">
                        <div style={{ lineHeight: "45px", color: "#222222", fontFamily: "Poppins-Regular", fontSize: "20px", textAlign: "left" }}>
                            Please enter your vehicle(s) information below. If you have more than three vehicles, one of our representatives will contact you shortly.
                        </div>
                    </div>
                    {
                        props.vehicles.map((data, key) => {
                            const editVehicle = (<EditVehicle id={key} Year={data.Year} Make={data.Make} Model={data.Model} updateVehicle={updateVehicle} />);
                            const removeDriver = (<RemoveModal id={key} state={props.vehicles} setState={props.setVehicles} show="Vehicle" />)

                            return ((data.Make !== '' && data.Model !== '' && data.Year !== '' && addVehicles === false) &&
                                <>
                                    <div className="py-4 col-lg-12 justify-content-center" key={key}>
                                        <InputSummary >
                                        <img alt="Vehicle Icon" className="RQ-modal-icon" src={"https://cdn.lonestarmga.com/img/vehicle-card_52x52px.png"} />
                                            <div className="row col-11" style={{ flexBasis: "100%" }}>
                                                <div className=" col-10 ">
                                                    <div className="vehicle-title">{data.Make}  {data.Model}  {data.Year} </div>
                                                    <div className="vehicle-info">Vehicle {key + 1}</div>

                                                </div>
                                                <div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                                                    <div>{editVehicle}</div>
                                                    <div>{removeDriver}</div>
                                                </div>
                                            </div>
                                        </InputSummary>
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                    <div className="py-4 flex-center justify-content-center col-lg-12">
                        {props.vehicles.length <= 2 &&
                            (
                                <AddInput className="add-up-to-section " onClick={openModal} type="button" >
                                    <AddText style={{ width: "auto" }} className="text-center flex-center align-items-center " ><span className="default-round-plus active-round-plus" />Add Vehicles</AddText>
                                </AddInput>
                            )
                        }
                    </div><br />
                    {addVehicles === true && (
                        props.vehicles.map((input, index) => {
                            const editVehicle = (
                                <EditVehicle id={index} Year={input.Year} Make={input.Make}
                                    Model={input.Model} updateVehicle={props.updateVehicle} />)
                            return (index <= 3 && (
                                <VehicleModal idx={index}
                                    editVehicle={editVehicle}
                                    input={input} action={openModal}
                                    setAddVehicles={setAddVehicles}
                                    setConfirm={setConfirm}
                                    stateForm={props.vehicles} setState={props.setVehicles}
                                    handleVehicleChange={props.handleVehicleChange} />
                            ))
                        })
                    )
                    }
                    {/* Drivers */}
                    <div className=" py-4 col-lg-12 justify-content-center">
                        <div style={{ lineHeight: "45px", color: "#222222", fontFamily: "Poppins-Regular", fontSize: "30px", textAlign: "left" }}>
                            Add Up to Three Drivers
                        </div>
                    </div>
                    <div className="py-4 col-lg-12 justify-content-center">
                        <div style={{ lineHeight: "45px", color: "#222222", fontFamily: "Poppins-Regular", fontSize: "20px", textAlign: "left" }}>
                            Please enter your driver(s) information below. If you have more than three drivers, one of our representatives will contact you shortly.
                        </div>
                    </div>
                    {
                        props.drivers.map((data, key) => {
                            const editDriver = (<EditDriver id={key} FirstName={data.FirstName} LastName={data.LastName}
                                Birthdate={data.Birthdate} Accident={data.Accident} updateDriver={updateDriver} />);
                            const remove = (<RemoveModal id={key} state={props.drivers} setState={props.setDrivers} show="Driver" />)
                            return ((data.Accident !== '' && data.FirstName !== '' &&
                                data.LastName !== '' && data.Birthdate !== '' && addDrivers === false) &&
                                <>
                                    <div className="py-4 col-lg-12 justify-content-center" key={key}>
                                        <InputSummary >
                                        <img alt="Driver Icon" className="RQ-modal-icon" src={"https://cdn.lonestarmga.com/img/driver-card_52x52px.png"} />
                                            <div className="row col-11" style={{ flexBasis: "100%" }}>
                                                <div className=" col-10 ">
                                                    <div className="vehicle-title"> {data.FirstName} {data.LastName} </div>
                                                    <div className="vehicle-info"> Driver {key + 1}</div>
                                                </div>

                                                <div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                                                    <div>{editDriver}</div>
                                                    <div>{remove}</div>
                                                </div>
                                            </div>
                                        </InputSummary>
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                    <div className="py-4 flex-center justify-content-center col-lg-12 ">
                        {props.drivers.length <= 2 && (
                            <AddInput className="add-up-to-section " onClick={openDriverModal} type="button" >
                                <AddText style={{ width: "auto" }} className="text-center flex-center align-items-center " ><span className="default-round-plus active-round-plus" />Add Drivers</AddText>
                            </AddInput>
                        )}
                    </div>
                    {addDrivers === true && (
                        props.drivers.map((input, index) => {
                            return (index <= 3 && (
                                <DriverModal
                                    idx={index}
                                    input={input}
                                    setConfirmDriver={setConfirmDriver}
                                    action={openDriverModal}
                                    stateForm={props.drivers}
                                    setState={props.setDrivers}
                                    handleDriverChange={props.handleDriverChange}
                                />
                            ))
                        })
                    )
                    }
                    <div className=" container-flex p-4 align-content-center align-items-center flex-center" >
                        <div className=" offset-lg-5 pt-3 padding-right-5 pb-4">1 of 2 </div>
                        <div onClick={handleSubmit} className="margin-left-3 col-lg-5 black-sm-pill-btn justify-content-center row ">
                            Next
                        </div>
                    </div>
                </div>
            </form>
        </>

    );
}

export default RequestQuoteForm;

const FormCategory = styled.div`
        height: 33px;
        color: #222222;
        font-family: 'Poppins-Bold';
        font-size: 140%;
        letter-spacing: 0;
        line-height: 30px;
        @media only screen and (max-width: 800px){
         padding-top:20px;
    }
        
`;

const AddInput = styled.div`
   box-sizing: border-box;
   height: 93px;
   border: 2px solid #DCDCDC;
   border-radius: 10px;
   background-color: #DCDCDC;
   width:100%;
    &:hover{
       background-color: #FFFFFF;
       box-shadow: 0 10px 20px 0 rgba(0,0,0,0.23);
    }
`;

const AddText = styled.div`
    height: 23px;
    width: 97px;
    color: #565656;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    padding-top:45px;
    
`;

const InputSummary = styled.div`
        box-sizing: border-box;
        min-height: 91px;
        border: 1px solid #DCDCDC;
        border-radius: 10px;
        background-color: #FFFFFF;
        display:flex;
        align-content:center;
        align-items:center;
        padding:20px 0px;
        .RQ-modal-icon{
            margin: 0px 10px 0px 20px;
            height:65px;
            max-width:90px;

            @media only screen and (max-width:800px){
                width:40px;
                height:40px;
            }
        }

        &:hover{
            box-shadow: 0 10px 20px 0 rgba(0,0,0,0.23);

        }
`;

