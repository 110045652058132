
const HoursOfOperation = ({ data }) => {

    return (
        
            data && (
            <>

                <div className="Hours-Card-header ">Hours Of Operation</div>
                <div className="flex-center">{data[0]?.phone_number}</div>
                <div className="Operation-Day-txt pl-3">
                    {
                        data[0]?.monday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3"> Mon </div>
                                <div className="Hours-text col-8">
                                    {data[0]?.monday_hours_text}
                                </div>
                            </div>
                        )
                    }

                    {
                        data[0]?.tuesday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3">
                                    Tue
                                </div>
                                <div className="Hours-text col-8">
                                    {data[0]?.tuesday_hours_text}
                                </div>
                            </div>
                        )
                    }
                    {
                        data[0]?.wednesday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3">
                                    Wed
                                </div>
                                <div className="Hours-text col-8">
                                    {data[0]?.wednesday_hours_text}
                                </div>
                            </div>
                        )
                    }
                    {
                        data[0]?.thursday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3">
                                    Thur
                                </div>
                                <div className="Hours-text col-8">
                                    {data[0]?.thursday_hours_text}
                                </div>
                            </div>
                        )
                    }
                    {
                        data[0]?.friday_hours_text !== "" && (
                            <div className="row pt-1 pb-1">
                                <div className="col-3">
                                    Fri
                                </div>
                                <div className="Hours-text col-8">
                                    {data[0]?.friday_hours_text}
                                </div>
                            </div>
                        )
                    }
              
                    <div className="row pt-1 pb-1">
                        <div className="col-lg-12 Hours-Disclosure">{data[0]?.disclosure_text}</div>
                    </div>
                </div>

            </>
            )

        
       )
}

export default HoursOfOperation 