import { useState, useEffect } from "react";

export const DBMessage = (props) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
  return (
      <div className={`row no-gutters justify-content-center px-4`}>
          <div className={windowSize.innerWidth < 550 ? (`col-lg-9 alert ` + props.style + ` p-3 `) : (`col-lg-9 alert ` + props.style +  ` p-4 `) }>
            <span className="text-notification">
                  <div dangerouslySetInnerHTML={{ __html: props.messageHTML }} />
            </span>
        </div>
    </div>
  )
}
