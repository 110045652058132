import React from "react";
import ContentHeader from "./ContentHeader";
import GeneralConfig from "./GeneralConfig";


const ImageList = ({data}) => {
     let GeneralConfigData = data.configuration;
    let backgroundImg = ""
    
 
    GeneralConfigData.map((items) => {
         backgroundImg= items?.background_image.filename
    })
    return (
        <>
              <div className="gen-config" style={{ background:`url(${backgroundImg}) no-repeat 0% 0px`,backgroundSize:"40%"}}>
        
                 {data.header != "" && (
                  <>
                   <div className="flex ImageList-Content">
                      <div className="col-lg-12">
                        <ContentHeader data={data.header}/>
                      </div>
                  </div>
                 </>
                 )}
                  <div className="col-lg-12 ">
                    <GeneralConfig data={data.configuration} items={data.images}/>
                  </div>
            </div>
      
          </> 
    )
}
 
export default ImageList;