import { storyblokEditable,StoryblokComponent } from "@storyblok/react";

export default function AllNewsSectionSB({blok}) {
  return (
    <div className='grid justify-content-center px-3' {...storyblokEditable(blok)}>
        <div className='px-3 newsletter-page-section' >
            <div style={{fontSize:"40px",fontFamily:"Poppins-Regular"}}>{blok.header}</div>
            <div className='pl-1 py-4'>
            {blok.all_news ? blok.all_news.map((nestedBlok) => (
                <div key={nestedBlok._uid}>
                    <StoryblokComponent blok={nestedBlok} /><br/>
                </div>
                ))
            : null
        }
            </div>
        </div>
    </div>
  )
}
