import React, { useState } from "react";
import styled from "styled-components";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const SubDropdown = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    return ( 
            <>
                <MenuButton onClick={toggleMenu}>
                  <a target={props.route.link_target} href={props.route.path}>{props.route.title}</a>
                  {isMenuOpen ?  <FaAngleUp /> : <FaAngleDown />}
                </MenuButton>
                <SubRoutesContainer isOpen={isMenuOpen}>
                {props.route.children_links.map((subRoute,index) => {
                    let StoryBlockView = subRoute.page_visibility.sort();
                    let CurrentView = props.view.sort();
                    let isMatch = StoryBlockView.some((otherItem) =>  CurrentView.includes(otherItem));
                    return (
                      <>
                          {(isMatch) ? (
                            subRoute?.children_links?.length > 0 ?(
                              <SubDropdown view={CurrentView} route={subRoute} key={index}/>
                            ): (
                              <SubRoute key={index} target={subRoute.link_target} href={subRoute.path}>{subRoute.title}</SubRoute>
                            )
                          ):(<>
                          </>)
                          }
                      </>
                    )
                  })}
                </SubRoutesContainer>
            </> 
          );
}
 
export default SubDropdown;

const SubRoutesContainer = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  padding: 1rem;
`;

const MenuButton = styled.div`
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a{font-size: 1.3rem;
    font-family:"Poppins-Bold";
    color:black;
  }
`;

const SubRoute = styled.a`
  text-decoration: none;
  color: black;
  padding: 0px 0px .2rem 0;
  font-size: 1.2rem;
`;