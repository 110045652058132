import { SpaceRemove } from "../../../Helpers/InputHelper";
export default function validate(info) {
    /*
      string contains at least one uppercase letter and one digit, and is at least 8 characters long.
    */
    const regex = /^(?=.*[A-Z])(?=.*\d).{8,20}$/;

    let errors = [];
    var NOTrequired = document.querySelectorAll("[id='No']");
    NOTrequired.forEach((elm, index) => {
        elm.style.backgroundColor = "white";
        elm.style.border = "1px solid #979797";
    }
    )
    var required = document.querySelectorAll("[id='Yes']");
    required.forEach((elm, index) => {
        if (elm?.value?.length !== 0) {
            elm.style.backgroundColor = "white";
            elm.style.border = "1px solid #979797";
            let text = ``;
            var elements = document.getElementsByClassName("errorON");
            for (var i = 0; i < elements.length; i++) {
                elements[i].innerHTML = text;
            }
        } else if (elm.value?.length === 0 || info?.Year?.length === 0) {
            errors.push(elm)
            elm.style.backgroundColor = "white";
            elm.style.border = "3px solid #B22727";
        }
    }
    )
    var required = document.querySelectorAll(".ResetPasswords");
    required.forEach(elm => {
        if (info.Password === "" ) {

            elm.style.backgroundColor = "white";
            elm.style.border = "3px solid #B22727";
            errors.push(elm);
        } else {

            elm.style.backgroundColor = "";
            elm.style.border = "";
            if (info.Password !== info.Confirm) {
                elm.style.backgroundColor = "white";
                elm.style.border = "3px solid #B22727";
                errors.push(elm);
            } else if (info.Password === info.Confirm) {
                elm.style.backgroundColor = "";
                elm.style.border = "";
            }
            if (!regex.test(info.Password)) {
                elm.style.backgroundColor = "white";
                elm.style.border = "3px solid #B22727";
                errors.push(elm);
            } else {
                elm.style.backgroundColor = "";
                elm.style.border = "";
            }
        }
    })
    var required = document.querySelectorAll(".ConfirmResetPassword");
    required.forEach(elm => {
        if ( info.Confirm === "") {

            elm.style.backgroundColor = "white";
            elm.style.border = "3px solid #B22727";
            errors.push(elm);
        } else {

            elm.style.backgroundColor = "";
            elm.style.border = "";
            if (info.Password !== info.Confirm) {
                elm.style.backgroundColor = "white";
                elm.style.border = "3px solid #B22727";
                errors.push(elm);
            } else if (info.Password === info.Confirm) {
                elm.style.backgroundColor = "";
                elm.style.border = "";
            }
            if ( !regex.test(info.Confirm)) {
                elm.style.backgroundColor = "white";
                elm.style.border = "3px solid #B22727";
                errors.push(elm);
            } else {
                elm.style.backgroundColor = "";
                elm.style.border = "";
            }
        }
    })
    var requiredOptions=  document.querySelectorAll(".YesPassword");
    requiredOptions.forEach(elm => {
        if(info.Password === ""){

            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
            errors.push(elm);
        }else {
            elm.style.backgroundColor = "";
            elm.style.border ="";
            let password = SpaceRemove(info.Password);
            if(!password.match(/^\S+$/)){
                errors.push(elm);
                elm.style.backgroundColor = "white";
                elm.style.border ="3px solid #B22727"
            }else{
                elm.style.backgroundColor = "";
                elm.style.border =""
            }
        }
    })

    var required3 =  document.querySelectorAll(".YesEmail");
    required3.forEach(elm => {
        if(info.Email !== undefined){
            elm.style.backgroundColor = "";
            elm.style.border =""
               
            if(!testEmail(info.Email)){
                errors.push(elm);
                elm.style.backgroundColor = "white";
                elm.style.border ="3px solid #B22727"
            }else{
                elm.style.backgroundColor = "";
                elm.style.border =""
            }

        }else {
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727"
        }}
    )
    return (errors.length)
}

const testEmail = (email) => {
    email = email.replace(/\s+/, "");
    return /^\S+@\S+\.\S+$/.test(email);
};