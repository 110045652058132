import { useStoryblok } from "@storyblok/react";
import { Link } from 'react-router-dom';
import { MDBSpinner } from 'mdb-react-ui-kit';
import MediaObject from '../Components/MediaObject'
import Breadcrumb from "../Components/Core/BreadCrumb";
import CardGrid from '../Components/Cards/CardGrid';
import Banners from '../Components/Core/Banners';
import { LS_PHONENUMBER_FORMAT, LS_PHONENUMBER_UNFORMAT } from "../utils/Global";
import TextSection from "../Components/Core/TextSection";

const items = [
    { to: '/', label: 'Home' },
    { to: '/AboutUs', label: 'About Us' },
]

const AboutUs = () => {
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const story = useStoryblok("lonestar/about-us-content/lonestar-continued-growth", { version: storyblokVersion });
    const StoryCoreFeatures = useStoryblok("lonestar/about-us-content/core-features", { version: storyblokVersion });
    const WhoWeAreStory = useStoryblok("lonestar/about-us-content/who-are-we", { version: storyblokVersion });
    const WeOfferStory = useStoryblok("lonestar/about-us-content/what-we-offer", { version: storyblokVersion });
    const YoutubeStory = useStoryblok("lonestar/about-us-content/about-us-youtube", { version: storyblokVersion });
    const aboutParagraph = useStoryblok("lonestar/about-us-content/about-us-paragraph", { version: storyblokVersion });
    const aboutParagraph2 = useStoryblok("lonestar/about-us-content/about-us-text", { version: storyblokVersion });
    const Partners = useStoryblok("lonestar/about-us-content/partners", { version: storyblokVersion });

    if (!story?.content || !aboutParagraph2?.content || !StoryCoreFeatures?.content || !aboutParagraph?.content ||
        !WhoWeAreStory?.content || !WeOfferStory?.content || !YoutubeStory?.content || !Partners?.content) {
        return <div className="loader-section">
            <MDBSpinner color='danger' className="spinner"></MDBSpinner>
        </div>
    }
    let MediaData = story.content
    let CoreFeaturesData = StoryCoreFeatures.content
    let WhoWeAreData = WhoWeAreStory.content
    let WeOfferData = WeOfferStory.content
    let winBadge = Partners.content.image.filename;

    let SectionImage = "";
    let SectionColor = "";
    WeOfferData.configuration.map((items) => {
        SectionImage = items.background_image.filename;
        SectionColor = items.background_color;
    })
    return (
        <>
            <Banners img={"https://cdn.lonestarmga.com/img/Banners/about-us_banner.png"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/about-us_390x282.png"} page={"About Us"} category={"Lonestar MGA"} />
            <div className='flex-center'>
                <div style={{ minHeight: "400px", maxWidth: "1300px" }}>
                    <div className="mobile-adjust-center crumb-position pb-3 mobile-spacing-left">
                        <Breadcrumb>{
                            items.map(({ to, label }) => (
                                <Link key={to} to={to}>{label}</Link>
                            ))}
                        </Breadcrumb>
                    </div>
                    <div className="flex-center">
                        <div className="">
                            <div className="quotation-background-image">
                                <MediaObject data={MediaData} />
                            </div><br />
                            <div className="px-3">
                                <TextSection SubHeadline={aboutParagraph2.content.subheading} Headline={aboutParagraph2.content.heading} Description={aboutParagraph2.content.text_body} Story={aboutParagraph2.content} />
                            </div>
                        </div>
                    </div>
                </div>
            </div><br />
            <div style={{ minHeight: "400px" }} >
                <div className='bg-color-grad'>
                    <div style={{ height: "450px" }}>
                        <div style={{ minHeight: "750px", background: `url(${SectionImage})center 150% no-repeat`, backgroundAttachment: "fixed", backgroundSize: "280%" }}>
                            <div className='flex-center align-items-center'>
                                <div className='auto-products-sec '>
                                    <CardGrid data={WeOfferData} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="flex-center about-us-text-sec white-smoke-background p-3" style={{ marginTop: "-90px", minHeight: "500px" }}>
                <div className="about-us-body-text">
                    <TextSection SubHeadline={aboutParagraph.content.subheading} Headline={aboutParagraph.content.heading} Description={aboutParagraph.content.text_body} Story={aboutParagraph.content} />
                    <br />
                    <div>
                    <img />
                    </div>
                    <div className="flex-center img-responsive image-fluid">
                        <img src={winBadge} height={300} width={900} alt="WIN partners" />
                        
                    </div>
                    <div className="flex-center" style={{ textAlign: "center", fontSize: "1.2rem", fontFamily: "Poppins-Semi-Bold" }}>
                        Call us or explore our website to learn more.<br />
                    </div><br />
                    <div className="flex-center wrap gap-3 align-items-center pl-2 pr-2 justify-content-center align-content-center"><br />
                        <div className="flex-center call-explore-about-icon"><img src={'https://cdn.lonestarmga.com/img/red-phone_52x46.png'} alt="red phone" /> <a href={'tel:' + LS_PHONENUMBER_UNFORMAT}>{LS_PHONENUMBER_FORMAT}</a></div>
                        <div className="flex-center call-explore-about-icon"><img src={'https://cdn.lonestarmga.com/img/link_52x46.png'} alt="red chain links" /> <a href="/">www.LonestarMGA.com</a></div>
                    </div>
                    <br />
                </div>
            </div>
            <div className='about-us-core-features'>
                <CardGrid data={CoreFeaturesData} />
            </div>
            <div className='about-us-our-team'>
                <MediaObject data={WhoWeAreData} />
            </div>
        </>
    )
}

export default AboutUs
