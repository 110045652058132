import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from 'react-router-dom'
import App from './App';
import { storyblokInit, apiPlugin } from "@storyblok/react";
import ScrollTop from './hooks/ScrollTop';
import CardCTA from './Components/Cards/CardCTA';
import HoursOfOperation from './Components/Cards/HoursOfOperationCard';
import ContactCard from './Components/Cards/ContactCard';
import { AuthProvider } from './utils/AuthProvider';
import DefaultPage from './Components/Storyblok/DefaultPage';
import CardGrid_SB from './Components/Storyblok/CardGrid_SB';
import SB_PlainText from './Components/Storyblok/SB_PlainText';
import SB_TrainingVideoSection from './Components/Storyblok/SB_TrainingVideoSection';
import SB_TrainingVideo from './Components/Storyblok/SB_TrainingVideo';
import SB_ContentHeader from './Components/Storyblok/SB_ContentHeader';
import SB_MediaObject from './Components/Storyblok/SB_MediaObject';
import BasicCard_SB from './Components/Storyblok/Cards/BasicCard_SB';
import SB_CardConfiguration from './Components/Storyblok/SB_CardConfig';
import NewsletterSB from './Components/Storyblok/NewsletterSB';
import TopNewsSectionSB from './Components/Storyblok/TopNewsSectionSB';
import AllNewsSectionSB from './Components/Storyblok/AllNewsSectionSB';
import TopNewsletterSB from './Components/Storyblok/TopNewsletterSB';
import ButtonSectionSB from './Components/Storyblok/ButtonSectionSB';
import ButtonSB from './Components/Storyblok/ButtonSB';
import BlogCard from './Components/Cards/BlogCard';
import MediaObject2sb from './Components/Storyblok/MediaObject2.0sb';
import ColSection from './Components/Storyblok/ColSection';
import SimpleTextSB from './Components/Storyblok/SimpleTextSB';
import ContactCardSB from './Components/Storyblok/Cards/ContactCardSB';
import HeaderIconSB from './Components/Storyblok/HeaderIconSB';
import ListObject from './Components/Storyblok/ListObject';
import OrderItem from './Components/OrderItem';
import UnOrderedItem from './Components/Storyblok/UnorderedItem';
import MediaObjectItem from './Components/Storyblok/MediaObectItem';
const storyblokAPI = process.env.REACT_APP_STORYBLOK_TOKEN;
const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

storyblokInit({
    accessToken:storyblokAPI,
    use:[apiPlugin],
    version: storyblokVersion,
    components:{
      'Card_Grid2.0': CardGrid_SB,
      'Card_Config': SB_CardConfiguration,
      'Call_To_Action_Card':CardCTA,
      'Hours_Of_Operation_Card':HoursOfOperation,
      'Contact_Card':ContactCard,
      'Default-page': DefaultPage,
      'Media Object Item': SB_MediaObject,
      'Plain_Text': SB_PlainText,
      'Training_Video_Section':SB_TrainingVideoSection,
      'Training_Video': SB_TrainingVideo,
      'Basic_Card': BasicCard_SB,
      'Content_Header': SB_ContentHeader,
      'Newsletter': NewsletterSB,
      'Top_Newsletter_Section': TopNewsSectionSB,
      'All_Newsletter_Section':AllNewsSectionSB,
      'Top_Newsletter': TopNewsletterSB,
      'Button_Section':ButtonSectionSB,
      'Button':ButtonSB,
      'Blog_Card': BlogCard,
      'Media_Object2.0': MediaObject2sb,
      'col-section':ColSection,
      'Simple-Text':SimpleTextSB,
      'Contact_Card':ContactCardSB,
      'Header_Icon': HeaderIconSB,
      'List_Object_2.0':ListObject,
      'Unordered_Item':UnOrderedItem,
      'Ordered_Item': OrderItem,
      'Media Object Item':MediaObjectItem
    }, 
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <ScrollTop />
                <App />
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);

