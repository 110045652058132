import ButtonGroup from "./Buttons/ButtonGroup"
import '../Assets/Style/Components.scss'

const LoginObject = (props) => {
    return (
        <>
            <div className="row justify-content-center">
                <img className="login-obj-img" src={props.data.image.filename} alt="Man Working at Desk" />
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <ButtonGroup data={props.data.button_group} />
                </div>
            </div>
        </>
    )
}

export default LoginObject