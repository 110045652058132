import { useState, useEffect,useReducer} from 'react';
import $ from "jquery";
import validateInfo from '../Validation/FormValidation';
import { limit } from '../../../Helpers/InputHelper';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { formReducer, responseReducer, FORM_INITIAL_STATE,CALL_INITIAL_STATE } from './FormReducer';

const CaPaymentForm = () => {
    const [FormState, FormDispatch] = useReducer(formReducer, FORM_INITIAL_STATE);
    const [CallResponseState, CallResponseDispatch] = useReducer(responseReducer, CALL_INITIAL_STATE);
    
    const [FormErrors, setFormErrors] = useState(1);
    const [IsSubmitting,setIsSubmitting] = useState(false);
    const [Attempt, setAttempt] = useState(false);
    const [Load, setLoad] = useState(false);

    useEffect(() => {
        FormDispatch({ type: "" });
        CallResponseDispatch({ type: "" });
    }, [FormState,CallResponseState]);

    useEffect(() => {
        if (FormErrors === "Ready" && IsSubmitting ) {
            $.ajax({
                type: "POST",
                url: "/FCICPayment/InitializeFCICPayment",
                data: JSON.stringify(FormState, function (k, v) { return v === undefined ? "" : v; }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response) {
                    setLoad(false);
                    if (response.found === "False" || response.url == "NO_REDIRECT") {
                        CallResponseDispatch({ type: 'SUCCESS', payload: response.paymentmessage})
                    } else {
                        window.open(`${response.url}`, '_blank');
                    }
                },
                failure: function (response) {
                    alert(response.d);
                }
            });
            setFormErrors(1);
            setIsSubmitting(false);
            setAttempt(false);
        }
    }, [FormErrors, IsSubmitting]);
     useEffect(() => {
        if(Attempt === true){ 
            setFormErrors(validateInfo(FormState));
            if(FormErrors > 1){
             setIsSubmitting(false);
            }else if (FormErrors <= 0){
                setFormErrors("Ready");
                setLoad(true);
            }
         }
     })
    const handleChange = (e) => {
        e.preventDefault();
        FormDispatch({
            type: "CHANGE_INPUT",
            payload: { name: e.target.name, value: e.target.value }
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setAttempt(true);
        setIsSubmitting(true); 
    };
    return (
        <form className="form-small-short" autoComplete="off">
         <div className="form-section-small">
            <div className="row p-3">
                  <h4 className='flex-center flex-basis-10 form-header'>Payment Options</h4>
            </div>
            <div className="row">
               <div className="col-lg-12">
                   <div className="required-box">
                        <div className="required-txt required-asterisk ">Required</div>
                   </div>
               </div>
            </div><br/>
            <div>
                <div className="row">
                        <div className="col-lg-3">Policy Prefix
                            <input disabled className="disabled-input pl-2" value="CAL" name="PolicyPrefix" />
                        {/*<select className="form-input" id="YesSelect" onChange={handleChange} name="PolicyPrefix"> */}
                        {/*    <option key={"0"} selected disabled>Select</option>         */}
                        {/*    <option key={"1"} value="CAL">CAL</option>*/}
                        {/*</select>*/}
                    </div>
                    <div className="col-lg-4">Policy Number<span className="required-asterisk"></span>
                           <input maxLength="25" className="form-input auto-capitalize pl-2" id="Yes"  type="text" name="PolicyNumber" onChange={handleChange} />
                    </div>
                        <div className="col-lg-5">Payment Amount<br />
                            <div className="currency-input">
                                <input id="No" className="form-input pl-3" name="PaymentAmount"
                                    type="number"
                                    onKeyUp={(e) => limit(e, 25)} onKeyDown={(e) => limit(e, 24)} 
                                    onChange={handleChange} />
                            </div>
                        </div>
                </div>
                <div className='container-flex p-4 align-content-center align-items-center flex-end'>
                    <div onClick={handleSubmit} className="margin-left-3 col-lg-5 black-sm-pill-btn justify-content-center row">
                            {Load ? (<MDBSpinner color='secondary' className="spinner-sm"></MDBSpinner>) : ("Continue")}
                    </div>
                    </div>
                <div>
                        NOTE: You will be directed to a secure site under VeriSign.
                </div>
                {
                    CallResponseState.NoRedirectMessage && (
                        <div className="col-lg-12 error-payment-form">{CallResponseState.ResponseMessage}</div>
                    )
                }
            </div>
        </div>
    </form>
  )
}
export default CaPaymentForm