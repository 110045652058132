import RichText from "./RichText";


const TextSection = (props) => {

    return ( 
        <>
            <div className={`col-flex justify-content-center m-auto ${props.Story?.header_color}`}>
                <div className="flex-basis-10">
                {props.SubHeadline && <span className="sub-headline">{props.SubHeadline}</span>}
                {props.HeadLine !== "" && <div className="heading">{props.Headline}</div>}
                </div>
                <RichText Description={props.Description} />
                {props.ButtonText && <a href={props.LinkURL} target={props.LinkTarget} className={props.ButtonStyle} style={{paddingTop:"30px"}} >{props.ButtonText}</a> }
            </div> 
        </>
     );
}
 
export default TextSection;