import CardConfiguration from "./CardConfig";
import ContentHeader from "../ContentHeader";

const CardGrid = (props) => {

   //Default
    let HeaderData = props?.data?.header;
    let Cards = props?.data?.cards;
    let CardConfig = props?.data?.configuration;
  
    let SectionImage = ""
    let SectionColor = ""
    return (
         <div style={{backgroundColor:SectionColor, background:`url(${SectionImage}) center top no-repeat`, backgroundSize:"100%,50%"}}> 
            {props?.data?.header.length > 0 && (
                    <div className="flex-center flex-wrap">
                        <div className="flex-basis-10 justify-content-center px-1">
                                <ContentHeader data={HeaderData}/>
                        </div>
                    </div>
                )}
                <CardConfiguration data={Cards} config = {CardConfig}/>   
          </div>
    )
}
 
export default CardGrid
