import React,{useState} from "react";
import SummaryPage from "./SummaryPage";
import SuccessRequest from "../SuccessPage";
import FileClaimForm from "./FileAClaim";

const MasterFileClaimForm = () => {
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [Step, setStep] = useState(0);
  const [ClaimData, setClaimData] = useState ({
        FilerFirstName:'',
        FilerLastName:'',
        FilerCompany:'',
        FilerPhoneNumber:'',
        FilerEmailAddress:'',
        PolicyNumber:'',
        PolicyHolderCompany: 'null',
        PolicyHolderFirstName:'',
        PolicyHolderLastName:'',
        PolicyHolderAddress:'',
        PolicyHolderCity:'',
        PolicyHolderState:'',
        PolicyHolderZIPCode:'',
        PolicyHolderMethodContact:'null',
        PolicyHolderHomePhone:'',
        PolicyHolderCellPhone:'',
        PolicyHolderBusinessPhone:'',
        PolicyHolderEmailAddress:'',
        DriverFirstName:'',
        DriverLastName:'',
        DriverAddress:'',
        DriverCity:'',
        DriverState:'',
        DriverZIPCode:'',
        DriverMethodContact:'null',
        DriverHomePhone:'',
        DriverCellPhone:'',
        DriverBusinessPhone:'',
        DriverEmailAddress:'',
        OtherDriverFirstName:'',
        OtherDriverLastName:'',
        OtherDriverAddress:'',
        OtherDriverCity:'',
        OtherDriverState:'',
        OtherDriverZIPCode:'',
        OtherDriverHomePhone:'',
        OtherDriverCellPhone:'',
        OtherDriverBusinessPhone:'',
        InsuredVehicleYear:'',
        InsuredVehicleMake:'',
        InsuredVehicleModel:'',
        InsuredVehicleMsg:'',
        OtherVehicleYear:'',
        OtherVehicleMake:'',
        OtherVehicleModel:'',
        OtherVehicleMsg:'',
        OtherVehicleDrivable:'',
        OtherVehicleCompanyName:'',
        OtherVehiclePolicyNumber:'',
        DateOfAccident:'',
        TimeOfAccident:'',
        LocationOfAccident:'',
        AccidentMsg:'',
        VehicleDrivable:'',
        VehicleStolen:'',
        TheftWasReported:'',
        PoliceDepartment:'',
        PoliceReportNumber:'',
        Injuries:'null',
        SMSconsent:'null'
  })
  //console.log(ClaimData)
function submitForm() {
    return setIsSubmitted(!IsSubmitted);
    }
    function nextStep () {
        return (setStep(Step + 1));
    };

    function prevStep () {
        return (setStep(Step - 1));
    };

    let handleChange = (e) => {
        const { name, value } = e.target;
        setClaimData({
          ...ClaimData,
          [name]: value
        });
      };
    switch(Step){
        case 1: return (<SummaryPage claim={ClaimData} nextStep={nextStep} prevStep={prevStep}  submitForm={submitForm}/>)
        case 2: return (<SuccessRequest title="Your Claim Was Successfully Sent" pos="mt-5" />)
        default: return (<FileClaimForm 
                            handleChange={handleChange}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            submitForm={submitForm}
                            claim={ClaimData}
                            setClaim={setClaimData}
                            IsSubmitted={IsSubmitted}
                        />)
    }
}
export default MasterFileClaimForm;