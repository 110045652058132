import Banners from "../../Components/Core/Banners";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Breadcrumb from "../../Components/Core/BreadCrumb";
import MasterRequestLoginForm from "../../Components/Forms/RequestLogin/MasterRequestLogin";
const items = [
    { to: '/', label: 'Home' },
    { to: '/ProducerLogin', label: 'Producer Login' },
    { to: '/ForgotPassword', label: 'Request New Login' }
]

const RequestLogin = () => {
    return ( 
        <>
            <Banners img={"https://cdn.lonestarmga.com/img/Banners/change-password_banner.svg"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/change-password_banner.svg"} page={"Producer Login"} category={"Request New Login"} />
            <div className='flex-center wrap white-smoke-background'>
                <div className="pb-4 pt-3 login-breadcrumb" style={{maxWidth:"1074px"}}>
                    <Breadcrumb>{
                        items.map(({to, label}) =>(           
                                    <Link key={to} to={to}>{label}</Link>
                        ))}
                    </Breadcrumb>
                </div>  
            </div>
            <div className="grid justify-content-center white-smoke-background" style={{paddingBottom:"5%"} }>
                <div className="flex-center">
                   <PageTitle>
                        Fill Out Form Below
                   </PageTitle>
                </div>
                <PageDescription className="flex-center">
                        <div className="flex-wrap">
                            Fill the form below and one of our representatives will reach back to you on communicating with you about creating a new login as soon as possible.
                        </div>
                </PageDescription><br/>
                <FormSection>
                    <MasterRequestLoginForm />
                </FormSection>
            </div>
        </> 
    );
}
 
export default RequestLogin;

const FormSection = styled.div`
    max-width:1074px;
    @media only screen and (max-width:800px){
        padding:0% 1% 5% 1% ;
    }
`;
const PageTitle = styled.div`
    line-height:52px;
    color:#222222;
    font-family:"Poppins-Regular";
    font-size:1.9rem;
    text-align:center;
    @media only screen and (max-width:800px){
        font-size:1.1rem;
        padding:0% 10%;
        line-height:32px;
        font-family:"Poppins-semi-bold";
    }

`;
const PageDescription = styled.div`
    padding:1% 0% 0px 0%;
    max-width:1074px;
    flex-wrap:wrap;
    @media only screen and (max-width:800px){
        padding:0% 1%;
    }
`;