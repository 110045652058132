import {useState,useEffect} from 'react'
import RichText from './Core/RichText'

export default function DependentMediaObject(props) {
   
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);
      function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
  return (
    <>
        <div className='flex-center wrap align-items-center px-4 '>
            <div className='flex-basis-5 justify-content-center align-items-center align-items-center' style={{height:"auto"}}>
                {props.data.image_position === "left" ? (
                    <div className='flex justify-content-center media-obj-content'>
                        {props.data.image_background.filename  && ( <img alt={"Media Object Overlay"} src={props.data.image_background.filename}/>  ) }             
                        {props.data.image.filename && (<img alt="Media Object Main" src={props.data.image.filename} /> )}
                    </div>
                ):(<>
                    <div className={windowSize.innerWidth < 650 ? 'flex-basis-10 px-2' :'flex-basis-5 align-items-start'} >
                        <div className={windowSize.innerWidth < 650 ? ' text-align-center' :'flex-basis-5 align-items-start'} >
                            <h5>{props.data.subheadline}</h5>
                            <h5>{props.data.headline}</h5>
                        </div>
                        <div className="pt-3">
                            <RichText Description={props.data.description} />
                        </div>
                        <div className='pb-4 rich-card-button'>
                            <a href={props.data.link_url} target={props.data.link_target} className={props.data.button_style}>{props.data.button_text}</a>
                        </div>
                    </div>
                    <div className="media-obj-content">
                      {props.data.image_background.filename  && ( <img alt={"Media Object Overlay"} src={props.data.image_background.filename}/>  ) }             
                      {props.data.image.filename && (<img alt="Media Object Main" src={props.data.image.filename} /> )}
                    </div>
                    </>
                )}
            </div>
            <div className={windowSize.innerWidth < 650 ? 'flex-basis-10 px-2 ' :'flex-basis-5 align-items-start'} >
                 <div className={windowSize.innerWidth < 650 ? ' text-align-center' :'flex-basis-5 align-items-start'} >
                <h5>{props.data.subheadline}</h5>
                <h5>{props.data.headline}</h5>
                </div>
                <div className="pt-3">
                    <RichText Description={props.data.description} />
                </div>
                <div className='pb-4 rich-card-button'>
                    <a href={props.data.link_url} target={props.data.link_target} className={props.data.button_style}>{props.data.button_text}</a>
                </div>
            </div>
        </div>
    
    </>
  )
}
