import {useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faSortDown } from '@fortawesome/free-solid-svg-icons'
import "../../Navbar.scss"
import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";
const MenuItems = ({ items, depthLevel}) => {

  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 1000 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 1000 && setDropdown(false);
  };
 //Where it will effect the navigation 
  return (
    (items.page_visibility.filter((results) => results === "member" || results === "only_visitors").length > 0) && (  
      <>
         <li  ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
          {items.title && depthLevel === 0   ? (
         
             <>
                <div className="menu-items">
                {items.link_target === "_self" ? (
                    <>
                      <Link className="top-nav-route" style={{lineHeight:"100px"}} to={items.path} >{items.title}</Link>
                        {items.children_links.length > 0 &&(
                        <>
                          {<span><FontAwesomeIcon className= "arrow" icon={faSortDown} /></span>}
                          <Dropdown depthLevel={depthLevel}  submenus={items.children_links} dropdown={dropdown} />
                        </>
                        )}
                  
                    </>
                    ):(
                    <>
                      <a className="top-nav-route" style={{lineHeight:"100px"}} target={items.link_target} href={items.path} >{items.title}</a> 
                        {items.children_links.length > 0 &&(
                        <>
                          {<span><FontAwesomeIcon className= "arrow" icon={faSortDown} /></span>}
                          <Dropdown depthLevel={depthLevel}  submenus={items.children_links} dropdown={dropdown} />
                        </>
                        )}
                   
                    </>
                  )}
               </div>
              <br/>
           </>     
         
            //Second Time it loops through 
          ) : items.title[1] &&           
          (
              <>
                  <div className="sub-row" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"} onClick={() => setDropdown((prev) => !prev)}> 
                  <>
                        {items.link_target === "_self" ? (
                      
                          <Link className="flex-start test" to={items.path}>
                              {items.title}
                                  {items.children_links.length > 0 && (
                              <FontAwesomeIcon className="subArrow" icon={faCaretRight} />
                            )}
                            </Link>
                          ):(
                              <a className="flex-start test" href={items.path} target={items.link_target}>{items.title}
                               {items.children_links.length > 0 && (
                              <FontAwesomeIcon className="subArrow" icon={faCaretRight} />
                            )}</a>
                            )
                        }{items.children_links.length > 0 &&(
                                  <>
                                    <Dropdown depthLevel={depthLevel}  submenus={items.children_links} dropdown={dropdown} />
                                  </>
                                  )
                        }
                   
                      </>
                  </div>
            </>
          )
      }
    </li>
        </>

      )
    
    
  );
};

export default MenuItems;