export default function validateInfo(props) {

    let errors = [];
    var required =  document.querySelectorAll("[id='YesCar']");
    required.forEach((elm,index) => {
        if(elm.value !== ""){
            elm.style.backgroundColor = "white";
            elm.style.border =""
        }else if (elm.value === "" || props.info.Year === ""){
            errors.push(elm)
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
            
        }
    })


    var requiredDriver=  document.querySelectorAll("[id='YesDriverAccident']");
    requiredDriver.forEach(elm => {
       props.map((items) => {
        if(items.Accident === 'null'){
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
            errors.push(elm);
        }else if(items.Accident !== 'null' ){
            elm.style.backgroundColor = "";
            elm.style.border ="";
        }
       })
      
    })
    
    var requiredDriver=  document.querySelectorAll("[id='YesDriverAccidentEDIT']");
    requiredDriver.forEach(elm => {
        if(props.Accident === 'null'){
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
            errors.push(elm);
        }else if(props.Accident !== 'null' ){
            elm.style.backgroundColor = "";
            elm.style.border ="";
        }
    })
    var required2 =  document.querySelectorAll("[id='YesDriver']");
    required2.forEach((elm,index) => {
        if(elm.value !== ""){
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797"
        }else if (elm.value === "" || props.info.Year === ""){
            errors.push(elm)
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
            
        }
    }
    )

    var required3 =  document.querySelectorAll(".needed");
    required3.forEach(elm => {
        if(elm.value !== "" || elm.value=== "null"){
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797"
        }else {
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727"
            errors.push(elm)
        }}
    )
    return errors;
}