import DateFormatter from './DateFormatter';
import { FormattedCurrentDate } from '../../Helpers/InputHelper';

export default function GroupAnnouncements({data}) {

    let currentDate = FormattedCurrentDate();
    
    let Calendar = [];
    let January = [{title:"January"}];
    let February = [{title:"February"}];
    let March = [{title:"March"}];
    let April = [{title:"April"}];
    let May = [{title:"May"}];
    let June = [{title:"June"}];
    let July = [{title:"July"}];
    let August = [{title:"August"}];
    let September = [{title:"September"}];
    let October = [{title:"October"}];
    let November = [{title:"November"}];
    let December = [{title:"December"}];
    var DisplayTitle = false;
    
    data.map((announcement) => {
        
        switch(announcement.month){
            case 'January': return January.push(announcement);
            case 'February': return February.push(announcement);
            case 'March': return March.push(announcement);
            case 'April':return April.push(announcement);
            case 'May': return May.push(announcement);
            case 'June': return June.push(announcement);
            case 'July': return July.push(announcement);
            case 'August': return August.push(announcement);
            case 'September': return September.push(announcement);
            case 'October': return October.push(announcement);
            case 'November': return November.push(announcement);
            case 'December': return December.push(announcement);
            default: return announcement;
        }
    })
    function Display(arr){
       if(Object.keys(arr).length > 1) {
        return true
       }else{
        return false
        }
    }

    function parseDateTime(data){
        const values = data.split(' ');
        return values;
    }
    function boundCheck(start,end,current){
        if((current >= start) && (current <= end) ){
            return true
        }else{
            return false
        }
    }
    Display(January) && (
      Calendar.push(January)
    )
    Display(February) &&(
      Calendar.push(February)
    )
    Display(March) &&(
      Calendar.push(March)
    )
    Display(April) && (
      Calendar.push(April)
     )
    Display(May) &&(
      Calendar.push(May)
    )
    Display(June) &&(
      Calendar.push(June)
    )
    Display(July) && (
      Calendar.push(July)
    )
    Display(August) &&(
      Calendar.push(August)
    )
    Display(September) &&(
      Calendar.push(September)
    )
    Display(October) && (
      Calendar.push(October)
    )
    Display(November) &&(
      Calendar.push(November)
    )
    Display(December) &&(
      Calendar.push(December)
    )

  return (
  <>
    {Calendar.map((item) => {  
             return( 
    
                <> 
                {    
                    item.map((data) => {
                      if(!('title' in data) ){
                        let startDate = parseDateTime(data.announcement.content.start_date);
                         let endDate = parseDateTime(data.announcement.content.end_date);
                        return (  
                            (boundCheck(startDate, endDate,currentDate)) &&(
                              DisplayTitle =  true
                            )
                        )
                    }})
                }
                {
                    DisplayTitle && (        
                      <div className="announcement-month pb-2">
                            {item[0].title}
                      </div>
                      )
                }
                {
                    item.map((data) => {
                       if(!('title' in data) ){
                        let startDate = parseDateTime(data.announcement.content.start_date);
                         let endDate = parseDateTime(data.announcement.content.end_date);
                        return (  
                            (boundCheck(startDate, endDate,currentDate)) &&

                            <>
                           
                            <div className={` row announcement-body`} >
                                    <img src={'https://cdn.lonestarmga.com/img/alert-circle-redbell_64x64.svg'} alt="Red Bell Icon" className='announcement-icon mr-2'></img>
                                                <div >
                                                    <div className='announcement-title'>{data?.announcement?.content.alert_title}</div> 
                                                    <div className='announcement-date'><DateFormatter startDate={data?.announcement?.content.start_date} /></div>
                                                    <div className='announcement-text'>{data?.announcement?.content.alert_text}</div>
                                                </div>
                            </div> <br/>
                        </>)
                        }
                        })
                }
            </>
        )
    })}
  </>
  )
}
