import Banners from "../Components/Core/Banners";
import Breadcrumb from "../Components/Core/BreadCrumb";
import FAQ from "../Components/FAQ";
import { Link } from "react-router-dom";

const items = [
    { to: '/', label: 'Home' },
    { to: '/Customers', label: 'Customers' },
    { to: '/CustomerFAQ', label: 'Customer FAQ'}
]

const CustomerFAQ= () => {
    return ( 
        <>
            <Banners img={"https://cdn.lonestarmga.com/img/ClaimsFAQbanner.png"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/faq-banner_390x282.png"} page={"Customer FAQ"} category={"Lonestar MGA"} />
                <div style={{minHeight:"600px", backgroundColor:"#F0F0F0",width:"100%",justifyContent:"center",alignItems:"center",alignContent:"center"}}> 
                    <div className="mobile-adjust-center crumb-position pb-3 mobile-spacing-left">
                            <Breadcrumb>{
                                        items.map(({to, label}) =>(           
                                            <Link key={to} to={to}>{label}</Link>
                                        ))}
                             </Breadcrumb>
                    </div>
                    <div className="faq-customer-section pt-3 pb-4">
                       <div className="lg-w">
                             <FAQ page={"Customer"} />
                        </div>
                    </div>
                </div>
        </>
     )
}
 
export default CustomerFAQ