import SbCardConfiguration from "./SB_CardConfig";
import ContentHeader from "../ContentHeader";
import { StoryblokComponent,storyblokEditable} from "@storyblok/react";
import SbContentHeader from "./SB_ContentHeader";
import { useEffect,useState } from "react";

const CardGrid_SB = ({blok}) => {
    const [CardStyle, setCardStyle] = useState("");
    const [Layout, setLayout] = useState("");
    const [GridColumns, setGridColumns] = useState("");
    const [ImageBackground, setImageBackground] = useState("");
    const [BackgroundColor, setBackgroundColor] = useState("");
    const [DefaultCardColor, setDefaultCardColor] = useState("");
    const [ContainerStyle, setContainerStyle] = useState("");
    const [windowSize, setWindowSize] = useState(getWindowSize());


    let SectionImage = ""
    let SectionColor = ""
    useEffect(() => {
        blok?.Configuration && blok.Configuration.map((item) => {
            setCardStyle(item.card_style);
            setLayout(item.layout);
            setGridColumns(item.grid_columns);
            setImageBackground(item.background_image.filename);
            setBackgroundColor(item.background_color);
            setDefaultCardColor(item.set_default_card_color);    
            setContainerStyle(item.card_container_style);
            })
    },[]);

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    const spaceBetweenItems = 25; // edit to increase/decrease space between items

    const containerStyle = {
        display: "flex",
        flexWrap: "wrap",
        margin: `-${spaceBetweenItems * 0.5}px`,
        justifyContent: "center" 
      };
    const containerStyleBlock = {
        display: "block",
        flexWrap: "wrap",
        margin: `-${spaceBetweenItems * 0.5}px`,
        justifyContent: "center" 
    };
      const itemStyle = {
        display: "block",
        flex: "none",
        width: `${90 / GridColumns}%`,
        boxSizing: "border-box",
        padding: `${spaceBetweenItems * 0.5}px`,
        justifyContent: "center",
        alignItem:"center",
        alignContent:"center"
      };
      const verticalStyle = {
        display: "block",
        flex: "none",
        width: `${100 / 1}%`,
        boxSizing: "border-box",
        padding: `${spaceBetweenItems * 0.5}px`
      }
      function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
    return ( 
        <>
        <div {...storyblokEditable(blok)} className={`${BackgroundColor}`} >
                <div style={{backgroundColor:SectionColor, background:`url(${SectionImage}) center top no-repeat`, backgroundSize:"100%,50%"}}> 
                    <div className="">
                    {
                        blok?.header && (
                            <div className="flex-center">
                               <SbContentHeader blok={blok.header[0]} />
                            </div>
                        )
                    }
                    <div>
                            {
                                 Layout === "grid" && GridColumns > 1 ? (
                                    <div style={windowSize.innerWidth < 550 ?(containerStyleBlock) : (containerStyle)}>
                                        <StoryblokComponent blok={blok.Cards} />
                                    </div>
                                ): Layout === "horizontal" ?(
                                    ContainerStyle === "continuous" ? (
                                    <>
                                        {DefaultCardColor === "white-card" && (
                                            setDefaultCardColor("cont-horiz-white-card")
                                        )}
                                         <div className={`flex-center gx-5 flex-wrap space ${DefaultCardColor}`} style={{justifyContent:"center"}}>
                                                <StoryblokComponent blok={blok.Cards} />
                                         </div>
                                    </>
                                    ):(
                                        <>
                                            <div className="flex-center wrap" style={{background:`${ImageBackground} no-repeat`}}>       
                                            {
                                                blok.Cards && blok.Cards.map((nestedBlok,index) => (
                                                     
                                                    <div key={nestedBlok._uid}>
                                                        <StoryblokComponent blok={nestedBlok} style={CardStyle}/>
                                                    </div>
                                                    
                                                ))
                                                }
                                            </div>
                                        </>
                                    )
                                ): Layout === "vertical" && (
                                    <>
                                        <div style={containerStyle}>
                                            <StoryblokComponent blok={blok.Cards} />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    
                </div>
            </div>
        </div>
        </>
    )
}
 
export default CardGrid_SB
