import Breadcrumb from "../Components/Core/BreadCrumb";
import styled from "styled-components";
import MasterRequestForm from "../Components/Forms/RequestQuote/MasterRequest";
import Banners from "../Components/Core/Banners";
import { Link } from "react-router-dom";
import { QAOnly } from "../Components/Storyblok/QAOnly";
const items = [
    { to: '/', label: 'Home' }, { to: '/PersonalAuto', label: 'Personal Auto Insurance' }, { to: '/PersonalAuto', label: 'Request Auto Quote Form' }
]
const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

const RequestAQuote = () => {

    return (<>
        <Banners img={"https://cdn.lonestarmga.com/img/Banners/request-a-quote-banner_1920x357.png"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/request-a-quote-banner_390x282.svg"} page={"Request Auto Quote"} category={"Personal Auto Insurance"} />
        <FormSection >
            <div className="flex align-content-center align-items-center py-4 justify-content-center">
                <div className="crumbs-rq" >
                    <Breadcrumb>{
                        items.map(({ to, label }) => (
                            <Link key={to} to={to}>{label}</Link>
                        ))}
                    </Breadcrumb>
                </div>
            </div>
            {storyblokVersion === "draft" ?
                    <QAOnly/>
                : <>
                    <div className="flex-center">
                        <div className="RQ-header" >
                            Start Your Personal Auto Quote Request Below
                        </div>
                    </div>
                    <div className="row RQ-title-box justify-content-center">
                        <div className="white-blue-border-box">
                            <div className="text">
                                Your information will be directed to your local Lonestar MGA agent who will provide you with a fast quote.
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="pb-5 flex-basis-10 " style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "1%" }}>
                <div style={{ width: "1107px" }}>
                    <MasterRequestForm />
                </div>
            </div>
        </FormSection>


    </>);
}

export default RequestAQuote;
const FormSection = styled.div`
    min-height:600px;
    background-color:#F0F0F0;
  
`;
