import React, { useEffect, useState } from "react"
import OrderItem from "../OrderItem";
import UnOrderedItem from "../UnOrderedItem";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";


const ListObject = ({blok}) => {
   
    const [windowSize, setWindowSize] = useState(getWindowSize());

    let IconPostition = blok?.configuration[0].icon_position;
    let Dividers = blok?.configuration[0].dividers;
    let Columns = blok?.configuration[0].number_of_columns;
    let OrderStyle = blok?.configuration[0].order_style;
    
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
   

    const lastIndex = blok.length - 1
    const spaceBetweenItems = 25; // edit to increase/decrease space between items

    const containerStyle = {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    };

    const itemStyle = {
        display: "block",
        flex: "none",
        width: `${100 / Columns}%`,
        boxSizing: "border-box",
    };

    const verticalStyle = {
        display: "block",
        flex: "none",
        width: `90%`,
        boxSizing: "border-box",
        padding: `${spaceBetweenItems * 0.5}px`,
        justifyContent: "center",
        minHeight: "120px",

        paddingBottom: "2%",

    }
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    return (<>
        <div style={containerStyle} {...storyblokEditable(blok)} >{
            blok?.list_item.map((nestedBlok, index) => {
                return (<>
                    <div key={index} style={windowSize.innerWidth < 1100 ? verticalStyle : itemStyle}>
                       <div key={index} className={blok.length - 1 !== index ? `UI-body align-items-center justify-content-center align-content-center m-1` : `UI-body-last`}>
                            <StoryblokComponent blok={nestedBlok} />
                        </div>
                    </div>
                </>
                )
            })
        }
        </div>
    </>
    )
}
export default ListObject