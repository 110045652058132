import { useEffect, useState} from "react"


const SB_CardConfiguration = ({blok}) => {
  
    return (
        <> 
            Card Config

         </> 
    )

}

export default SB_CardConfiguration


