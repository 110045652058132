import { Navigate, useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { createContext,useContext } from "react";
const DataContext = createContext(null);

export default function AdminAuth({ children }) {
    
    const location = useLocation();
    
    let { firstname, lastname, email } = useParams();
 
    let obj = localStorage.getItem("DATA");
    if (!obj.includes("ADMIN") || JSON.parse(localStorage.getItem("DATA")).rtnval?.requirePasswordChange === true) {
        if (firstname !== null && lastname !== null && email !== null) {
            localStorage.setItem('_RNL', JSON.stringify({ fn: firstname, ln: lastname, e: email }));
            return <Navigate to='/ProducerLogin' state={{ path: location.pathname }} />
        }
    }
    return children
}

export const useEmailData = () => {
    return useContext( DataContext )
}