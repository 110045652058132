/// <reference path="../../../app.js" />
/// <reference path="../../../utils/requiredauth.js" />
import { useState, useRef, useEffect } from "react";
import $ from "jquery";
import { useAuth } from "../../../utils/AuthProvider";
import {Link} from "react-router-dom";
import validate from "../Validation/LoginValidate";
import PasswordResetModal from './Modals/PasswordResetModal';

const ChangePasswordForm = () => {
    const [show, setShow] = useState(false);
    const PasswordRef = useRef("");
    const [passwords, setPasswords] = useState({
        Password: "",
        confirmPassword: "",
    });
    const auth = useAuth(); 
    const [formErrors, setFormErrors] = useState(1);
    const [Attempt, setAttempt] = useState(false);
    const [Submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (Submitting && formErrors === "Ready") {
            setShow(true);
            $.ajax({
                type: "POST",
                url: "/AuthenticateUser/RequiredPasswordChange",
                data: JSON.stringify({
                    UserId: `${JSON.parse(localStorage.getItem("DATA"))?.rtnval?.id}`,
                    NewPassword: passwords.Password,
                }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response) {
                    auth.ChangedPassword();
                }
            });
        }
    },[formErrors, Submitting]);

    useEffect(() => {
        if (Attempt === true) {

            setFormErrors(validate({
                Password:passwords.Password,
                Confirm:passwords.confirmPassword
            }))
            if (formErrors > 1) {
                setSubmitting(false);
            } else if (formErrors <= 0) {
                setFormErrors("Ready");
                setAttempt(false);
            }
        }
    })
    const handleInputChange = (event) => {
        setPasswords({ ...passwords, [event.target.name]: event.target.value });
    };
    const handleShowPassword = (e) => {
        const passwordInput = document.querySelector("#Password")
        const eye = document.querySelector("#eye")
        eye.classList.toggle("fa-eye-slash")
        const type = passwordInput.getAttribute("type") === "password" ? "text" : "password"
        passwordInput.setAttribute("type", type)
    }
    const handleShowConfirmPassword = (e) => {
        const passwordInput = document.querySelector("#confirmPassword")
        const eye = document.querySelector("#eye-2")
        eye.classList.toggle("fa-eye-slash")
        const type = passwordInput.getAttribute("type") === "password" ? "text" : "password"
        passwordInput.setAttribute("type", type)
    }
    const updatePassword = (e) => {
        e.preventDefault();
        setAttempt(true);
        setSubmitting(true);
    }
    
    return ( 
        <>
            <div className="md-form-body">
                    <div className="flex-center ">
                        <div className="header-login">Change Password</div>
                </div><br />
                <form autoComplete="off">
                    <div className="mb-2">New Password</div>
                    <div className="password-container ">
                        <input type="password" id="Password" maxLength="256" name="Password" ref={PasswordRef} value={passwords.Password} onChange={handleInputChange} className="form-input ResetPasswords"/>
                            <i className="fa-solid fa-eye " onClick={(e) => handleShowPassword(e)} id="eye"></i>
                        </div>
                    <br />
                    <div className="mb-2">Confirm New Password</div>
                        <div className="password-container">
                                <input type="password" id="confirmPassword" name="confirmPassword" value={passwords.confirmPassword} onChange={handleInputChange} className="form-input ConfirmResetPassword"/>
                                <i className="fa-solid fa-eye" onClick={(e) => handleShowConfirmPassword(e)} id="eye-2"></i>
                        </div>
                        <br/>
                        <div style={{marginTop:"2%", backgroundColor: "#F0F0F0", borderRadius: "10px", padding:"2% 10%" }}>
                            <div style={{ fontSize: "1rem", color: "#B22727", fontFamily: "Poppins-Semi-Bold",paddingBottom:"2.5%" }}>Password Must:</div>
                                <div style={{ paddingLeft: "5%", color: "#333333", fontFamily: "Poppins-Regular" }}>
                            <div className="blue-bullet"><span><img src={'https://cdn.lonestarmga.com/img/blue-bullet_11x11.svg'} alt="Blue Bullet point" style={{ paddingRight: "3px" }} /></span> Be between 8 and 20 characters</div>
                            <div className="blue-bullet"><span><img src={'https://cdn.lonestarmga.com/img/blue-bullet_11x11.svg'} alt="Blue Bullet point" style={{paddingRight:"3px"}} /></span> Include at least one number</div>
                            <div className="blue-bullet"><span><img src={'https://cdn.lonestarmga.com/img/blue-bullet_11x11.svg'} alt="Blue Bullet point" style={{ paddingRight: "3px" }} /></span> Include at least one upper and lower case letter</div>
                            </div>
                        </div>
                        <br/>
                        <div className="flex-center">
                            <button className=" primary-lg-pill-btn" type="button" onClick={updatePassword }>Update Password</button>
                        </div>
                        <div className="flex-center pt-3">
                            <Link to='/ProducerLogin'>Cancel</Link>
                        </div>
                    </form> 
            </div>
            {
                show && (<PasswordResetModal setShow={setShow} show={show} />)
            }
            </> 
    );
}
export default ChangePasswordForm;