import {useState,useEffect} from 'react'
import RichText from '../Core/RichText';

export default function MediaObjectSB({blok}) {
  
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);
      function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

  return (
    <>
   
            <div className='flex-center wrap align-items-center align-items-center px-4 ' >
               <div className="flex-center wrap" style={{maxWidth:"1300px"}}>
                {blok?.Image_Position === "left" ? (
                    <>
                        <div className={windowSize.innerWidth < 650 ? 'flex-basis-10 px-2 ' :'flex-basis-1 align-items-start'}>
                            {blok?.Image?.filename && (<img alt="Media Object Main" style={{height:"100%", width:"100%"}} src={blok.Image.filename} /> )}
                        </div>
                        <div className={windowSize.innerWidth < 650 ? 'flex-basis-10 px-2 ' :'flex-basis-5 align-items-start'} >
                            <div className={windowSize.innerWidth < 650 ? ' text-align-left' :'flex-basis-5 align-items-start'} >
                                  {blok?.Sub_Heading !== "" && <h5>{blok?.Sub_Heading}</h5>}
                                  {blok?.Heading !== "" && <h5>{blok?.Heading}</h5>}
                            </div>
                            <div className="pt-3">
                                <RichText Description={blok?.Description} />
                            </div>
                        </div>
                    </>
                ):(<>
                    <div className={windowSize.innerWidth < 850 ? 'flex-basis-10 px-2 wrap pt-3' :'flex-basis-5 align-items-start'} >
                        <div className={windowSize.innerWidth < 850 ? ' text-align-left' :'flex-basis-5 align-items-start'} >
                            {blok?.Sub_Heading !== "" && <h5>{blok?.Sub_Heading}</h5>}
                            {blok?.Heading !== "" && <h5>{blok?.Heading}</h5>}
                        </div>
                              <div className={windowSize.innerWidth < 850 ? '' : 'pt-3'}>
                            <RichText Description={blok?.Description} />
                        </div>
                    </div>
                    <div className={`${windowSize.innerWidth < 850 ? 'flex-basis-10 wrap px-2' :'flex-basis-5 '}`} >
                      <div className='flex-center'>
                        {blok?.Image?.filename && (<img alt="Media Object Main" style={{width:"80%"}}src={blok.Image.filename} /> )}
                      </div>
                    </div>
                    </>
                )}
                </div>
            </div>
    </>
  )
}
