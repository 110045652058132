import { useState, useEffect } from "react";
import RichText from "../Core/RichText"
import { StoryblokComponent, storyblokEditable, useStoryblok } from "@storyblok/react";
import { MDBSpinner } from 'mdb-react-ui-kit';
import { LinkParse } from '../../Helpers/InputHelper';


export default function SB_TrainingVideoSection({ blok }) {
    const [DisplayVideo, setDisplayVideo] = useState("");
    const [SpanishDisplay, setSpanishDisplay] = useState("");
    const [Playing, setPlaying] = useState(false);
    const [SpanishPlay, setSpanishPlay] = useState(false);
    const [PageInquiry, setPageInquiry] = useState("0");
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
    const story = useStoryblok("lonestar/producer/online-training-spanish", { version: storyblokVersion });

    const handleClick = (e, Video) => {
        e.preventDefault();

        setDisplayVideo(Video);
        if (Video === Playing) {
            // If the same button is clicked again, reset the selection
            setPlaying(null);
        } else {
            // Otherwise, select the new button
            setPlaying(Video);
        }

    }
    const handleSpanishClick = (e, Video) => {
        e.preventDefault();

        setSpanishDisplay(Video);
        if (Video === SpanishPlay) {
            // If the same button is clicked again, reset the selection
            setSpanishPlay(null);
        } else {
            // Otherwise, select the new button
            setSpanishPlay(Video);
        }

    }
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    if (!story?.content) {
        return <div className="loader-section">
            <MDBSpinner color='danger' className="spinner"></MDBSpinner>
        </div>
    }
    const handleInquiryButtons = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setPageInquiry(value);
    }
 //console.log(PageInquiry)
    return (
        <div className={`flex-center flex-basis-10 ${blok.Color} wrap px-4`} {...storyblokEditable(blok)} >
            <div className='training-video-container py-4 '>
                <RichText Description={blok.Sub_Heading} />
                <RichText Description={blok.Heading} />
                <div className=" training-video-section">
                    <div className="flex-start pb-3 gap-1">
                        <button className={PageInquiry === "0" ? " col-lg-3 blue-large-option-button" : "blue-large-option-button active-option-button col-lg-3"} onClick={(e) => handleInquiryButtons(e)} value="0">English</button>
                        <button className={PageInquiry === "1" ? "col-lg-3 blue-large-option-button" : "blue-large-option-button active-option-button col-lg-3"} onClick={(e) => handleInquiryButtons(e)} value="1">Spanish</button>
                    </div><br />
                    <div className="flex-center wrap">
                        {
                            PageInquiry === "1" ? 
                                <>
                                    <div className="flex-basis-5-full flex-start">
                                        {
                                            story.content.body[0].Video && story.content.body[0].Video.map((nestedBlok) => (
                                                nestedBlok._uid === SpanishDisplay &&
                                                <div key={nestedBlok._uid}>
                                                    <StoryblokComponent blok={nestedBlok} />
                                                </div>

                                            ))
                                        }
                                        {
                                            SpanishDisplay === ""  &&
                                            <div key={story.content.body[0].Video[0]._uid}>
                                                    <div className="container-training-video">
                                                        <a href={`https://www.youtube.com/embed/${LinkParse(story.content.body[0].Video[0].Video_Link.url)}`} style={windowSize.innerWidth < 800 ? { backgroundImage: `url(${story.content.body[0].Video[0].Thumbnail.filename})`, maxWidth: "300px", height: "200px", backgroundPosition: "center", backgroundSize: "contain" } : { backgroundImage: `url(${story.content.body[0].Video[0].Thumbnail.filename})`, width: "350px", height: "200px", backgroundPosition: "center", backgroundSize: "contain" }} target="_blank" className="align-content-center align-items-center corner-wrapper">
                                                        </a><br />
                                                        <h5>{story.content.body[0].Video[0].Title}</h5>
                                                        <RichText Description={story.content.body[0].Video[0].Description} />
                                                    </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex-basis-5-full">
                                        {story.content.body[0].Video_Log ? story.content.body[0].Video_Log.map((nestedBlok) => (
                                            <div key={nestedBlok._uid}>
                                                <StoryblokComponent blok={nestedBlok} />
                                            </div>
                                        ))
                                            : null
                                        }
                                        {story.content.body[0].Video ? story.content.body[0].Video.map((nestedBlok) => (
                                            <div className={`${SpanishPlay === nestedBlok._uid ? "active-video-body" : "training-video-body"} mb-3 list-container-training-video` }  key={nestedBlok._uid}>
                                                <button onClick={(e) => handleSpanishClick(e, nestedBlok._uid)} key={nestedBlok._uid}>
                                                    <div className="video-log-title">{nestedBlok.Title}</div>
                                                    <div className="flex align-items-center">
                                                        {nestedBlok?.Date && <div className="video-log-date">{nestedBlok?.Date}</div>}
                                                        {nestedBlok?.Video_Length && <span className="date-span">|</span>}
                                                        {nestedBlok?.Video_Length && <div className="video-log-date">{nestedBlok?.Video_Length}</div>}
                                                    </div>
                                                </button>
                                            </div>
                                        ))
                                            : null
                                        }
                                    </div>
                                </>
                                : PageInquiry === "0" &&
                            <>
                        <div className="flex-basis-5-full flex-start">
                            {
                                blok.Video && blok.Video.map((nestedBlok) => (
                                    nestedBlok._uid === DisplayVideo &&
                                    <div key={nestedBlok._uid}>
                                        <StoryblokComponent blok={nestedBlok} />
                                    </div>

                                ))
                            }
                            {
                                DisplayVideo === "" &&
                                <div key={blok.Video[0]._uid}>
                                    <StoryblokComponent blok={blok.Video[0]} />
                                </div>
                            }
                        </div>
                        <div className="flex-basis-5-full">
                            {blok.Video_Log ? blok.Video_Log.map((nestedBlok) => (
                                <div key={nestedBlok._uid}>
                                    <StoryblokComponent blok={nestedBlok} />
                                </div>
                            ))
                                : null
                            }
                            {blok.Video ? blok.Video.map((nestedBlok) => (
                                <div className={`${Playing === nestedBlok._uid ? "active-video-body" : "training-video-body"} mb-3`} key={nestedBlok._uid}>
                                    <button id="play-video" onClick={(e) => handleClick(e, nestedBlok._uid)} key={nestedBlok._uid}>
                                        <div className="video-log-title">{nestedBlok.Title}</div>
                                        <div className="flex align-items-center">
                                            {nestedBlok?.Date && <div className="video-log-date">{nestedBlok?.Date}</div>}
                                            {nestedBlok?.Video_Length && <span className="date-span">|</span>}
                                            {nestedBlok?.Video_Length && <div className="video-log-date">{nestedBlok?.Video_Length}</div>}
                                        </div>
                                    </button>
                                </div>
                            ))
                                : null
                            }
                            </div>
                        </> }
                    </div>
                </div>
            </div>
        </div>
    )
}