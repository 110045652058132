import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const DefaultPage = ({blok}) => {
 
    return ( 
        <div {...storyblokEditable(blok)}>
             {blok.body ? blok.body.map((nestedBlok) => (
                    <div key={nestedBlok._uid}>
                        <StoryblokComponent blok={nestedBlok} />
                    </div>
                    ))
                : null
             }
        </div>
    );
}
 
export default DefaultPage;