import React, { useRef, useState,useEffect } from "react"
import styled from "styled-components";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RichText from "./Core/RichText";
import Buttons from "./Buttons/Buttons";

const ContentObject2Bottom = ({data}) => {
    let Description = data.description;
    const [active, setActive] = useState(false);
    const content = useRef(null);
    const [height, setHeight] = useState("0px");
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    function toggleAccordion() {
        setActive(!active);
        setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
    }
    return ( 
        <>       
                {data.button_style === "expand-btn" ?(
                  <>
                      <div className="FAQbody">
                        <div className={`accordion ${active ? "active" : ""}`} onClick={toggleAccordion}>
                            <p className="FAQquestion" style={{paddingLeft:"5%",paddingRight:"5%"}}>{data.headline}</p><span>{active ? (<><FontAwesomeIcon icon={faChevronUp} /></>):(<><FontAwesomeIcon icon={faChevronDown} /></>)}</span>
                        </div>
                        <div ref={content} style={{ maxHeight: `${height}`, padding:"0 7% 0% 7%"}} className="accordion__content" >
                        <div className="FAQanswer "/>
                              <RichText Description ={Description}/>
                              <br/>
                        </div>
                        <Buttons data={data.buttons} />

                      </div>
                  </>
                ):(
                  <>
                        <div className="pt-4 px-4-full flex-center" style={{width:"100%",height:"100%"}}>
                          <div className="h-dark" style={{width:"70%",textAlign:"center"}}>
                          <div className="sub-headline">{data.subheadline}</div>
                            <div className="heading">{data.headline}</div>
                            
                            <DescriptionMO><RichText Description={Description} /></DescriptionMO><br/>
                          </div>
                         
                        </div>
                        <div className={windowSize.innerWidth < 800 ? ("grid justify-content-center") : ("flex-center")}>
                          <Buttons data={data.buttons} />
                          </div>
                  </>
                )}

        </> 
)
}
 
export default ContentObject2Bottom;


const DescriptionMO = styled.div`
padding-top:15px;
  p{font-size:100%;
    text-align: left;}
  font-family:"Poppins-Regular";
`;
