import { storyblokEditable } from "@storyblok/react";
import RichText from "../Core/RichText";

export default function NewsletterSB({blok}) {

    return (
        <div {...storyblokEditable(blok)}>
            <div className={`regular-newsletter-body gap-1 ${blok.background_color} flex`}>
                <div className="flex-basis-4-full ">
                    <img className="newsletter-image" src={blok.image?.filename}/>
                </div>
                <div className="flex-basis-6-full">
                    <div className="flex gap-2 newsletter-topic pb-2"><span>{blok.topic}</span><span>{blok.date}</span></div>
                    <div className="mobile-newsletter-title"><RichText Description={blok.title} /></div>
                    <div><RichText Description={blok.description} /></div>
                    <div className="py-2">
                        <a href={blok.link_url} target="_blank" className={`${blok.Link_Url_Style}`}>{blok.link_text}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
