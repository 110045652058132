import {useState, useEffect, useRef } from "react";
import { FaBars } from "react-icons/fa";
import MenuItems from "./MenuItems";
import { Link } from "react-router-dom";

const Navigation = (props) => {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();

    useEffect(() => {
      const handler = (event) => {
        if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
      };
      document.addEventListener("mousedown", handler);
      document.addEventListener("touchstart", handler);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("touchstart", handler);
      };
    }, [dropdown]);
  
    const onMouseEnter = () => {
      window.innerWidth < 1200 && setDropdown(true);
    };
  
    const onMouseLeave = () => {
      window.innerWidth > 1200 && setDropdown(false);
    }; 
 
   return ( 
            <nav className="nav-container">
              <div className={`navigation-bar ${props.isOpen ? 'position-none' : 'position-relative'}`} >
                  <div className="nav-section">
                        <div className="flex-basis-5 align-items-start justify-content-start align-content-start nav-company-logo">
                       <Link to="/"><img className="nav-company-logo" alt="Lonestar MGA Company Logo" src={"https://cdn.lonestarmga.com/img/lonestarLogo.svg"}/></Link>
                        </div>
                        <div className="flex-basis-5 flex-end">
                          <button className="nav-drawer-button" onClick={props.toggleDrawer}><FaBars /></button>
                        </div>
                  </div>
                  <div className="nav-routes-section " ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
                        {props.routes.map((route) => {
                           return route.children_links.length !== 0 ? (
                                <MenuItems items={route} depthLevel={0} />
                           ):(
                              route.link_target === "_self" ? (
                                       <Link className={((route.title === "Customer Login") || (route.title === "Auto Customer Login")) ? ("enhanced") : ("menu-items")} to={route.path}>{route.title}</Link>
                              ): (
                                           <a className={((route.title === "Customer Login") || (route.title === "Auto Customer Login")) ? ("enhanced") : ("menu-items")} target={route.link_target} href={route.path}>{route.title}</a>
                              )
                           )
                        })}
                  </div>
              </div>
            </nav>
    );
}
 
export default Navigation;