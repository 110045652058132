import React from 'react'
import { FaFortAwesome } from 'react-icons/fa'
import { FaChevronRight } from 'react-icons/fa'
import '../../Assets/Style/breadcrumb.scss'

const BreadcrumbItem = ({ children, ...props }) => (

    <li className='breadcrumb-item crumbtxt' {...props}>{children}</li>
  )

  const BreadcrumbSeparator = ({ children, ...props }) => (
    <li className='breadcrumb-separator' {...props}>
      {children}
      <div >
      <FaChevronRight />
      </div>
    </li>
  ) 

  const BreadcrumbActive = ({ children, ...props }) => (
    <li className='last' {...props}>{children}</li>
  )

const Breadcrumb = (props) => {
  let children = React.Children.toArray(props.children)

  children = children.map((child, index) => (
    <BreadcrumbItem key={`breadcrumb_item${index}`}>{child}</BreadcrumbItem>
  ))

  const lastIndex = children.length - 1

  children = children.reduce((acc, child, index) => {
    
    const notLast = index < lastIndex
    if (notLast) {
        acc.push(
           <li key={index} className="crumbs">{child}</li>,
            <BreadcrumbSeparator key={`breadcrumb_sep${index}`}>    
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
                 
            </BreadcrumbSeparator>,
          )
        } else {
        // eslint-disable-next-line no-lone-blocks
        {acc.push(<BreadcrumbActive key={index} className="last">{child}</BreadcrumbActive>)}
    }
    return acc
  }, [])

  return <div>{children}</div>
}
export default Breadcrumb