import ImageItem from "./ImageItem";

const GeneralConfig = (props) => {

    let Layout = "";
    // let Layout = data.layout;
    props.data.map((item) => {
        Layout = item.layout
    })

    return ( <>{
            Layout === "horizontal" ? (
                <div  className="pb-1 row" style={{justifyContent: "center"}}>
                    {
                        props.items.map((pic,index) => {

                                return(
                                    <div className="general-config">
                                        <ImageItem key={index} data={pic}/>
                                    </div>
                                )
                        })
                    }
                </div>
            ): Layout === "vertical" && (
                <>
                  <div  className="row" style={{justifyContent: "flex-start"}}>
                    {
                        props.items.map((pic,index) => {
                                return(
                                    <div className="py-2 ">
                                        <ImageItem key={index} data={pic}/>
                                    </div>
                                )
                     
                        })
                    }
                    </div>
                </>
            )}
                 
    </> )
}
 
export default GeneralConfig 