
import { useRef, useState, useEffect } from "react";
import { useAuth } from "../../../utils/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import validate from "../Validation/LoginValidate";

const LoginForm = (props) => {
    const [formErrors, setFormErrors] = useState(1);
    const [Email, setEmail] = useState({ Email: props.params });
    const PasswordRef = useRef("");
    const [Password, setPassword] = useState({
        password: "",
        showPassword: true
    });
    const [Submitting, setSubmitting] = useState(false);
    const [Attempt, setAttempt] = useState(false);
    const EmailRef = useRef(null);
    const auth = useAuth();

    useEffect(() => {
        if (Submitting && formErrors === "Ready") {
            $.ajax({
                type: "POST",
                url: "/AuthenticateUser/GetAuthentication",
                data: JSON.stringify({
                    Email: Email.Email,
                    Password: Password.password,
                    Application: "LONESTAR"
                }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response) {
                    auth.login(response);
                }
            });
            setAttempt(false);
        }
    }, [Submitting, formErrors])

    useEffect(() => {
        if (Attempt === true) {
            setFormErrors(validate({
                Email: Email.Email,
                Password: Password.password
            }))
            if (formErrors > 1) {
                setSubmitting(false);
            } else if (formErrors <= 0) {
                setFormErrors("Ready");
            }
        }
    })
    const handleShowPassword = (e) => {
        const passwordInput = document.querySelector("#password")
        const eye = document.querySelector("#eye")
        eye.classList.toggle("fa-eye-slash")
        const type = passwordInput.getAttribute("type") === "password" ? "text" : "password"
        passwordInput.setAttribute("type", type)
    }
    const handlePasswordChange = (prop) => (e) => {
        e.preventDefault();
        
        const trimmedPassword = e.target.value.trim();
        //console.log(trimmedPassword);
        setPassword({ ...Password, [prop]: trimmedPassword });
    }
    const handleChange = (e) => {
        e.preventDefault();
        const trimmedEmail = e.target.value.trim();
        //console.log(trimmedEmail);
        setEmail({ ...Email, [e.target.name]: trimmedEmail });
    }
    const handleLogin = (e) => {
        e.preventDefault();
        setAttempt(true);
        setSubmitting(true);
    }

    return (
        <>
            <form>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css"></link>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <div className="sm-form-body" >
                    <div className="header-login">Producer Login </div>
                    <div style={{ fontSize: "15px", padding: "10px 0px 0px 0px" }}>
                        <span>Instructions for first time login&nbsp;<a href={"https://cdn.lonestarmga.com/pdf/LS-first-time-login FINAL.pdf"} target="_blank" style={{ color: "#B22727", fontFamily: "Poppins-Semi-Bold", fontSize: "15px" }}>click here</a> or contact your agency administrator.</span>
                    </div>
                    <br />
                    <div className="grid gap-2">
                        <div>Email<br />
                            <input required type="text" name="Email" maxLength="256" ref={EmailRef} onChange={handleChange} value={Email.Email} className="form-input YesEmail " autoComplete="off" style={{ borderRadius: "10px" }} />
                        </div>
                        <div>Password<br />
                            <div className="password-container mt-2">
                                <input className="form-input YesPassword" onChange={handlePasswordChange("password")} maxLength="256" value={Password.password} ref={PasswordRef} autoComplete="off"
                                    type="password" id="password" />
                                <i className="fa-solid fa-eye" onClick={(e) => handleShowPassword(e)} id="eye"></i>
                            </div>
                        </div>
                    </div><br/>
                    <div className="row py-1 justify-content-center">
                        <button className=" primary-sm-pill-btn" onClick={handleLogin}>Producer Login</button>
                    </div>
                    <Link className="pt-3 pb-3 simple-link" to="/ForgotPassword">Forgot Password</Link>
                    <Link className=" simple-link" to="/RequestLogin">Request New Login</Link>
                    <br />
                </div>
            </form>
        </>
    );
}

export default LoginForm

