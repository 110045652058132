const ContactCard = ({data}) => {
    
    return ( 
            <>
            
            <div className="">
                <div className="col-lg-12 col-md-12 col-sm-12">
                <div style={{paddingBottom:"10px",fontFamily:"Poppins-Semi-Bold", fontSize:"20px"}}>{data.icon_image?.filename && (<img style={{width:"40px", marginRight:"10px" }}alt="Icon" src={data.icon_image?.filename}/>)} {data.title_text}</div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="CT-txt">{data.address_line_1} <br/> 
                   {data.address_line_2}
                   <div>{data.address_line_3}</div>
                </div>
            </div>
            
            </> 
        );
}
 
export default ContactCard;