import { useState, useEffect} from "react";
import { Link, useParams } from "react-router-dom"
import Banners from "../../Components/Core/Banners"
import Breadcrumb from "../../Components/Core/BreadCrumb"
import validate from "../../Components/Forms/Validation/FormValidation";
import ProducerAddModal from "../../Components/Forms/LoginUsers/Modals/ProducerAddModal";
import $, { error } from "jquery";
import CheckProducerForm from "../../Components/Forms/CheckProducer/CheckProducerForm";

const items = [
   { to: '/ProducerPortal', label: 'Home' },
   { to: '/AddProducer', label: 'Add Producer' }
]
export default function AddProducer() {
    const [formErrors, setFormErrors] = useState(1);
    const [Submitting, setSubmitting] = useState(false);
    const [Attempt, setAttempt] = useState(false);
    const [show, setShow] = useState("-1");

    let { firstname, lastname, email } = useParams();
    const [PreFormData, setPreFormData] = useState({
        FirstName: firstname,
        LastName: lastname,
        ExternalId:null,
        Email: email,
        Password: "",
        Roles: "PRODUCER",
        Application: "LONESTAR"
    });
    const [WriteType, setWriteType] = useState("null");
    useEffect(() => {
        if (Submitting && formErrors === "Ready") {
            $.ajax({
                type: "POST",
                url: "/AuthenticateUser/AddProducer",
                data: JSON.stringify({
                    FirstName: PreFormData.FirstName,
                    LastName: PreFormData.LastName,
                    ExternalId: PreFormData.ExternalId,
                    Email: PreFormData.Email,
                    Password: PreFormData.Password,
                    Roles: "PRODUCER",
                    Application: "LONESTAR"
                }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response) {
                    (response === "-1" || response === -1) ?
                        (
                            setShow("ERROR")
                   ): (
                            setShow("SUCCESS")
                   )
                },
                failure: function (response) {
                    console.log(response);
                }
            });
            setAttempt(false);
        }
    }, [Submitting, formErrors])
    useEffect(() => {
        if (Attempt === true) {
            setFormErrors(validate({
                FirstName: PreFormData.FirstName,
                LastName: PreFormData.LastName,
                ExternalId: PreFormData.ExternalId,
                Email: PreFormData.Email,
                Password: PreFormData.Password,
                WriteType: WriteType
            }))
            if (formErrors > 1) {
                setSubmitting(false);
            } else if (formErrors <= 0) {
                setFormErrors("Ready");
            }
        }
    })
    const handleInputChange = (e) => {
        setPreFormData({ ...PreFormData, [e.target.name]: e.target.value });
    };
    const handleWriteType = (e) => {
        setWriteType(e.target.value);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setAttempt(true);
        setSubmitting(true);
    }
    return (
         <>
            <Banners img={"https://cdn.lonestarmga.com/img/Banners/view-edit-agents_minibanner.svg"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/view-edit-agents_minibanner.svg"} page={"Admin Portal"} category={"Add Producer"}/>
            <div className='flex-center wrap white-smoke-background'>
                <div className="pb-4 pt-3 login-breadcrumb position-breadcrumb">
                            <Breadcrumb>{
                                items.map(({to, label}) =>(           
                                    <Link key={to} to={to}>{label}</Link>
                                ))}
                            </Breadcrumb>
                        </div>  
            </div>
            <div className="flex-center" style={{ paddingBottom: "5%", backgroundColor: "#F0F0F0" }}>
                {firstname === "" || lastname === "" || email === "" ? (
                    <></>
                ) : (
                        <>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                <CheckProducerForm />
                                <div className="grid xs-form-body">
                                    <div className="flex-basis-10 mt-2"> <Link className="white-xs-pill-btn" to="/ProducerPortal">Go Back</Link> </div>
                                        <div className="pt-3 flex-center">
                                        <div className="flex-center add-producer-icon"><img className="flex-center" alt="red circle icon" src={"https://cdn.lonestarmga.com/img/account_84x84.svg"} /></div>
                                        </div>
                                        <div>
                                            <label className="header-login flex-center">Add Producer</label>
                                        </div>
                                        <div className="my-3 required-box">
                                            <div className="required-txt required-asterisk">Required</div>
                                        </div>
                                        <div className="pb-2" >First Name<span className="required-asterisk"></span> <br />
                                            <input id="Yes" type="text" onChange={handleInputChange} name="FirstName" maxLength="256" value={PreFormData.FirstName} className="form-input pt-4 " autoComplete="off" style={{ textTransform: "none", padding: "22.5px 14px", height: "1.4375em" }} />
                                        </div><br />
                                        <div className="pb-2" >Last Name<span className="required-asterisk"></span> <br />
                                            <input id="Yes" type="text" onChange={handleInputChange} name="LastName" maxLength="256" value={PreFormData.LastName} className="form-input pt-4 " autoComplete="off" style={{ textTransform: "none", padding: "22.5px 14px", height: "1.4375em" }} />
                                        </div><br />
                                        <div className="pb-2" >Email Address<span className="required-asterisk"></span> <br />
                                            <input required type="text" value={PreFormData.Email} onChange={handleInputChange} name="Email" maxLength="256" className="email-login-form-input pt-4 YesEmail " autoComplete="off" style={{ textTransform: "none", padding: "22.5px 14px", height: "1.4375em" }} />
                                        </div><br />
                                        <div className="pb-2">Is this producer writing commercial auto?<span className="required-asterisk"></span><br />
                                            <div className="flex-center gap-2" >
                                                <input name="WriteType" type="button" id="yes" value="Yes" onClick={handleWriteType} className={`mt-2 form-input flex-basis-5-full btnYes2 ${(WriteType === "Yes") ? 'onBtn' : 'offBtn'}`} />
                                                <input name="WriteType" type="button" id="no" value="No" onClick={handleWriteType} className={`mt-2 form-input flex-basis-5-full btnYes2 ${(WriteType === "No") ? 'onBtn' : 'offBtn'}`} />
                                            </div>
                                        </div> <br />
                                        {
                                            WriteType === "Yes" && (
                                            <>
                                                <div className="pb-2">Commercial Auto ID<span className="required-asterisk"></span><br />
                                                    <input required id="YesWriteType" type="text" onChange={handleInputChange} name="ExternalId" maxLength="10" className="form-input pt-4" autoComplete="off" style={{ textTransform: "none", padding: "22.5px 14px", height: "1.4375em" }} />
                                                </div><br/>
                                            </>
                                            )
                                        }
                                        <div className="pb-4" >Temp Password<span className="required-asterisk"></span> <br />
                                            <input id="Yes" type="text" onChange={handleInputChange} name="Password" maxLength="150" className="email-login-form-input pt-4 " autoComplete="off" style={{ textTransform: "none", padding: "22.5px 14px", height: "1.4375em" }} />
                                        </div><br />
                                        <div className="flex-center">
                                            <button className="primary-lg-pill-btn" onClick={handleSubmit}>Add Producer Information</button>
                                        </div><br />
                                        <div className="flex-center"><Link to="/ProducerPortal">Cancel</Link></div>
                                        {
                                          show === "ERROR" && (<><br/><span className="error-text">Producer was not added. Commercial Auto ID is a duplicate. </span></>)
                                        }
                                </div>
                            </div>

                     </>
                )}    
            </div>
            {
                show === "SUCCESS" && (<ProducerAddModal setShow={setShow} show={show} />)
            }
         </>
        )
}
