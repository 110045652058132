import React from "react";
import ContentHeader from "./ContentHeader";
import ListConfiguration from "./ListConfiguration";

const ListObject= (props) => {    
    return (
        <>
                {props.data.header !== "" && (
                    <div className="content-header-list"  style={{justifyContent:"start"}}>
                        <ContentHeader data={props.data.header} headingPosition={props.headingPosition}/>
                    </div>
                )}
                  <ListConfiguration data={props.data.configuration} list={props.data.list_item}/>
                             
        </>
    )
}
 
export default ListObject