import React from 'react'
import RichText from '../Core/RichText'

export default function HeaderIconSB({ blok }) {
    return (
        <div className="flex pb-4 align-items-center align-content-center wrap">
            <span><img className="mr-3" style={{ maxWidth: "55px", maxHeight:"55px"}} src={blok.icon.filename} /></span><RichText Description={blok.header } /><br/>
        </div>
  )
}
