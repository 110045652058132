import {useState} from "react";
import RequestQuoteForm from "./RequestAQuote";
import SuccessRequest from "../SuccessPage";
import SummaryPage from "./summaryPage";
const MasterRequestForm = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [Step, setStep] = useState(0);

    const [Quote, setQuote] = useState ({
            FirstName:'',
            LastName:'',
            Address:'',
            City:'',
            State:'null',
            Zip:'',
            Birthdate:'',
            Email:'',
            Phone:'',
            ContactType:'null',
            Insured:'null',
            InsuranceName:''
    })
    const [Vehicles, setVehicles] = useState([{Year:'',Make:'',Model:''}]);
    const [Drivers, setDrivers] = useState([{FirstName:'', LastName:'', Birthdate:'',Accident:'null'}]);

    function submitForm() {
    return    setIsSubmitted(true);
      
    }
    function nextStep () {
        return (setStep(Step+1));
    };
    function prevStep () {
        return (setStep(Step-1));
    };
    let handleChange = (e) => {
        
        const { name, value } = e.target;
        setQuote({
          ...Quote,
          [name]: value
        });
      };
    let handleVehicleChange = (i,e) => {
        e.preventDefault();
        let newVehicles = [...Vehicles];
        newVehicles[i][e.target.name] = e.target.value;
        setVehicles(newVehicles);
                e.target.style.backgroundColor = "white";
                e.target.style.border ="3px solid #004C78";
      
    }
 
    
    let handleDriverChange = (i,e) => {
        e.preventDefault();
        let newDrivers = [...Drivers];
        newDrivers[i][e.target.name] = e.target.value;
        setDrivers(newDrivers);
    }
    switch(Step){
        case 1: return (<SummaryPage quote={Quote}  drivers={Drivers} vehicles={Vehicles} nextStep={nextStep} prevStep={prevStep} submitForm={submitForm}/>)
        case 2: return (<SuccessRequest title="Your Auto Quote Request Was Successfully Sent" pos="" nextStep={nextStep} prevStep={prevStep} submitForm={submitForm} /> )
        default: return (<RequestQuoteForm 
                            handleChange={handleChange}
                            handleVehicleChange={handleVehicleChange}
                            handleDriverChange={handleDriverChange}
                            nextStep={nextStep} 
                            quote={Quote} 
                            setQuote={setQuote}
                            vehicles={Vehicles}
                            drivers={Drivers}
                            setDrivers={setDrivers}
                            setVehicles={setVehicles}
                            prevStep={prevStep} 
                            submitForm={submitForm}/> )
        
        };

}
 
export default MasterRequestForm 