
export default function validateInfo(info) {

    let errors = [];
    var  NOTrequired =  document.querySelectorAll("[id='No']");
    NOTrequired.forEach((elm,index) => {
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797";
    }
    )
    var required =  document.querySelectorAll("[id='Yes']");
    required.forEach((elm,index) => {
        if(elm?.value?.length !== 0){
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797";
            let text = ``;
            var elements = document.getElementsByClassName("errorON");
            for(var i = 0; i < elements.length; i++) {
                // names += elements[i].name;
                elements[i].innerHTML = text; 
            }
        }else if (elm.value?.length === 0 || info?.Year?.length === 0 ){
            errors.push(elm)
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";        
        }
    }
    )
    var requiredSelect =  document.querySelectorAll("[id='YesSelect']");
    
    requiredSelect.forEach((elm, index) => {
        if (info.State === 'null' || info.Subject === 'null' || info.PolicyPrefix == 'null' || info.PolicyHolderMethodContact === 'null' || info.PolicyHolderMethodContact === '- Select -' || info.PolicyPrefix == 'null') {
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
        }else if(info.State !== 'null' || info.Subject !== 'null' ){
            elm.style.backgroundColor = "";
            elm.style.border ="1px solid #979797";
        }
    }
    )

    var requiredSMSNo = document.querySelectorAll("[id='NOsms']");
        requiredSMSNo.forEach((elm, index) => {
            if (info.SMSconsent === 'null') {
                errors.push(elm);
                elm.style.backgroundColor = "white";
                elm.style.border ="3px solid #B22727";
            }else if(info.SMSconsent !== 'null' && info.SMSconsent === "NO"){
                elm.style.backgroundColor = "";
                elm.style.border ="1px solid #979797";
            }
        }
    )
    var requiredSMSYes = document.querySelectorAll("[id='YESsms']");
        requiredSMSYes.forEach((elm, index) => {
            if (info.SMSconsent === 'null') {
                errors.push(elm);
                elm.style.backgroundColor = "white";
                elm.style.border ="3px solid #B22727";
            } else if (info.SMSconsent !== 'null' && info.SMSconsent === "YES"){
                elm.style.backgroundColor = "";
                elm.style.border ="1px solid #979797";
            }
        }
    )

    
    var requiredInsuredContact =  document.querySelectorAll("[id='YesSelectInsuredContact']");
    
    requiredInsuredContact.forEach((elm,index) => {

        if (info.DriverMethodContact === 'null' || info.DriverMethodContact === "- Select -"){
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
        }else{
            elm.style.backgroundColor = "";
            elm.style.border ="";
        }
    }
    )
    
    var requiredSelectCompany =  document.querySelectorAll("[id='YesSelectCompany']");
    
    requiredSelectCompany.forEach((elm,index) => {
        if (info.PolicyHolderCompany ==='null' || info.PolicyHolderCompany === "- Select -"){
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
        }else if(info.PolicyHolderCompany !== 'null' ){
            elm.style.backgroundColor = "";
            elm.style.border ="1px solid #979797";
        }
    }
    )

    var requiredPhoneNumber = document.querySelectorAll("[id='YesPhone']");

    requiredPhoneNumber.forEach((elm) => {
        if (info.PhoneNumber === 'null' || info.PhoneNumber.length < 12 ){
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border = "3px solid #B22727";
        } else if (info.PhoneNumber !== 'null' || info.PhoneNumber.length === 12) {
            elm.style.backgroundColor = "";
            elm.style.border = "1px solid #979797";
        }
    }
    )

    var requiredSelect2 =  document.querySelectorAll("[id='YesSelect2']");
    requiredSelect2.forEach((elm,index) => {
        if( info.UserType === 'null' ){
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
        }else if( info.UserType !== 'null' ){
            elm.style.backgroundColor = "";
            elm.style.border =""
        }
    })
 
    function minYear(input){

      const answer = input.split('-');
      let year = answer[0];
      let month = answer[1];
      let day = answer[2];

      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      var min = today.getFullYear() - Number(year)
      
      if(min <= 16){    
        return false;
      }else{
        return true;
      }  
  }
    var dateRequired = document.querySelectorAll("[id='16Required']");
    dateRequired.forEach((elm,index) => {
        if( info.Birthdate === 'null'  || info.Birthdate === ''){
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
        }
        else if( info.Birthdate !== 'null' ){
            if(minYear(info.Birthdate) === false){
                errors.push(elm);
                elm.style.backgroundColor = "white";
                elm.style.border ="3px solid #B22727";
            }else{
                elm.style.backgroundColor = "";
                elm.style.border ="";
            }
        }
    })
    var required3 =  document.querySelectorAll(".user-type-btn");
    required3.forEach(elm => {
        if(info.UserType !== "null"){
            elm.style.backgroundColor = "";
            elm.style.border =""
        }else {
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727"
        }}
    )
    var required2 =  document.querySelectorAll(".needed");
    required2.forEach(elm => {
        if(elm.value !== "" || info.UserType === "null"){
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797"
        }else {
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727"
        }}
    )

    var requiredOptions=  document.querySelectorAll(".btnInsured");
    requiredOptions.forEach(elm => {
        if(info.Insured === 'null'){

            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
            errors.push(elm);
        }else if(info.Insured !== 'null' ){
            elm.style.backgroundColor = "";
            elm.style.border ="";
        }
    })
    var required6 =  document.querySelectorAll(".btnYes");
    required6.forEach(elm => {
      
        if(info?.TheftWasReported !== 'null' || info?.UserType === 'null' || info?.Accident === 'null'){

            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
            errors.push(elm);
        }else if(info?.TheftWasReported !== 'null' || info?.UserType !== 'null' || info?.Accident !== 'null' ){
            elm.style.backgroundColor = "";
            elm.style.border ="";
        }
        if(info?.TheftWasReported?.length === 0){
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727"
        }else if(info?.TheftWasReported?.length !== 0 ){
            elm.style.backgroundColor = "";
            elm.style.border =""
        }
    }
    ) 

    var requiredPolice =  document.querySelectorAll(".btnYesPolice");
    requiredPolice.forEach(elm => {
      
        if(info?.TheftWasReported === 'null'){

            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
            errors.push(elm);
        }else if(info?.TheftWasReported !== 'null' ){
            elm.style.backgroundColor = "";
            elm.style.border ="";
        }
        if(info?.TheftWasReported?.length === 0){
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727"
        }else if(info?.TheftWasReported?.length !== 0 ){
            elm.style.backgroundColor = "";
            elm.style.border =""
        }
    }
    ) 
    var required2 =  document.querySelectorAll(".btnYes2");
    required2.forEach(elm => {
        if(info.Injuries === 'null' || info.WriteType === 'null' || info.ContactType === 'null'){
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727"
            errors.push(elm);
        } else if (info.Injuries !== 'null' || info.WriteType !== 'null' || info.ContactType !== 'null'){
            var required = document.querySelectorAll("[id='YesWriteType']");
            required.forEach(elm => {
                if (info.ExternalId === 'null') {
                    elm.style.backgroundColor = "white";
                    elm.style.border = "3px solid #B22727"
                    errors.push(elm);
                } else {
                    elm.style.backgroundColor = "";
                    elm.style.border = ""
                }
            })


            elm.style.backgroundColor = "";
            elm.style.border = "";
        }}
    ) 
    var NOTrequired = document.querySelectorAll("[id='NotRequired']");
    NOTrequired.forEach(elm => {
            elm.style.backgroundColor = "";
            elm.style.border =""
        
    })
    var required3 = document.querySelectorAll(".YesEmail");
    required3.forEach(elm => {
        if (info.Email !== undefined) {
            elm.style.backgroundColor = "";
            elm.style.border = ""

            if (!testEmail(info.Email)) {
                errors.push(elm);
                elm.style.backgroundColor = "white";
                elm.style.border = "3px solid #B22727"
            } else {
                elm.style.backgroundColor = "";
                elm.style.border = ""
            }

        } else {
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border = "3px solid #B22727"
        }
    }
    )
    var required2 =  document.querySelectorAll("[id='dependent']");
    required2.forEach(elm => {
    
        if(info.ContactType === "null"){
            elm.style.backgroundColor = "white";
            elm.style.border ="3px solid #B22727";
        }else{
            if(info.ContactType === "Email" && info.Email === ""){
                elm.style.backgroundColor = "white";
                elm.style.border ="3px solid #B22727"
            }else if (info.ContactType === "Email" && info.Email !== ""){
                elm.style.backgroundColor = "";
                elm.style.border =""
            }

            if(info.ContactType === "Phone" && info.Phone === ""){
                elm.style.backgroundColor = "white";
                elm.style.border ="3px solid #B22727"
            }else if (info.ContactType === "Phone" && info.Phone !== ""){
                elm.style.backgroundColor = "";
                elm.style.border =""
            }
        }
        }
    ) 
    return(errors.length)  
}

const testEmail = (email) => {
    email = email.replace(/\s+/, "");
    return /^\S+@\S+\.\S+$/.test(email);
};