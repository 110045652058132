import React, { useState } from "react"
import styled from "styled-components";
import { Link } from "react-router-dom";

const BasicCard = (props) => {
    let CardShape = props.shape
    let Layout = props.layout
    let CardColor = props.data?.background_color;
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    return (
        <>{CardShape === "card-oval" ? (
            <>
                {props.data?.link_target === "_self" ? (
                    <Link to={props.data?.link_url} className={`card-oval card-spacing ${CardColor}`}>
                        <div className="flex-center">
                            <img className="icon-image" src={props.data.icon_image?.filename} alt="icon" />
                            <img className="hover-icon-image" src={props.data.hover_icon_image?.filename} alt="hover-icon" />
                        </div>
                        <div className="title-text">{props.data.title_text}</div>
                        <div className="link-text" >{props.data.link_text}</div>
                    </Link>
                ) : (
                    <a target={props.data.link_target} href={props.data?.link_url} className={`card-oval card-spacing ${CardColor}`}>
                        <div className="flex-center">
                            <img className="icon-image" src={props.data.icon_image?.filename} alt="icon" />
                            <img className="hover-icon-image" src={props.data.hover_icon_image?.filename} alt="hover-icon" />
                        </div>
                        <div className="title-text">{props.data?.title_text}</div>
                        <div className="link-text" >{props.data?.link_text}</div>
                    </a>
                )
                }
            </>
        ) : CardShape === "square" || CardShape === "square-small" ? (
            Layout === "horizontal" ? (
                <>   {props.data?.link_url !== "" ? (
                    <>
                        {
                            props.data?.link_target === "_self" ? (
                                <div className="p-none">
                                    <Link onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} to={props.data?.link_url} >
                                        <div className={props.DefaultCardColor === "" ? (`red-card ${props.size ? (props.size) : (props.data.hover_text.length <= 0 ? 'square-card-no-hover' : 'square-card')} mb-2`) : `${props.DefaultCardColor} ${props.size ? (props.size) : (props.data.hover_text.length <= 0 ? 'square-card-no-hover' : 'square-card')}`}>
                                            {props.data.hover_icon_image ? (
                                                isHovering ? (
                                                    <div className="flex-center py-1">
                                                        <img className="hover-square-icon" alt={props.data?.hover_icon_image?.alt} src={props.data.hover_icon_image?.filename} />
                                                    </div>
                                                ) : (
                                                    <div className="flex-center py-1">
                                                        <img className="square-icon" alt={props.data?.icon_image?.alt} src={props.data.icon_image?.filename} />
                                                    </div>
                                                )
                                            ) : (
                                                <div className="flex-center py-1">
                                                    <img className="square-icon" alt={props.data.icon_image.alt} src={props.data.icon_image?.filename} />
                                                </div>
                                            )
                                            }
                                            <div className="col-text-cntr">
                                                <div className="square-card-title">{props.data.title_text}</div>
                                                <div className="hover-text-square">{props.data?.hover_text}</div>
                                                {props.data.link_text !== "" && (<a href={props.data.link_url} className="hover-square-link" target={props.data.link_target}>{props.data?.link_text}</a>)}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ) : (
                                <div className="p-none">
                                    <a onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} href={props.data?.link_url} target={props.data?.link_target} >
                                        <div className={props.DefaultCardColor === "" ? (`red-card ${props.size ? (props.size) : (props.data.hover_text.length <= 0 ? 'square-card-no-hover' : 'square-card')} mb-2`) : `${props.DefaultCardColor} ${props.size ? (props.size) : (props.data.hover_text.length <= 0 ? 'square-card-no-hover' : 'square-card')}`}>
                                            {props.data.hover_icon_image.length > 0 ? (
                                                isHovering ? (
                                                    <div className="flex-center py-1">
                                                        <img className="hover-square-icon" alt={props.data?.hover_icon_image?.alt} src={props.data.hover_icon_image?.filename} />
                                                    </div>) : (
                                                    <div className="flex-center py-1">
                                                        <img className="square-icon" alt={props.data.icon_image.alt} src={props.data.icon_image?.filename} />
                                                    </div>)) : (
                                                <div className="flex-center py-1">
                                                    <img className="square-icon" alt={props.data.icon_image.alt} src={props.data.icon_image?.filename} />
                                                </div>
                                            )}
                                            <div className="col-text-cntr">
                                                <div className="square-card-title">{props.data.title_text}</div>
                                                <div className="hover-text-square">{props.data?.hover_text}</div>
                                                {props.data.link_text !== "" && (<a href={props.data.link_url} className="hover-square-link" target={props.data.link_target}>{props.data?.link_text}</a>)}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                    </>

                ) : (
                    <div className="p-none">
                        <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className={props.DefaultCardColor === "" ? ("red-card square-card mb-2") : `${props.DefaultCardColor} square-card`}>
                                <div className="flex-center">
                                    {props.data.hover_icon_image?.filename !== "" ? (isHovering ? (
                                        <img className="hover-square-icon" alt={props.data?.hover_icon_image?.alt} src={props.data.hover_icon_image?.filename} />
                                    ) : (
                                        <img className="square-icon" alt={props.data.icon_image.alt} src={props.data.icon_image?.filename} />
                                    )
                                    ) : (
                                        <img className="square-icon" alt={props.data.icon_image.alt} src={props.data.icon_image?.filename} />

                                    )}
                                </div>
                                <div className="col-text-cntr">
                                    <div className="square-card-title">{props.data?.title_text} </div>
                                    <div className="hover-text-square">{props.data?.hover_text}</div>
                                    {props.data.link_text !== "" && (<a href={props.data.link_url} className="hover-square-link" target={props.data.link_target}>{props.data?.link_text}</a>)}

                                </div>
                            </div>
                        </div>
                    </div>
                )}

                </>
            ) : Layout === "vertical" && (
                <>
                    <SquareCard className="col-icon-bg-cntr mx-3 xl" >
                        <div className="icon-cntr flex-center"><span><img style={{ maxHeight: "70px", maxWidth: "70px" }} alt="Icon" src={props.data.icon_image?.filename}></img></span></div>
                        <div className="col-text-cntr">
                            <SquareTitle className="pb-1">{props.data?.title_text}</SquareTitle>
                            <p style={{ fontSize: "1rem" }}>{props.data?.hover_text}<br />
                                <a href={props.data?.link_url}>{props.data?.link_text}</a>
                            </p>
                        </div>
                    </SquareCard>
                </>
            )

        ) : CardShape === "button-list" ? (
            <>
                {props.data?.link_url === "_self" ? (
                    <Link className="flex-center buttonList" target={props.data?.link_target} to={props.data?.link_url}>
                        {props.data.icon_image.filename !== "" && (
                            <img className="mt-3 ml-4 mr-2 mb-3 " style={{ height: "60px", width: "70px" }} alt="Icon" src={props.data.icon_image?.filename}></img>)}
                        <div style={{ fontFamily: "Poppins-Semi-Bold", fontSize: "1.25em" }} className="pt-4 col ">{props.data?.title_text}</div>
                    </Link>
                ) : (
                    <a className="flex-center buttonList" target={props.data?.link_target} href={props.data?.link_url}>
                        {props.data.icon_image.filename !== "" && (
                            <img className="mt-3 ml-4 mr-2 mb-3 " style={{ height: "60px", width: "70px" }} alt="Icon" src={props.data.icon_image?.filename}></img>)}
                        <div style={{ fontFamily: "Poppins-Semi-Bold", fontSize: "1.25em" }} className="pt-4 col ">{props.data?.title_text}</div>
                    </a>
                )}
            </>
        ) : (
            //Continuous 
            <>
                <div style={{ justifyContent: "center", textAlign: "center" }}>
                    <div className="flex-center">
                        <img alt="icon" style={{ maxHeight: "70px", maxWidth: "70px" }} src={props.data.icon_image?.filename}></img>
                    </div>
                    <h6 style={{ color: "black", fontFamily: "Poppins-Bold", fontSize: "1.3rem" }}>{props.data.title_text}</h6>
                    <a style={{ color: "#B22727", fontFamily: "Poppins-Bold", textDecoration: "underline" }} href={props.data?.link_url} target={props.data?.link_target}>{props.data.link_text}</a>
                </div>
            </>
        )

        }
        </>
    )
}

export default BasicCard;


const SquareCard = styled.div`
    max-height: 230px;
    max-width: 245px;
    border-radius: 17px;
    background-color: #FFFFFF;
 
   
`;
const SquareTitle = styled.div`
    height: 20px;
    color: #222222;
    font-family: 'Poppins-Regular';
    font-size: 110%;
    letter-spacing: 0;
    line-height: 20px;
    @media only screen and (max-width:800px){
        height:2px;
    }
  
`;