import { useEffect, useState } from "react";
import RichTextRevolver from 'storyblok-js-client/dist/rich-text-resolver.cjs';
import React from "react";

const ContentHeader = (props) => {
    const [Headline,setHeadline] = useState("");
    const [HeadlineStyle,setHeadlineStyle] = useState("");
    const [SubHeadline,setSubHeadline] = useState("");
    const [Description,setDescription] = useState("");
    const resolver = new RichTextRevolver();
  
        useEffect(() =>{
            props?.data.map((item) => {
                setHeadline(item?.headline);
                setSubHeadline(item?.subheadline);
                setDescription( item?.description);
                setHeadlineStyle(item?.headline_style);
            })
        },[props.data])

    return ( 
        <>
            <div style={!props.headingPosition ? {textAlign:`center`} :{textAlign:`${props?.headingPosition}`}} className={`${HeadlineStyle}`}>
                    <span className={`sub-headline`}>{SubHeadline}</span>
                    <div className={`heading`}>{Headline}</div>
            </div>
            {Description.length <= 0 && (<>
                <div className="justify-content-center align-items-center row " style={{marginBottom:"2%", marginTop:"-1%"}}> 
                <div className="content-header-description" dangerouslySetInnerHTML={{__html:resolver.render(Description)}}></div>
                </div>
                </>)}
        </> 
    );

}
 
export default ContentHeader;