/// <reference path="components/forms/loginusers/changepasswordform.js" />
import {useEffect} from 'react';
import { Route, Routes} from 'react-router-dom';
import { AuthProvider } from './utils/AuthProvider';
import RequiredAuth from './utils/RequiredAuth';
import AdminAuth from './utils/AdminAuth';
import './Assets/Style/Storyblok.scss';
import './Assets/Style/css/custome/responsive.css';
import './Assets/Style/css/custome/style.scss';
import './Assets/Style/css/bootstrap/bootstrap.css';
import './Assets/Style/css/bootstrap/bootstrap-grid.css';
import './Assets/Style/css/bootstrap/bootstrap-reboot.css';
import './Assets/Style/Core.scss';
import './Assets/Style/Components.scss';
import ProducerPortal from './Pages/Producers/ProducerPortal';
import FormsAndGuidelines from './Pages/Producers/FormsAndGuidelines/FormsandGuidelines';
import DocumentPublication from './Pages/Producers/DocPublc';
import Mailers from './Pages/Producers/Mailers';
import Compensation from './Pages/Producers/Compensation';
import OrderSupplies from './Pages/Producers/OrderSupplies';
import Announcements from './Pages/Producers/Announcements';
import ProducerFAQ from './Pages/Producers/ProducerFAQ';
import TexasMaverickAuto from './Pages/Producers/FormsAndGuidelines/TexasMaverickAuto';
import TexasPointRider from './Pages/Producers/FormsAndGuidelines/TexasPointRider';
import FooterComponent from './Components/Layout/Footer/Footer';
import Navbar from './Components/Layout/Navbar';
import AddProducer from './Pages/Admin/AddProducer';
import { AppRoutes} from './Routes';
import ReactGA from 'react-ga';
import Progress from './Pages/Progress';
import ChangePassword from './Pages/Producers/ChangePassword';
import ResetPassword from './Pages/Producers/ResetPassword';
import OnlineTraining from './Pages/Producers/OnlineTraining';
import Newsletters from './Pages/Producers/Newsletters';
import TexasCommercialAuto from './Pages/Producers/FormsAndGuidelines/TexasCommercialAuto';
import TimeoutLogout from './utils/LogoutTimeout';
const TRACKING_ID = "UA-129150993-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []); 

  return (
    <>
          <AuthProvider>
      <TimeoutLogout />
          <Navbar/>
            <Routes>
                {AppRoutes.map(
                    ({ path, element }, key) => (
                        <Route path={path} element={element} key={key} />
                    )
                )}
                <Route path='/ProducerPortal' element={<RequiredAuth><ProducerPortal /></RequiredAuth>} />
                <Route path='/Newsletters' element={<RequiredAuth><Newsletters /></RequiredAuth>} />
                <Route path='/OnlineTraining' element={<RequiredAuth><OnlineTraining /></RequiredAuth>}/>
                <Route path='/Announcements' element={<RequiredAuth><Announcements /></RequiredAuth>} />
                <Route path='/FormsAndGuidelines' element={<RequiredAuth><FormsAndGuidelines /> </RequiredAuth>} />
                <Route path='/TexasMaverickAuto' element={<RequiredAuth><TexasMaverickAuto /> </RequiredAuth>} />
                <Route path='/TexasPointRider' element={<RequiredAuth><TexasPointRider /></RequiredAuth>} />
                <Route path='/TexasCommercialAuto' element={<RequiredAuth><TexasCommercialAuto /></RequiredAuth>} />
                <Route path='/DocumentsAndPublications' element={<RequiredAuth><DocumentPublication /></RequiredAuth>} />
                <Route path='/Mailers' element={<RequiredAuth><Mailers /></RequiredAuth>} />
                <Route path='/Compensation' element={<RequiredAuth><Compensation /></RequiredAuth>} />
                <Route path='/OrderSupplies' element={<RequiredAuth><OrderSupplies /></RequiredAuth>} />
                <Route path='/ProducerFAQ' element={<ProducerFAQ />} />
                <Route path='/AddProducer' element={<AdminAuth><AddProducer /></AdminAuth>} />
                <Route path='AddProducer/:firstname/:lastname/:email' element={<AdminAuth><AddProducer /></AdminAuth>} />
                <Route path='/ChangePassword' element={<ChangePassword />} />
                <Route path='ResetPassword/:token' element={<ResetPassword />} />
                <Route path='*' element={<Progress/>} />
              </Routes> 
            <FooterComponent />  
      </AuthProvider>
    </>
  );
}

export default App;
