import RichTextRevolver from 'storyblok-js-client/dist/rich-text-resolver.cjs';

export default function SB_ContentHeader({blok}) {
  
    const resolver = new RichTextRevolver();

    return ( 
        <>
            <div className={`${blok?.headline_style}`} style={blok?.headingPosition ? {textAlign: blok.headingPosition, paddingTop:"2.5%"} : {textAlign:blok?.header_alignment, paddingTop:"2.5%"}}>
                    <span className={`sub-headline`}>{blok?.subheadline}</span>
                    <div className='heading'>{blok?.headline}</div>
                <div style={{marginBottom:"2%", marginTop:"25px"}}> 
                    <div className="content-header-description" dangerouslySetInnerHTML={{__html:resolver.render(blok?.description)}}></div>
                </div>
            </div>
        </> 
    );

}
