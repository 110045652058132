import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { limit } from '../../../../Helpers/InputHelper';
import validateInfo from '../../Validation/DriverModal';
function EditVehicle({id,Year,Make,Model,updateVehicle}) {    

    const [YearNew, setYearNew] = useState();
    const [MakeNew, setMakeNew] = useState();
    const [ModelNew, setModelNew] = useState();
    const [show, setShow] = useState(false);

    const handleClose = (e) =>{e.preventDefault(); setShow(false)};
    const handleShow = (e) => {e.preventDefault(); setShow(true)};
    useEffect(() => {
        setYearNew(Year);
        setMakeNew(Make);
        setModelNew(Model);
    },[Year,Make,Model])
    let handleClick= (e) => {
      
         e.preventDefault();
         if(validateInfo({YearNew:YearNew,MakeNew:MakeNew,ModelNew:ModelNew}) <= 0 ){
            updateVehicle(id, YearNew, MakeNew, ModelNew);
            setShow(false);
         }
    }
    return (
        <>
            <button onClick={e => handleShow(e)}  style={{width:"auto",backgroundColor:"transparent"}}><div className='pencil-icon'/></button>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered dialogClassName="border-radius-2 col-lg-6 pr-5"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
               <div className="row m-3 ml-4">
                    <div className="col-lg-11 col-10 modal-title" >
                        {`Edit Vehicle ${id + 1}`}
                    </div>
                    <button className=" mt-1  modal-x" style={{width:"auto"}} onClick={handleClose} ><FontAwesomeIcon icon={faXmark}/></button>
                </div><span className="header-divider"></span>
                
            <form  autoComplete="off" autoCapitalize="on">
              <div className='flex-center'>
               <div className="col-lg-10 mr-2 pt-3"> 
                    <>
                        <div>Year<span className="required-asterisk"></span></div>   
                            <input className="form-input needed pl-2"
                                    id="Year"
                                    maxLength="256"
                                    type="text"
                                    value={YearNew}
                                    onKeyUp={(e) =>limit(e,4)}
                                    onKeyDown={(e) => limit(e,3)}
                                    onChange={(e) => {
                                                setYearNew(e.target.value)
                                            }}
                                        /><br/><br/>
                    </>
                      <>
                        <div>Make<span className="needed required-asterisk"></span></div>
                               <input
                                    className="form-input needed pl-2"
                                    maxLength="256"
                                    id="make"
                                    type="text"
                                    value={MakeNew}
                                    onChange={(e) => {
                                       setMakeNew(e.target.value);
                                    }}
                                    /><br/><br/>
                      </>
                      <>
                          <div>Model<span className="needed required-asterisk"></span></div>
                                
                                    <input
                                        maxLength="256"
                                        className="form-input pl-2"
                                        id="model"
                                        type="text"
                                        value={ModelNew}
                                        onChange={(e) => {
                                           setModelNew(e.target.value);
                                        }}
                                    /><br/><br/>
                          </>
                          </div>
                        </div>
                    </form>
                    <div className="row pt-2 pb-4 pl-5 pr-5 justify-content-center ">
                            <button onClick={handleClick}  className=" col-lg-7 justify-content-center tealBtn" form="editmodal" >
                                {`Confirm Vehicle ${id + 1} Information`} 
                            </button>
                    </div>
            </Modal>
        </>
    );
}

export default EditVehicle;