import { useEffect, useState} from 'react';
import validateInfo from '../../Validation/DriverModal';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { TodayDate } from '../../../../Helpers/InputHelper';
const DriverModal = ({idx,input,setConfirmDriver, action,stateForm,setState,handleDriverChange}) => {
    const [Attempt,setAttempt] = useState(false);
    const [formErrors, setFormErrors] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalShow, setModalShow] = useState(true);
    const handleClose = () => setModalShow(false);


    useEffect(() => {
      if (formErrors === "Ready" && isSubmitting) {
        setConfirmDriver(true)
        action();
     }
    },[formErrors, isSubmitting])

    useEffect(() => {
      if(Attempt === true){
        setFormErrors(validateInfo(stateForm));
        if(formErrors.length > 1){
          setIsSubmitting(false)
         }else if(formErrors <= 0){
          setFormErrors("Ready")
         }
      }
    },[Attempt, formErrors, stateForm])
    let handleSubmit = (e) => {
       e.preventDefault();
       setAttempt(true);
       setIsSubmitting(true); 
    }
    return (
    <>
        <Modal show={modalShow} onHide={handleClose}aria-labelledby="contained-modal-title-vcenter"
          centered dialogClassName="col-lg-6 pr-5 border-radius-2" key={idx} >
           <div className="row ml-4 m-3">
              <div className="col-lg-11 col-10 modal-title">
                {`Add Driver ${idx + 1}`}
              </div>
              <button onClick={action} style={{width:"auto"}} className=" mt-1 modal-x"><FontAwesomeIcon icon={faXmark}/></button>        
            </div><span className="header-divider"></span>
          <form autoComplete="off" autoCapitalize="on" className='pt-3 p-4'>
            <div className=" flex-center wrap " >
                        <div className="col-lg-6 mb-4">First Name<span className="required-asterisk"></span> <br />
                            <input
                                maxLength="256"
                                id='YesDriver'
                                className="form-input"
                                name="FirstName"
                                type="text"
                                value={input.FirstName}
                                onChange={e => handleDriverChange(idx,e) } />  
                               <br/>
                         </div>
                         <div className="mb-4 col-lg-6 ">Last Name<span className="required-asterisk"></span> <br />
                            <input 
                                maxLength="256"
                                className=" form-input py-2"
                                id='YesDriver'
                                name="LastName"
                                type="text"
                                value={input.LastName}
                                onChange={e => handleDriverChange(idx,e) } />    
                               <br/>
                        </div>
            </div>
              <div className='flex-center wrap '>
                   <div className="col-lg-6 mb-4">Birthdate<span className="required-asterisk"></span> <br />
                          <div style={{width:"70%"}}>
                               <input
                                    maxLength="256"
                                    id='YesDriver' max={TodayDate()}
                                    className="form-input-lg" name="Birthdate"
                                    type="date"
                                    value={input.Birthdate}
                                    onChange={e => handleDriverChange(idx,e) } />   
                                <br/>
                        </div>
                   </div>
                   <div className=" col-lg-6">
                      <div className=' required-asterisk pb-1'>Has this Driver had any accidents in the last three years?</div>
                        <input type="button" id='YesDriverAccident' className={ `col-5 mr-3 justify-content-center  contactBtn ${(input.Accident === 'Yes') ? 'onBtn' : 'offBtn'}`} onClick={e => handleDriverChange(idx,e)}  name= "Accident" value={`Yes`} />
                        <input type="button" id='YesDriverAccident' className={ `col-5 justify-content-center contactBtn ${(input.Accident === 'No') ? 'onBtn' : 'offBtn'}`}  onClick={e => handleDriverChange(idx,e)} name="Accident"   value={`No`}  />    
                        <br/>
                  </div>
              </div>
     
            </form>
          <div className="col-lg-10 row offset-lg-1 justify-content-center px-5 pb-4">
                <button  onClick={handleSubmit}  className="modal-Btn">{`Confirm Driver ${idx + 1} Information`}</button>
            </div>
        </Modal>
      </>
    );
    
  };
export default DriverModal;
