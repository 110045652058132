import React from 'react'

export default function DateFormatter(props) {    
    function convertMonth(format){
        switch(format){
            case '01':return 'Jan';
            case '02': return 'Feb';
            case '03': return 'Mar';
            case '04': return 'Apr';
            case '05': return 'May';
            case '06': return 'Jun';
            case '07': return 'Jul';
            case '08': return 'Aug';
            case '09': return 'Sep';
            case '10': return 'Oct';
            case '11': return 'Nov';
            case '12': return 'Dec';
            default: return format;
        }
    }

    function date (format){
        let [year,month,day] = format.split("-");
        let [newDay] = day.split(" "); 
       
        let shortMonth = convertMonth(month)
        return <>{shortMonth} {newDay}, {year}</>
    }

    return (
        date(props.startDate)
  )
}
