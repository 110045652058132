/// <reference path="../pages/commercialauto.js" />
import RichText from "./Core/RichText";

const TextSection = ({ data }) => {
  let Description = data?.text_body;
  if (data.body) {
    Description = data.body;
  }
  return (
    <>
      {data?.subheading !== "" && (
        <div className="col-lg-6 offset-lg-3 py-1" style={{color: "#B22727", fontFamily: "Poppins-Bold", fontSize: "16px", textAlign: "center"}}>
          {data.subheading}
        </div>
      )}
      {data?.heading !== "" && (
        <div className="text-section-heading col-lg-8 offset-lg-2 mb-4 py-1">
          {data.heading}
        </div>
      )}
      <div className="col-lg-8 offset-lg-2 media-object-p" style={{ fontFamily: "Poppins-Regular", fontSize: "15px" }}>
        <RichText Description={Description} />
      </div>
      <br />
    </>
  );
};

export default TextSection;
