import Banners from "../../Components/Core/Banners";
import Breadcrumb from "../../Components/Core/BreadCrumb";
import FAQ from "../../Components/FAQ";
import { Link } from "react-router-dom";
const items = [
    { to: '/', label: 'Home' },
    { to: '/ProducerLogin', label: 'Producer Login' },
    { to: '/ProducerFAQ', label: 'Producer FAQ' }
];

const ProducerFAQ= () => {
    return ( 
        <>
            <Banners img={"https://cdn.lonestarmga.com/img/ClaimsFAQbanner.png"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/faq-banner_390x282.png"} page={"Producer FAQ"} category={"Lonestar MGA"} />
                <div style={{minHeight:"500px", backgroundColor:"#F0F0F0"}}> 
                            <div className="py-4 ml-4  mr-4 flex-center wrap ">
                                <div style={{width:"1300px"}}>
                                    <Breadcrumb>{
                                    items.map(({to, label}) =>(           
                                        <Link key={to} to={to}>{label}</Link>
                                    ))}
                                    </Breadcrumb>
                                </div>
                            </div>
                            <div className="m-4" style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                                <div className="lg-w">
                                        <FAQ page={"Producer"} />
                                </div>
                    </div>
            </div>
        </>
     )
}
 
export default ProducerFAQ