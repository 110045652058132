import React from "react";
import AddressLine from "../AddressLine";
import Buttons from "../Buttons/Buttons";
import PhoneLine from "../PhoneLine";
import SimpleMUCText from "../SimpleMUCText";
import NewPhoneLine from "../Storyblok/NewPhoneLine";


const MultiUseCard = ({ data }) => {

    return ( 
        <>
            <div className="p-5">
                    <div className="p-1 flex-start">
                        {data.icon_image.filename !== "" && (
                            <img className="MUC-Icon" alt="Icon Driven Through Storyblok" src={data.icon_image.filename}/>
                        )}
                        <div className="MUC-Title">{data.title_text}</div>
                    </div>
                    <div className="flex-center mx-3  MUC-Description">{data.content_text}</div>
                    <div className="row p-1 ">
                        {
                            data.content_blocks.map((block) => {
                               return(
                                block.component === "Button" ? (  
                                    <>
                                        <div className="col-lg-11 mx-3 py-3">
                                            <Buttons data={[block]} />
                                        </div>
                                    </>
                               ): block.component === "Phone_Line" ? (
                                    <>
                                        <NewPhoneLine data={block} /> <br/>
                                    </>
                                      ) : block.component === "simple_muc_text" ?
                                        (<>
                                                   <SimpleMUCText data={block} />
                                          </>) :
                                               block.component === "Address_Line" && (
                                    <>
                                        <AddressLine data={block} />    
                                    </>
                                )
                               )
                            })
                        }
                    </div>
            </div>
                
        </> 
    )
}
export default MultiUseCard 