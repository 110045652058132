export const INITIAL_STATE = {
        ContactFirstName: '',
        ContactLastName: '',
        AgencyName: '',
        Street: '',
        City: '',
        State: 'null',
        Zip: '',
        Email: '',
        Phone: '',
        MaverickPolicyJacket: '0',
        PointRiderPolicyJacket: '0',
        MaverickDesktopGuide: '0',
        PointRiderDesktopGuide: '0',
        BinderEnvelopes: '0',
        ClaimCards: '0',
        PointRiderUnderWritingGuideline:'0',
        Comment: ''
};
/**
 * Purpose: Update the state and return the new version of the state
 * @param {any} state
 * @param {any} action
 */
export const formReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_INPUT":
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        case "INCREASE":
            let resultIncrease = parseInt(action.payload.value) + 1;
            return {
                ...state,
                [action.payload.name]: resultIncrease.toString()
            };
        case "DECREASE":
            let resultDecrease = action.payload.value
            if (parseInt(resultDecrease) > 0) {
                 resultDecrease = parseInt(action.payload.value) - 1;
            }
            return {
                ...state,
                [action.payload.name]: resultDecrease.toString()
            };
        default: return state;
    }
}