import { useEffect,useState } from "react";

export default function Banners(props) {
    const [windowSize, setWindowSize] = useState(getWindowSize());
   
   const BannerSection =  {
       background: `url(${props.img}) no-repeat center center`,
       height: "350px",
       backgroundSize: "cover",
       width:"100%"
   };
   const LargeBannerSection =  {
    background: `url(${props.img}) no-repeat center center`,
    height: "350px",
    backgroundSize: "contain",
    width:"100%"

   };
   const MobileBannerSection ={
    background: `url(${props.mobileImg}) no-repeat center bottom`
   }
   useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
   function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

    return (
        <div className="flex-center">
            <div style={windowSize.innerWidth < 550 ? (MobileBannerSection): windowSize.innerWidth > 1920 ? (LargeBannerSection) : (BannerSection)} className={windowSize.innerWidth < 550 ? ("header-section-mobile"): ("header-section")} >
                    <div className="flex-center align-items-top ml-auto pt-2 mr-auto align-content-center py-2">
                        <div className="justify-content-center text-align-center py-4">
                            <div className="banner-sub-heading" >
                                {props.category}
                            </div> 
                            <div className="banner-heading">
                                {props.page}
                            </div>
                        </div>
                    </div>
            </div>
        </div>
  )
}

