/// <reference path="modals/sentmodal.js" />
/// <reference path="loginform.js" />
import { useState, useEffect, useRef } from "react";
import validate from "../Validation/LoginValidate";
import SentModal from "./Modals/SentModal";
import { Link } from "react-router-dom";
import $ from "jquery";
const ForgotPasswordForm = () => {
    const [Email, setEmail] = useState({
        Email: ""
    });
    const [formErrors, setFormErrors] = useState(1);
    const [PublicId, setPublicId] = useState(-1)
    const [Submitting, setSubmitting] = useState(false);
    const [Attempt, setAttempt] = useState(false);
    const [show, setShow] = useState(false);

    const EmailRef = useRef(null);


    useEffect(() => {
        if (Submitting && formErrors === "Ready") {
            setShow(true);
            $.ajax({
                type: "POST",
                url: "/AuthenticateUser/ForgotPassword",
                data: JSON.stringify({
                    Email: Email.Email,
                    Application: "LONESTAR"
                }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response) {
                    setPublicId(response)
                },
                failure: function (response) {
                    alert(response);
                },
            });
        } 


    }, [formErrors])
    useEffect(() => {
        if (Attempt === true) {
            setFormErrors(validate({ Email: Email.Email, }));

            if (formErrors > 0) {
                setSubmitting(false);
            } else if (formErrors <= 0) {
                setFormErrors("Ready");
                setAttempt(false);
            }
        }
    })
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setEmail({
            ...Email,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate({ Email: Email.Email, }));
        setAttempt(true);
        setSubmitting(true);
    }
    return ( 
            <>
                <div className="md-form-body">
                    <div className="flex-center">
                        <label className="header-login">Forgot Password?</label>
                    </div>
                <div className="flex-center">
                    <label className="py-2"><div style={{ fontSize:"1rem" }}>Enter your email associated with this account and we’ll send you a link to your email  to reset password.</div></label>
                    </div>
                    <div className="pb-2" > Email <br />
                        <input required type="text" ref={EmailRef} onChange={handleChange} name="Email" maxLength="256"  className="email-login-form-input pt-4 YesEmail " autoComplete="off" style={{textTransform:"none", padding:"22.5px 14px",height:"1.4375em"}} />
                    </div><br />
                <div className="flex-center">
                    <button className="primary-lg-pill-btn" onClick={handleSubmit}>Send</button>
                </div>
                <div className="flex-center">
                    <Link className="flex-center" to="/ProducerLogin">Cancel</Link>
                </div>
            </div>
            {
                show && (<SentModal setShow={setShow} show={show} />)
            }
            </>
    ) ;
}
export default ForgotPasswordForm;