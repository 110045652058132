import {useState, useEffect, useReducer} from "react"
import SuccessForm from "./successForm";
import ContactUs from "./ContactUs";
import { formReducer, INITIAL_STATE } from "./FormReducer";

const MasterContactUs = () => {
    const [State, Dispatch] = useReducer(formReducer, INITIAL_STATE);
    const [isSubmitted, setIsSubmitted] = useState(false);
    useEffect(() => {
        Dispatch({ type: "" });
    }, [State])

    const handleChange = (e) => {
        e.preventDefault();
        Dispatch({
            type: "CHANGE_INPUT",
            payload: { name: e.target.name, value: e.target.value }
        });
    }

    const submitForm = () => {
        return setIsSubmitted(true);
    }
    return ( 
        isSubmitted === false ? (
            <ContactUs submitForm={submitForm} handleChange={handleChange} state={State} dispatch={Dispatch} />
        ): isSubmitted === true && (
            <SuccessForm />
        )
     );
}
 
export default MasterContactUs;