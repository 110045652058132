import { storyblokEditable } from "@storyblok/react";
import RichText from "../Core/RichText";

export default function SB_PlainText({blok}) {

  return (
    <div className=" flex-center px-4 py-4" {...storyblokEditable(blok)} >
        <div style={{width:"1300px"}}>
            <RichText Description={blok?.text_body} />
        </div>
    </div>
  )
}
