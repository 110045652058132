import { useEffect, useState} from "react"
import BasicCard from "./BasicCard"
import "../../Assets/Style/buttons.scss"
import RichCard from "./RichCard"
import ProductCard from "./ProductCard";
import MultiUseCard from "./MultiUse";
import CardCTA from "./CardCTA";
import HoursOfOperation from "./HoursOfOperationCard";
import ContactCard from "./ContactCard";
import ContentCard from "./ContentCard";
import BlogCard from "./BlogCard";
import styled from "styled-components";
import "../../Assets/Style/Components.scss"
import Basic from "./Basic";

const CardConfiguration = ({data,config}) => {

    const [CardStyle, setCardStyle] = useState("");
    const [Layout, setLayout] = useState("");
    const [GridColumns, setGridColumns] = useState("");
    const [ImageBackground, setImageBackground] = useState("");
    const [BackgroundColor, setBackgroundColor] = useState("");
    const [DefaultCardColor, setDefaultCardColor] = useState("");
    const [ContainerStyle, setContainerStyle] = useState("");
    const [IconSize, setIconSize] = useState("")
    const [Length,setLength] = useState("")
    const [CardSize, setCardSize] = useState("");
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        config.map((item) => {
           
            setCardStyle(item.card_style);
            setLayout(item.layout);
            setGridColumns(item.grid_columns);
           
            // setIsBackground(item.background_image.id);
            setImageBackground(item.background_image.filename);
            setBackgroundColor(item.background_color);
            setDefaultCardColor(item.set_default_card_color);    
            setContainerStyle(item.card_container_style);
            setIconSize(item?.icon_style);
            setCardSize(item?.card_size);
        })
    },[config,data])
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);
    const lastIndex = data?.length - 1
    const spaceBetweenItems = 25; // edit to increase/decrease space between items

    const containerStyle = {
        display: "flex",
        flexWrap: "wrap",
        margin: `-${spaceBetweenItems * 0.5}px`,
        justifyContent: "center" 
      };
    const containerStyleBlock = {
        display: "grid",
        flexWrap: "wrap",
        margin: `-${spaceBetweenItems * 0.5}px`,
        justifyContent: "center" 
    };
      const itemStyle = {
        display: "block",
        flex: "none",
        maxWidth: `${90 / GridColumns}%`,
        boxSizing: "border-box",
        padding: `${spaceBetweenItems * 0.5}px`,
        justifyContent: "center",
        alignItem:"center",
        alignContent:"center"
      };
      const verticalStyle = {
        display: "block",
        flex: "none",
        maxWidth: `${100 / 1}%`,
        boxSizing: "border-box",
        padding: `${spaceBetweenItems * 0.5}px`
      }
      function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
   
      useEffect(() => {
        data.map((datas,index) => {
            datas.component === "Rich_Card" &&
                  datas?.title_text.split("").length >= 21 && (
                     setLength("height-larger")
                  )
          })
      },[])
    return (
        <> 
        {/* GRID */}
        {
            Layout === "grid" && GridColumns > 1 ? (
                <>  
                     <div style={windowSize.innerWidth < 550 ?(containerStyleBlock) : (containerStyle)}>
                     {
                        data.map((datas,index)=> {
                            return (
                               datas.component === "Basic_Card" ? (
                                    window.innerWidth < 1200 ? (
                                                            <>
                                                                 <div className={` ${DefaultCardColor} ${CardStyle} ${CardSize} m-3`}>
                                                                        <Basic data={datas}/>
                                                                </div>
                                                            </>
                                                        ): (
                                                            <>
                                                                <div style={itemStyle}>
                                                                    <div className={`col-icon-bg-cntr ${DefaultCardColor} ${CardStyle} ${CardSize} `}>
                                                                        <Basic data={datas}  />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                               ): datas.component === "Blog_Card" &&(
                                                    <>
                                                        <div style={window.innerWidth < 900 ? {} : itemStyle}>
                                                            <BlogBody className="justify-content-center">
                                                                <BlogCard key={index} data={datas} />
                                                            </BlogBody>
                                                        </div>
                                                    </>
                                                ))
                        })
                     }
                      </div>
                </>
//  HORIZONTAL
  ) : Layout === "horizontal" ? (
                ContainerStyle === "continuous" ? (
                <>
                {/* Background Color */}
                        {DefaultCardColor === "white-card" && (
                                setDefaultCardColor("cont-horiz-white-card")
                        )}
                            <div className={`flex-center gx-5 flex-wrap space ${DefaultCardColor}`} style={{justifyContent:"center"}}>
                                    {data.map((datas, index) => {
                                    
                                        return(
                                            <>
                                            {datas.component === "Basic_Card" ? (
                                                data.length > 2 ?(
                                                    <div className="col-lg-4 col-md-3 col-sm-12 pro-border justify-content-center">
                                                          <BasicCard shape="continuous" key={index} data={datas} />
                                                    </div>
                                                ):( 
                                                    <div className="col-lg-6 col-md-3 col-sm-12 justify-content-center pro-border">
                                                        <BasicCard shape="continuous" key={index} data={datas} />
                                                    </div>
                                                )
                                            ) : datas.component === "Rich_Card" && (
                                                    ContainerStyle === "continuous" && (
                                                      <> 
                                                        <div className={data.length > 2 ? 'col-lg-4 pt-4 pro-border' : 'col-lg-6 pt-4 pro-border'}>
                                                             <RichCard iconSize={IconSize} layout={Layout} key={index} data={datas} />
                                                        </div>
                                                      </>
                                                    ))
                                                }
                                             
                                            </>
                                        )
                                    })}
                            </div>
                        {/* </div> */}
                          
                </>
            //Default Container Style = Individual   
            ):(
                <>  
                    <div className="flex-center flex-wrap gap-1" style={{background:`${ImageBackground} no-repeat`}}>       
                   
                        {data.map((datas,index)=> {
                             return (
                                  <div className="" style={{ backgroundColor:{BackgroundColor}}} > 
                                     {datas.component === "Basic_Card" ? (
                                         <BasicCard shape={CardStyle} size={CardSize} layout={Layout} key={index} data={datas} DefaultCardColor={DefaultCardColor} />
                                         ) : datas.component === "Rich_Card" ? (
                                             <> {ContainerStyle === "continuous" && (
                                                   <> {index === lastIndex && (<span className="continuous"/> )}</>
                                                 )}
                                                 <div className="help-join">
                                                     <RichCard iconSize={IconSize} layout={Layout} key={index} data ={datas} LengthTitle={Length} />
                                                 </div>
                                              </>
                                            ): datas.component === "Product_Card" ? (
                                                <div className="p-4">
                                                <ProductCard data={datas}/>
                                                </div>
                                            ): datas.component === "Content_Card" ? (
                                                <div className="">
                                                    <ContentCard data={datas} />
                                                </div>
                                            ): datas.component === "Multi_Use_Card" && (
                                              <div className="MUC-Style m-4">    
                                                 <MultiUseCard data={datas} />
                                              </div>
                                            )}
                                        </div>)
                                    })
                                }
                        </div>
                    </> 
                )
//VERTICAL 
            ): Layout === "vertical" && ( <>
                            <div style={containerStyle}>
                                    {data.map((datas,index) => {
                                        return(
                                            <>
                                            <div style={verticalStyle}>
                                                    {datas.component === "Call_To_Action_Card" ? (
                                                        <div className="col-lg-9">
                                                            <CardCTA data={[datas]} />
                                                            <br />
                                                        </div>
                                                    ) : datas.component === "Hours_Of_Operation_Card" ? (
                                                        <>
                                                            <div className="pb-1 col-lg-9" style={{ marginTop: "-10%", marginLeft: "-10%", textAlign: "left" }}>
                                                                {/* <h1 style={{color:"#DCDCDCDC"}}>______ </h1> */}
                                                                <HoursOfOperation data={[datas]} />
                                                                {/* {index === lastIndex && (<span className="continuous"/> )}    */}
                                                            </div><br />
                                                        </>
                                                    ) : datas.component === "Contact_Card" && (
                                                        <>
                                                            <div >
                                                                <ContactCard data={datas} />
                                                            </div> <br />

                                                        </>
                                                    )}
                                               </div>
                                                { 
                                                    datas.component === "Basic_Card" && (
                                                    <>
                                                        <BasicCard shape="button-list" layout={CardStyle} key={index} data ={datas} /> 
                                                        <br/>
                                                    </>
                                                )}
                                                
                                            </>
                                        )
                                    })}
                           </div> 
                    </>)
        }

         </> 
    )

}

export default CardConfiguration
const BlogBody = styled.div `
    height: 100%;
    max-width: 500px;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 16px 0 rgba(141,141,141,0.5);
    margin-bottom:2%;
    margin-right:1%;
    @media only screen and (max-width:800px){
        margin:5%;
        padding: 5% !important;
        height: auto !important;
        max-width: 400px;
        min-width: 350px;

    }
`;

