import React from "react"
import styled from "styled-components";
import RichTextRevolver from 'storyblok-js-client/dist/rich-text-resolver.cjs';

const ContentCard = ({data}) => {
    
    const resolver = new RichTextRevolver();
    let Description = data.content_text;
    return ( 
        <>         
                    <div className="justify-content-center row " style={{maxWidth:"450px",alignContent:"space-evenly"}}>
                        <div className="">
                              <ContentCardImage src={data.image.filename}  alt="Icon"/>
                        </div>
                        <div className="row col-lg-12 py-4 " style={{textAlign:`${data.text_alignment}`,paddingTop:"1%", paddingBottom:"5%"}}>
                                <DescriptionRC dangerouslySetInnerHTML={{__html:resolver.render(Description)}}></DescriptionRC>
                        </div>   
                          
                    </div>   
        </> 
    )
}
 
export default ContentCard 


const DescriptionRC = styled.div`
  min-height:100px;
  height:100px;
  font-family:"Poppins-Regular";

  p{font-size: 22px;}
  @media only screen and (max-width:800px){
    p{font-size: 16px;}
  }
  
`;

const ContentCardImage = styled.img`
    width:350px;
    max-height: 352px;

    @media only screen and (max-width:800px){
        width:200px !important;
        height: 152px;
    }
`;