import Breadcrumb from "../../Components/Core/BreadCrumb";
import { useStoryblok } from "@storyblok/react";
import TextSection from "../../Components/TextSection";
import Banners from "../../Components/Core/Banners";
import { Link } from "react-router-dom";
import { MDBSpinner } from 'mdb-react-ui-kit';

const items = [
    { to: '/ProducerPortal', label: 'Home' },
    { to: '/DocumentsAndPublications', label: 'Documents and Publications' },
    { to: '/Compensation', label: 'Producer Compensation Disclosure' }
]

const Compensation = () => {

    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const disclosureStory = useStoryblok("lonestar/producer/producer-compensation-disclosure", { version: storyblokVersion });
    const disclosure2Story = useStoryblok("lonestar/producer/producer-compensation-disclosure-part-2", { version: storyblokVersion });
    
    if(!disclosureStory?.content || !disclosure2Story?.content){
        return <div className="loader-section">
                <MDBSpinner color='danger' className="spinner"></MDBSpinner>
               </div>
    }
    return ( 
        <>
            <Banners img={"https://cdn.lonestarmga.com/img/Banners/formsandguidelines_borderless.svg"} mobileImg={"https://cdn.lonestarmga.com/img/Banners/mobile/forms-and-guidelines_389x275.svg"} page={"Producer Compensation Disclosure"} category={"Documents and Publications"} />
            <div className="compensation-page-body"> 
                    <div className="offset-lg-2 py-3 px-4 ">
                        <Breadcrumb>{
                            items.map(({to, label}) =>(           
                                <Link key={to} to={to}>{label}</Link>
                            ))}
                        </Breadcrumb>
                    </div>
                    <div className="col-lg-12  ">
                      <TextSection data={disclosureStory.content}/>
                    </div>
                    
           </div><br/><br/>
            <div className="flex-center align-content-center align-items-center white-background"> 
                <div className="col-lg-12">
                    <TextSection data={disclosure2Story.content} />
                </div>   
            </div>
        </>
     );
}
 
export default Compensation;