import { Link } from "react-router-dom";
const Progress = () => {
    return (
        
        <>
           <div className="progress-style" style={{minHeight:"900px"}}>
                <div className="row">
                    <div className="col-lg-12 py-5" style={{textAlign:"center"}}>
                        <div className="" style={{fontSize:"40px",fontFamily:"Poppins-Regular"}}>This Page is under Construction </div>
                        <div style={{fontSize:"30px",color:"firebrick", fontFamily:"Poppins-Bold"}}>Please Come back later ...</div>
                        <div style={{textDecoration:"underline", fontSize:"18px"}}><Link to="/" >Return Home</Link></div>
                        <img src={"https://cdn.lonestarmga.com/img/guy-on-computer_498x540.png"} alt="Pending Page Icon" style={{ width: "32%", height: "70%", paddingTop: "2%" }}></img>
                    </div> 
                </div>
            </div>
        </> 
        
        );
}
 
export default Progress;