import React,{useState} from "react"
import LinkButton from "./LinkButton"
import ReactTooltip from "react-tooltip";
import DownloadButton from "./DownloadButton";
import { Link } from "react-router-dom";
import ClaimBusinessHoursGlobal from "../Storyblok/ClaimBusinessHoursGlobal";
import HoursOfOperationGlobal from "../Storyblok/HoursOfOperationGlobal";
import PolicyHoursOfOperationGlobal from "../Storyblok/PolicyServiceHourofOperation";
import ReportHoursOfOperationGlobal from "../Storyblok/ReportNewClaimHours";


const Buttons = ({ data, config }) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
      };
    
      const handleMouseOut = () => {
        setIsHovering(false);
      };
    return ( 
        <>
            
            {data.map((item) => {
                   
                    return(
                      item.component === "Button" ? (
                        item.button_style === "primary-rectangle-arrow" ? 
                        (
                            <div style={{paddingBottom:"20px"}}>
                                                                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>

                            <Link className="flex-start align-items-center primary-rectangle-arrow flex-basis-10" target="_self" to={item.link_url}>{item.button_text}<i></i></Link>
                            </div>
                        ): item.button_style === "white-large-grey-border-btn" ? (
                            <div className="p-none">
                            <Link onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}  to={item?.link_url} >
                                        <div className={` ${item.button_style}`}>
                                                {item.hover_icon_image !== "" ?
                                                  (  isHovering ? (
                                                            <div className="flex-center py-1">
                                                                <img className="hover-square-icon" alt={item?.hover_icon_image?.alt} src={item.icon_image?.filename}/>
                                                            </div>
                                                        ) : (
                                                            <div className="flex-center py-1">
                                                                <img className="square-icon" alt={item.icon_image.alt} src={item.icon_image?.filename}/>
                                                            </div>
                                                        )
                                                  ): (
                                                        <div className="flex-center py-1">
                                                            <img className="square-icon" alt={item.icon_image.alt} src={item.icon_image?.filename} />
                                                        </div>
                                                )
                                            }
                                            <div className="col-text-cntr">
                                            <div className="square-card-title" style={{fontSize:"20px"}}>{item.button_text}</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        ):(
                        <>
                            {/* button_style currently primary */}
                            <div  className={` row  justify-content-center align-content-center align-item-center ${item.button_style}`}>
                                {(item.link_target === "_self" || item.link_target === "") ? <Link to={item.link_url}>{item.button_text}</Link> : 
                                        (item.link_target === "_Phone") ? (  <a href={item.link_url} target="_self">{item.button_text}</a>):(
                                        <a href={item.link_url} target={item.link_target}>{item.button_text}</a>
                                )}
                            </div>
                            {item.help_text !== "" ? (
                                <> 
                                        <div  className="row justify-content-center py-2 help-text" style={{width: "-webkit-fill-available"}}>
                                            {item.help_text}<span className="help-icon" data-tip data-for={`registerTip${item._uid}`}></span>
                                            <ReactTooltip id={`registerTip${item._uid}`} place="top" className="tooltip" type="light" effect="solid">
                                                       <div className="pt-2">
                                                        {
                                                             item?.claim_hours ? <ClaimBusinessHoursGlobal /> : item?.policy_hours ? <PolicyHoursOfOperationGlobal /> : item?.report_hours ? <ReportHoursOfOperationGlobal /> : <HoursOfOperationGlobal />
                                                        }
                                                       </div>
                                                </ReactTooltip>
                                        </div>
                                </>
                           ):(<div style={{height:"1px"}}></div>)}
                        </>)
                    ): item.component === "LinkButton" ? (
                            <LinkButton data={item} config={item.button_style} style={config}/>

                    ): item.component === "Download_Button" && (
                            <DownloadButton data={item} config={config} />                      
                    )
                    )
                })}
       
        </>
     )

}
 
export default Buttons 

