import React, { useRef, useState } from "react";
import RichText from "./Core/RichText";


function Accordion(props) {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");


  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }

  return (
    <>
    <div className="FAQbody">
      <div className={`accordion ${active ? "active" : ""}`} onClick={toggleAccordion}>
        <p className="FAQquestion">{props.faq.Question}</p>
        <span className="FAQ-Icon">{active ? "-" : "+"}</span>
      </div>
      <div ref={content} style={{ maxHeight: `${height}` }} className="accordion__content" >
        <div className="FAQanswer">
            <RichText Description={props.faq.Answer} />
        </div>
      </div>
    </div>
    <br/>
    </>
  );
}

export default Accordion;
