import { storyblokEditable,StoryblokComponent } from "@storyblok/react";


export default function ButtonSectionSB({blok}) {
  return (
    <div className={`${blok.background_color}`}>
            <div className="flex-center" style={{padding:"1.5% 0px 0px 0px"}}>
                    {blok.content_header ? blok.content_header.map((nestedBlok) => (
                        <div key={nestedBlok._uid}>
                            <StoryblokComponent blok={nestedBlok} /><br/>
                        </div>
                        ))
                            : null
                    }
            </div>
            <div className={`flex-center gap-5 mobile-gap-none `} {...storyblokEditable(blok)}>
            <div className={`flex-center wrap-button-cols pb-4 px-4 ${blok.column_1.length > 2 || blok.column_2 > 2 ? "align-items-center" : "align-items-start"}`} style={{width:"1100px"}} >
                <div className='flex-basis-5-full '> 
                <div className="flex-center"style={{color:"black", fontFamily:"Poppins-Semi-Bold",fontSize:"20px"}}>{blok.heading_1}</div>
                    {blok.column_1 ? blok.column_1.map((nestedBlok) => (
                        <div key={nestedBlok._uid} style={{padding:"1px 0px"}}>
                            <StoryblokComponent blok={nestedBlok} />
                        </div>
                        ))
                            : null
                    }
                </div>
                <span className='break-btn-group'></span>
                <div className='flex-basis-5-full'>
                    <div className="flex-center"style={{color:"black", fontFamily:"Poppins-Semi-Bold",fontSize:"20px"}}>{blok.heading_2}</div>
                    {blok.column_2 ? blok.column_2.map((nestedBlok) => (
                        <div key={nestedBlok._uid}>
                            <StoryblokComponent blok={nestedBlok} />
                        </div>
                        ))
                            : null
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
