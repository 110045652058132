import { useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {NewVehicleModal} from "../../../../Data/RequestQuoteInput"
import validateInfo from '../../Validation/DriverModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { limit } from '../../../../Helpers/InputHelper';

const VehicleModal = ({stateForm,idx,input,setState,setConfirm,setAddVehicles,handleVehicleChange,action}) => {
    const [Attempt,setAttempt] = useState(false);
    const [formErrors, setFormErrors] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalShow, setModalShow] = useState(true);
    const handleClose = () => setModalShow(false);
    

    useEffect(() => {
      if (formErrors.length === 0 && isSubmitting === true) {
        setConfirm(true);
        close();
     }
   },[formErrors, isSubmitting]);
    useEffect(() => {
        if(Attempt === true){
           setFormErrors(validateInfo(stateForm));
          if(formErrors > 1){
            (setIsSubmitting(false))
           }
        }
      },[Attempt])
    const close = () => {
      action();
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setAttempt(!Attempt)
        setIsSubmitting(true); 
    }
    const pickValue = (val) => {
      if(val === "Year"){
      return  input.Year
      }if(val === "Model"){
        return input.Model
      }if(val === "Make"){
        return input.Make
      }
      return ("Issue with identifying value for new vehicles LN:62")
    }
   
    return (

    <>
        <Modal show={modalShow} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
          centered dialogClassName="border-radius-2 col-lg-6 pr-5" key={idx} autoComplete="off">
          <div className="row m-3 ml-4">
              <div className="col-lg-11 col-10 modal-title">
                {`Add Vehicle ${idx + 1}`}
              </div> 
              <button  onClick={action} style={{width:"auto"}} className=" mt-1  modal-x"><FontAwesomeIcon icon={faXmark}/></button>
          </div><span className="header-divider"></span>
          <form autoComplete="off" autoCapitalize="on">
              <div className='flex-center wrap'>
                  <div className="col-lg-10 mr-2 pt-3">
                          {NewVehicleModal.map((item) => {
                            return(
                              <>
                                  <div>{item.label}<span className="required-asterisk"></span></div>
                                  <input name={item.label}
                                    maxLength="256"
                                    id={item.required} className="form-input"
                                    type={item.type} value={pickValue(item.label)}
                                    onChange={e => handleVehicleChange(idx,e)} 
                                    onKeyUp={item.type === "number" && ((e) => limit(e,4))}
                                    onKeyDown={item.type === "number" && ((e) => limit(e,3))}
                                  /><br/><br/>
                              </>
                            )
                          })}
                    </div>
                </div>
            </form>
           <div className="row pt-2 pb-4 pl-5 pr-5 justify-content-center ">
                  <button onClick={(e) => handleSubmit(e)} className=" col-lg-7 justify-content-center tealBtn">{`Confirm Vehicle ${idx + 1} Information`}</button>
           </div>
      </Modal> 
  </>
    );
  };
export default VehicleModal;