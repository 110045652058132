import React from "react"
import HoursOfOperation from "./HoursOfOperationCard";
import ReactTooltip from "react-tooltip";
import RichText from "../Core/RichText";
const RichCard = ({data,iconSize,LengthTitle}) => {

    let Description = data.card_content;

    return ( 
        <>         
            <div className={`pl-4 pr-4 ${data.Content_Alignment} wrap`}>
       
                 <div className="flex">
                    <div>
                        <img src={data.icon_image.filename} className={iconSize} alt="Icon"/>
                    </div>
                    {iconSize === "rich-card-icon-sm" ? (<div className="rich-card-title-sm">{data.title_text}</div>) : (<div className={`rich-card-title-md ${LengthTitle}`}>{data.title_text}</div>)}
                 </div>
                <div className="py-4">
                    <div className="pb-3 Description" style={{textAlign:"left"}}>
                        <RichText Description={Description} />
                    </div>
                    {data.button_text !== "" &&(
                        <div className={` ${data.Content_Alignment}`}>
                            <a href={data.link_url} target={data.link_target} className={data.button_style}>{data.button_text}</a>
                        </div>
                    )}
                    {data.help_text !== "" && (
                        <> 
                            <div   className="row col-lg-12 justify-content-center py-2">
                                <div className="help-text">{data.help_text}</div><span data-tip data-for={`registerTip${data._uid}`}  data-tooltip-id={`registerTip${data._uid}`} className="px-1 help-icon" />
                                <ReactTooltip id={`registerTip${data._uid}`}  place="top" className="tooltip"  type="light" effect="solid">
                                       <div className="pt-2">
                                          {data.help_content.content.map((obj) => {
                                              return  <HoursOfOperation data={obj.attrs.body}/>
                                          })}
                                       </div>
                                   </ReactTooltip>
                            </div>
                        </>
                    )}
                </div>     
            </div>   
        </> 
    )
}
export default RichCard;



