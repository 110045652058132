import { useEffect, useState } from "react";
import validate from "../Validation/FormValidation";
import styled from "styled-components";
import PolicyHolderCompany from "../../../Data/PolicyHolderCompany";
import PreferredMethod from "../../../Data/PreferredMethod";
import states from "../../../Data/US-states";
import { limit } from "../../../Helpers/InputHelper";

const FileClaimForm = (props) => {
    const [FormErrors, setFormErrors] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [Attempt, setAttempt] = useState(false);

    useEffect(() => {
        if (FormErrors === "Ready" && isSubmitting) {
            props.nextStep()
            window.scrollTo(0, 0);
            setAttempt(false);
        }
    }, [FormErrors, isSubmitting]);
    useEffect(() => {
        if (Attempt === true) {
            setFormErrors(validate(props.claim))
            if (FormErrors >= 1 || FormErrors === "not ready") {
                setIsSubmitting(false);
                let erroed = document.querySelectorAll("[style*='background-color: white; border: 3px solid rgb(178, 39, 39);']")
                //erroed[0]?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            } else if (FormErrors <= 0) {
                setFormErrors("Ready");
                setAttempt(false);
            }
        }
    })

    const handleSubmit = e => {
        e.preventDefault();
        setAttempt(true);
        setIsSubmitting(true);
        setFormErrors(validate(props.claim))
        if (FormErrors === 0) {
            setFormErrors("Ready");
            setIsSubmitting(true);
            setAttempt(false);
        }
    };
    const handleInput = (e) => {
        if (e.key !== 'Backspace' && (e.target.value.length === 3 || e.target.value.length === 7)) {
            e.target.value += '-';
        }
    };
    return (
        <>
            <div className="row">
                <PageTitle className="col-lg-10 col-md-12 col-sm-12 offset-lg-1 " >
                    Submit Your Personal Auto Claim or Commercial Auto Claim Below.
                </PageTitle>
            </div>
            <PageDescription >
                <div className="flex-wrap">
                    Fill out as much of the online accident form below when you submit. This will help one of our local claims representatives when they reach out to you to review your claim faster. Claims are for Texas auto and commercial auto insurance policies only and underwritten by one of the following carriers: First Chicago Insurance Company, Homestate County Mutual Insurance Company or Old American County Mutual Insurance Company.
                </div>
            </PageDescription>
            <form name="ClaimForm" noValidate autoComplete="off">
                <EntireBody >
                    <div className=" py-4 justify-content-center ">
                        <div className="form-title">General</div>
                    </div>
                    <FormSection>
                        <div>
                            <FormCategory className="py-2">Person Submitting Auto Claim</FormCategory>
                            <div className="required-box">
                                <div className="required-txt required-asterisk ">Required</div>
                            </div><br />
                        </div>
                        <div className="row break-mobile ">
                            <div className="col-lg-6 pb-4">First Name<span className={((props.claim.FilerFirstName !== '') || (props.claim.FilerLastName !== '')) && `required-asterisk`} /><br />
                                <input className="form-input" id={((props.claim.FilerFirstName.length > 0) || (props.claim.FilerLastName.length > 0)) ? "Yes" : "No"} onChange={props.handleChange} type="text" maxLength="256" name="FilerFirstName" value={props.claim.FilerFirstName} />
                            </div>
                            <div className="col-lg-6 pb-4">Last Name<span className={((props.claim.FilerFirstName !== '') || (props.claim.FilerLastName !== '')) && `required-asterisk`} /><br />
                                <input className="form-input" id={((props.claim.FilerFirstName.length > 0) || (props.claim.FilerLastName.length > 0)) ? "Yes" : "No"} onChange={props.handleChange} type="text" maxLength="256" name="FilerLastName" value={props.claim.FilerLastName} />
                            </div>
                            <div className="col-lg-6 pb-4">Company<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="FilerCompany" />
                            </div>
                            <div className="col-lg-6 pb-4">Cell Phone<span className={((props.claim.FilerFirstName !== '') || (props.claim.FilerLastName !== '') || (props.claim.SMSconsent === "YES")) && `required-asterisk`} /><br />
                                <input className="form-input" value={props.claim.FilerPhoneNumber} onChange={props.handleChange} id={((props.claim.FilerFirstName.length > 0) || (props.claim.FilerLastName.length > 0 || (props.claim.SMSconsent === "YES"))) ? "Yes" : "No"} type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="FilerPhoneNumber" />
                            </div>
                            <div className="col-lg-6 pb-4">Email Address<span className={((props.claim.FilerFirstName !== '') || (props.claim.FilerLastName !== '')) && `required-asterisk`} /><br />
                                <input className="form-input" id={((props.claim.FilerFirstName.length > 0) || (props.claim.FilerLastName.length > 0)) ? "Yes" : "No"} onChange={props.handleChange} type="text" maxLength="256" name="FilerEmailAddress" value={props.claim.FilerEmailAddress} />
                            </div>
                            <div className="col-lg-6">
                                <div style={{display:"flex"}}>
                                    <div className="col-auto">
                                        {
                                            props.claim.SMSconsent !== null && props.claim.SMSconsent === "YES" ?
                                                (
                                                    <input id="YESsms" type="radio" value="YES" name="SMSconsent" onClick={props.handleChange} checked></input>
                                                ) : (
                                                    <input id="YESsms" type="radio" value="YES" name="SMSconsent" onClick={props.handleChange}></input>
                                            )
                                        }
                                   </div>
                                    <div className="col-auto">
                                        <b>Yes</b>, I consent to having Lonestar MGA contact me about my claim via SMS/text messaging.
                                    </div>
                                </div>
                                <div style={{display:"flex", paddingTop:"5px"}}>
                                    <div className="col-auto">
                                        {
                                            props.claim.SMSconsent !== null && props.claim.SMSconsent === "NO" ? (
                                                <input id="NOsms" type="radio" value="NO" name="SMSconsent" onClick={props.handleChange} checked></input>
                                            ) : (
                                                <input id="NOsms" type="radio" value="NO" name="SMSconsent" onClick={props.handleChange}></input>
                                            )
                                        }
                                    </div>
                                    <div className="col-auto">
                                        <b>No</b>, I DO NOT consent to having Lonestar MGA about my claim to contact me via SMS/text messaging.
                                    </div>
                                </div>
                                {
                                    ((props.claim.FilerPhoneNumber.length > 0 && props.claim.SMSconsent && Attempt === true) || (props.claim.SMSconsent === "YES" && Attempt === true && props.claim.FilerPhoneNumber.length <= 0) || (props.claim.SMSconsent === 'null' && Attempt === true))&& (
                                        <span className="text-danger">Must select and option before moving on</span>
                                    )
                                }   
                            </div>
                        </div>
                        <div className="row break-mobile" ><br /><br />
                            <div className="col-lg-12 form-category">Policy Holder</div>
                            <div className="col-lg-6 pb-4">Policy Number<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" value={props.claim.PolicyNumber} onChange={props.handleChange} type="text" maxLength="256" name="PolicyNumber" />
                            </div>
                            <div className="col-lg-6 pb-4">Policy Company<span className="required-asterisk" /><br />
                                <select className={`form-input pl-1`} value={props.claim.PolicyHolderCompany} onChange={props.handleChange} id="YesSelectCompany" name="PolicyHolderCompany">
                                    {PolicyHolderCompany.map(items => (
                                        <option key={items.index} value={items.label}>{items.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-6 pb-4">First Name<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" value={props.claim.PolicyHolderFirstName} name="PolicyHolderFirstName" />
                            </div>
                            <div className="col-lg-6 pb-4">Last Name<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" value={props.claim.PolicyHolderLastName} name="PolicyHolderLastName" />
                            </div>
                            <div className="col-lg-6 pb-4">Address<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" value={props.claim.PolicyHolderAddress} name="PolicyHolderAddress" />
                            </div>
                            <div className="col-lg-6 pb-4">City<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="PolicyHolderCity" value={props.claim.PolicyHolderCity} />
                            </div>
                            <div className="col-lg-6 pb-4">State<br />
                                <select className="form-input" value={props.claim.PolicyHolderState} onChange={props.handleChange} id="No" name="PolicyHolderState">
                                    {states.map(items => (
                                        <option key={items.index} value={items.label}>{items.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-6 pb-4">ZIP Code<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="number" onKeyUp={((e) => limit(e, 5))} onKeyDown={((e) => limit(e, 4))} maxLength="5" name="PolicyHolderZIPCode" value={props.claim.PolicyHolderZIPCode} />
                            </div>
                            <div className="col-lg-6 pb-4">Preffered Contact Method<br />
                                <select className="form-input" value={props.claim.PolicyHolderMethodContact} onChange={props.handleChange} id="Yes" name="PolicyHolderMethodContact">
                                    {PreferredMethod.map(items => (
                                        <option key={items.index} value={items.label}>{items.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-6 pb-4">Home Phone<span className={(props.claim.PolicyHolderMethodContact === "PolicyHolderHomePhone") && `required-asterisk`} /><br />
                                <input className="form-input" value={props.claim.PolicyHolderHomePhone} onChange={props.handleChange} id={(props.claim.PolicyHolderMethodContact === "PolicyHolderHomePhone") ? "Yes" : "No"} type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="PolicyHolderHomePhone" />
                            </div>
                            <div className="col-lg-6 pb-4">Cell Phone<span className={(props.claim.PolicyHolderMethodContact === "PolicyHolderCellPhone") && `required-asterisk`} /><br />
                                <input className="form-input" value={props.claim.PolicyHolderCellPhone} onChange={props.handleChange} id={(props.claim.PolicyHolderMethodContact === "PolicyHolderCellPhone") ? "Yes" : "No"} type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="PolicyHolderCellPhone" />
                            </div>
                            <div className="col-lg-6 pb-4">Business Phone<span className={(props.claim.PolicyHolderMethodContact === "PolicyHolderBusinessPhone") && `required-asterisk`} /><br />
                                <input className="form-input" value={props.claim.PolicyHolderBusinessPhone} onChange={props.handleChange} id={(props.claim.PolicyHolderMethodContact === "PolicyHolderCellPhone") ? "Yes" : "No"} type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="PolicyHolderBusinessPhone" />
                            </div>
                            <div className="col-lg-6 pb-4">Email Address<span className={(props.claim.PolicyHolderMethodContact === "PolicyHolderEmailAddress") && `required-asterisk`} /><br />
                                <input className="form-input" id={(props.claim.PolicyHolderMethodContact === "PolicyHolderEmailAddress") ? "Yes" : "No"} onChange={props.handleChange} type="text" maxLength="256" name="PolicyHolderEmailAddress" />
                            </div>
                        </div>
                    </FormSection><br />
                    <div className="py-4 justify-content-center" >
                        <div className="form-title"> Drivers </div>
                    </div>
                    <FormSection>
                        <div className="row break-mobile ">
                            <div className="col-lg-12 form-category">Insured Driver</div><br />
                            <div className="col-lg-6 pb-4">First Name<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" name="DriverFirstName" value={props.claim.DriverFirstName} />
                            </div>
                            <div className="col-lg-6 pb-4">Last Name<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" name="DriverLastName" value={props.claim.DriverLastName} />
                            </div>
                            <div className="col-lg-6 pb-4">Address<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" name="DriverAddress" value={props.claim.DriverAddress} />
                            </div>
                            <div className="col-lg-6 pb-4">City<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" name="DriverCity" value={props.claim.DriverCity} />
                            </div>
                            <div className="col-lg-6 pb-4">State<br />
                                <select className="form-input" value={props.claim.DriverState} onChange={props.handleChange} id="No" name="DriverState">
                                    {states.map(items => (
                                        <option key={items.index} value={items.label}>{items.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-6 pb-4">ZIP Code<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="number" onKeyUp={((e) => limit(e, 5))} onKeyDown={((e) => limit(e, 4))} maxLength="5" name="DriverZIPCode" value={props.claim.DriverZIPCode} />
                            </div>
                            <div className="col-lg-6 pb-4">Preffered Contact Method<br />
                                <select className="form-input" value={props.claim.DriverMethodContact} onChange={props.handleChange} id="Yes" name="DriverMethodContact">
                                    {PreferredMethod.map(items => (
                                        <option key={items.index} value={items.label}>{items.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-6 pb-4">Home Phone<span className={(props.claim.DriverMethodContact === "DriverHomePhone") && `required-asterisk`} /><br />
                                <input className="form-input" value={props.claim.DriverHomePhone} onChange={props.handleChange} id={(props.claim.DriverMethodContact === "DriverHomePhone") ? "Yes" : "No"} type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="DriverHomePhone" />
                            </div>
                            <div className="col-lg-6 pb-4">Cell Phone<span className={(props.claim.DriverMethodContact === "DriverCellPhone") && `required-asterisk`} /><br />
                                <input className="form-input" value={props.claim.DriverCellPhone} onChange={props.handleChange} id={(props.claim.DriverMethodContact === "DriverCellPhone") ? "Yes" : "No"} type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="DriverCellPhone" />
                            </div>
                            <div className="col-lg-6 pb-4">Business Phone<span className={(props.claim.DriverMethodContact === "DriverBusinessPhone") && `required-asterisk`} /><br />
                                <input className="form-input" value={props.claim.DriverBusinessPhone} onChange={props.handleChange} id={(props.claim.DriverMethodContact === "DriverBusinessPhone") ? "Yes" : "No"} type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="DriverBusinessPhone" />
                            </div>
                            <div className="col-lg-6 pb-4">Email Address<span className={(props.claim.DriverMethodContact === "DriverEmailAddress") && `required-asterisk`} /><br />
                                <input className="form-input" id={(props.claim.DriverMethodContact === "DriverEmailAddress") ? "Yes" : "No"} onChange={props.handleChange} type="text" maxLength="256" name="DriverEmailAddress" />
                            </div>
                        </div>
                    </FormSection><br /><br />
                    <FormSection>
                        <div className="row break-mobile ">
                            <div className="col-lg-12 form-category">Other Driver</div><br />
                            <div className="col-lg-6 pb-4">First Name<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="OtherDriverFirstName" value={props.claim.OtherDriverFirstName} />
                            </div>
                            <div className="col-lg-6 pb-4">Last Name<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="OtherDriverLastName" value={props.claim.OtherDriverLastName} />
                            </div>
                            <div className="col-lg-6 pb-4">Address<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="OtherDriverAddress" value={props.claim.OtherDriverAddress} />
                            </div>
                            <div className="col-lg-6 pb-4">City<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="OtherDriverCity" value={props.claim.OtherDriverCity} />
                            </div>
                            <div className="col-lg-6 pb-4">State<br />
                                <select className="form-input" value={props.claim.OtherDriverState} onChange={props.handleChange} id="No" name="OtherDriverState">
                                    {states.map(items => (
                                        <option key={items.index} value={items.label}>{items.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-6 pb-4">ZIP Code<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="number" maxLength="5" onKeyUp={((e) => limit(e, 5))} onKeyDown={((e) => limit(e, 4))} name="OtherDriverZIPCode" />
                            </div>
                            <div className="col-lg-6 pb-4">Home Phone<br />
                                <input className="form-input" value={props.claim.OtherDriverHomePhone} onChange={props.handleChange} id="No" type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="OtherDriverHomePhone" />
                            </div>
                            <div className="col-lg-6 pb-4">Cell Phone<br />
                                <input className="form-input" value={props.claim.OtherDriverCellPhone} onChange={props.handleChange} id="No" type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="OtherDriverCellPhone" />
                            </div>
                            <div className="col-lg-6 pb-4">Business Phone<br />
                                <input className="form-input" value={props.claim.OtherDriverBusinessPhone} onChange={props.handleChange} id="No" type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" name="OtherDriverBusinessPhone" />
                            </div>
                        </div>
                    </FormSection><br />
                    <div className="py-4 justify-content-center "><div className="form-title">Vehicles</div></div>
                    <FormSection>
                        <div className="row break-mobile">
                            <div className="col-lg-12 form-category">Insured Vehicle</div><br />
                            <div className="col-lg-4">Year<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} onKeyUp={((e) => limit(e, 4))} onKeyDown={((e) => limit(e, 3))} type="number" name="InsuredVehicleYear" value={props.claim.InsuredVehicleYear} />
                            </div>
                            <div className="col-lg-4">Make<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" name="InsuredVehicleMake" value={props.claim.InsuredVehicleMake} />
                            </div>
                            <div className="col-lg-4">Model<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" name="InsuredVehicleModel" value={props.claim.InsuredVehicleModel} />
                            </div>
                        </div><br />
                        <div className="row break-mobile">
                            <div className="col-lg-12 claim-txt-area">Damage to Vehicle<span className="required-asterisk" /><br />
                                <textarea className="form-input claim-txt-area needed" id="Yes" onChange={props.handleChange} maxLength="500" name="InsuredVehicleMsg" value={props.claim.InsuredVehicleMsg} />
                            </div>
                        </div>
                    </FormSection><br /><br />
                    <FormSection>
                        <div className="row break-mobile">
                            <div className="col-lg-12 form-category">Other Vehicle</div><br />
                            <div className="col-lg-4">Year<br />
                                <input className="form-input" id="No" onChange={props.handleChange} onKeyUp={((e) => limit(e, 4))} onKeyDown={((e) => limit(e, 3))} type="number" name="OtherVehicleYear" value={props.claim.OtherVehicleYear} />
                            </div>
                            <div className="col-lg-4">Make<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="OtherVehicleMake" value={props.claim.OtherVehicleMake} />
                            </div>
                            <div className="col-lg-4">Model<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="OtherVehicleModel" value={props.claim.OtherVehicleModel} />
                            </div>
                        </div><br />
                        <div className="row break-mobile">
                            <div className="col-lg-6">Damage to Vehicle<br />
                                <textarea className="form-input claim-txt-area" id="No" onChange={props.handleChange} maxLength="256" name="OtherVehicleMsg" value={props.claim.OtherVehicleMsg} />
                            </div>
                            <div className="col-lg-6">
                                <div>Is this Vehicle Drivable</div>
                                <div className="row col-lg-12">
                                    <input type="button" className={`mb-4 col-5 mr-4 contactBtn ${(props.claim.OtherVehicleDrivable === "Yes") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="OtherVehicleDrivable" id="yes" value={`Yes`} />
                                    <input type="button" className={`mb-4 col-5  contactBtn ${(props.claim.OtherVehicleDrivable === "No") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="OtherVehicleDrivable" id="no" value={`No`} />
                                </div>
                            </div>
                        </div><br />
                        <div className="row break-mobile">
                            <div className="col-lg-6">Insurance Company Name<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="OtherVehicleCompanyName" value={props.claim.OtherVehicleCompanyName} />
                            </div>
                            <div className="col-lg-6">Policy Number<br />
                                <input className="form-input" id="No" onChange={props.handleChange} type="text" maxLength="256" name="OtherVehiclePolicyNumber" value={props.claim.OtherVehiclePolicyNumber} />
                            </div>
                        </div>
                    </FormSection><br />
                    <div className=" py-4 justify-content-center ">
                        <div className="form-title">Accident and Injuries</div>
                    </div>
                    <FormSection>
                        <div className="row break-mobile">
                            <div className="col-lg-12 form-category">Accident</div><br />
                            <div className="col-lg-6">Date of Accident<span className="required-asterisk" /><br />
                                <input max={new Date().toISOString().split('T')[0]} value={props.claim.DateOfAccident} className="form-input-date pl-1" id="Yes" onChange={props.handleChange} type="date" name="DateOfAccident" />
                            </div>
                            <div className="col-lg-6">Time of Accident<span className="required-asterisk" /><br />
                                <input value={props.claim.TimeOfAccident} className="form-input" id="Yes" onChange={props.handleChange} type="time" name="TimeOfAccident" />
                            </div>
                        </div><br />
                        <div className="row break-mobile">
                            <div className="col-lg-6">Location of Accident<span className="required-asterisk" /><br />
                                <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" name="LocationOfAccident" value={props.claim.LocationOfAccident} />
                            </div>
                            <div className="col-lg-6">How did the accident happen?<span className="required-asterisk" /><br />
                                <textarea className="form-input claim-txt-area needed" id="Yes" onChange={props.handleChange} maxLength="500" name="AccidentMsg" value={props.claim.AccidentMsg} />
                            </div>
                        </div><br />
                        <div className="row break-mobile">
                            <div className="col-lg-6">
                                <div>Is the vehicle drivable?</div>
                                <div className="row ml-0 mt-3">
                                    <input type="button" className={`mb-4 col-5 mr-4 justify-content-center contactBtn ${(props.claim.VehicleDrivable === "Yes") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="VehicleDrivable" id="" value={`Yes`} />
                                    <input type="button" className={`mb-4 col-5  justify-content-center contactBtn ${(props.claim.VehicleDrivable === "No") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="VehicleDrivable" id="" value={`No`} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div>Was your vehicle stolen?</div>
                                <div className="ml-0 row mt-3">
                                    <input type="button" className={`mb-4 col-5 mr-4 justify-content-center contactBtn ${(props.claim.VehicleStolen === "Yes") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="VehicleStolen" id="" value={`Yes`} />
                                    <input type="button" className={`mb-4 col-5  justify-content-center contactBtn ${(props.claim.VehicleStolen === "No") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="VehicleStolen" id="" value={`No`} />
                                    <br />
                                </div>
                            </div>
                        </div><br />
                        <div className="row break-mobile">
                            <div className="col-lg-6 pt-4">
                                <div className="required-asterisk">Was the theft or accident reported to the Police?</div>
                                <div className="ml-0  row mt-3">
                                    <input type="button" className={`mb-4 col-5 mr-4 justify-content-center btnYesPolice contactBtn ${(props.claim.TheftWasReported === "Yes") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="TheftWasReported" id="yes" value={`Yes`} />
                                    <input type="button" className={`mb-4 col-5  justify-content-center btnYesPolice contactBtn ${(props.claim.TheftWasReported === "No") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="TheftWasReported" id="no" value={`No`} />
                                    <br />
                                </div>
                            </div>
                        </div><br />
                        <div className="row ">
                            {props.claim.TheftWasReported === "Yes" &&
                                <>
                                    <div className="col-lg-6">Which Police Department<span className="required-asterisk" /><br />
                                    <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" name="PoliceDepartment" value={props.claim.PoliceDepartment} />
                                    </div>
                                    <div className="col-lg-6">Police Report Number<span className="required-asterisk" /><br />
                                    <input className="form-input" id="Yes" onChange={props.handleChange} type="text" maxLength="256" name="PoliceReportNumber" value={props.claim.PoliceReportNumber} />
                                    </div>
                                </>}
                        </div>
                        <div className="row break-mobile">
                            <div className="col-lg-12 form-category">Injuries</div>
                            <div className="col-lg-6 pt-4">
                                <div className="required-asterisk">Were there any injuries?</div>
                                <div className="ml-0 justify-content-start row mt-3">
                                    <input type="button" className={`mb-4 col-5 mr-4 justify-content-center btnYes2 contactBtn ${(props.claim.Injuries === "Yes") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="Injuries" id="yes" value={`Yes`} />
                                    <input type="button" className={`mb-4 col-5  justify-content-center btnYes2 contactBtn ${(props.claim.Injuries === "No") ? 'onBtn' : 'offBtn'}`} onClick={props.handleChange} name="Injuries" id="no" value={`No`} />
                                </div>
                            </div>
                        </div>
                    </FormSection> <br />
                </EntireBody>
                {(Attempt && Error != 0) && <div className="row justify-content-center"><div className="col-lg-6"></div><div className="text-danger col-lg-6 " style={{textAlign:"center"} }>Please fill out all required fields.</div></div>}
                <div className="flex-center align-items-center gap-5 py-4" >
                    <div className="flex-basis-5 flex-end ">1 of 2</div>
                    <div onClick={handleSubmit} className="flex-basis-5 black-sm-pill-btn" style={{ height: "62px", maxWidth: "495px" }}>Next</div>
                </div>
            </form>
        </>
    );
}

export default FileClaimForm;

const EntireBody = styled.div`
    @media only screen and (max-width:1100px){
        padding:0% 12% 0% 3%;
    }
    @media only screen and (max-width: 1000px){
        padding:0% 10% 0% 12%;
    }
    @media only screen and (max-width:800px){
        padding:0% 0% 0% 0%;
    }
`;
const FormSection = styled.div`
    display:block;
    align-content:center;
    align-items:center;
    max-width:1107px;
    border-radius: 40px;
    background-color: #FFFFFF;
    padding:5% 7% 5% 7%;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.23);
    @media only screen and (max-width:800px){
        padding:5%;
        min-height: 630px;
    }
`;

const FormCategory = styled.div`
        min-height: 33px;
        width:100%;
        color: #222222;
        font-family: 'Poppins-Bold';
        font-size: 140%;
        letter-spacing: 0;
        line-height: 30px;
`;

const PageTitle = styled.div`
    line-height:52px;
    color:#222222;
    font-family:"Poppins-Regular";
    font-size:30px;
    text-align:center;
    @media only screen and (max-width:800px){
        font-size:18px;
        padding:0% 10%;
        line-height:32px;
        font-family:"Poppins-semi-bold";
    }

`;
const PageDescription = styled.div`
    padding:1% 0% 0px 0%;
    max-width:1074px;
    flex-wrap:wrap;
    @media only screen and (max-width:800px){
        padding:0% 1%;
    }
`;