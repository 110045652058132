import HoursOfOperation from "../Cards/HoursOfOperationCard";
import ReactTooltip from "react-tooltip";
import ClaimBusinessHoursGlobal from "./ClaimBusinessHoursGlobal";
import HoursOfOperationGlobal from "./HoursOfOperationGlobal";
import PolicyHoursOfOperationGlobal from "./PolicyServiceHourofOperation";
import ReportHoursOfOperationGlobal from "./ReportNewClaimHours";

const NewPhoneLine = ({ data }) => {

    return (
        <>
            <div className="row col-lg-12 offset-1">
                <div className="col-auto">
                    <div className="row" style={{ fontFamily: "Poppins-Bold" }}>
                        {data.action_verb}
                    </div>
                    <div className="row">
                        <a style={{ textDecoration: "underline" }} href={`tel:${data.phone_number}`}>{data.phone_number}</a>
                    </div>
                </div>
                <div className="col-lg-6 py-1 " >
                    {data.help_text !== "" && (
                        <>
                            <div className="row justify-content-center  align-items-center py-2 help-text">
                                <div>{data.help_text}</div><span className="help-icon" alt="Icon" data-tip data-for={`registerTip${data._uid}`} src={data.help_icon?.filename} />
                                <ReactTooltip id={`registerTip${data._uid}`} className="tooltip" place="top" type="light" effect="solid">
                                    <div className="pt-2">
                                        {
                                            data?.claim_hours ? <ClaimBusinessHoursGlobal /> : data?.policy_hours === true ? <PolicyHoursOfOperationGlobal /> : data.report_hours ? <ReportHoursOfOperationGlobal /> : <HoursOfOperationGlobal />
                                        }
                                    </div>
                                </ReactTooltip>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default NewPhoneLine;