import React from 'react';
import styled from "styled-components";
import RichText from '../Core/RichText';

const UnOrderedItem = ({blok}) => {
    return ( 
        <>  
            <div className='flex-center '>
                    <div className="">
                        {blok.icon_image.filename !== "" ? (
                            <img style={{width:"65px", paddingRight:"10px"}} alt="icon" src={blok.icon_image.filename}/>
                            ): (
                                <>
                                    <div style={{width:"65px"}}></div>
                                </>
                            )
                        }
                    </div>
                    <div className="col-flex" style={{width:"100%"}}>                              
                            <HeadingUI>{blok.list_text}</HeadingUI>
                            <div className='flex-wrap' style={{Width:"100%"}}>
                            <RichText Description={blok.description} />
                            </div>
                    </div>
            </div>
        </>
     )

}

export default UnOrderedItem 

const HeadingUI = styled.div`
   font-size:20px;
   height: 100%;
   line-height:30px;
   width:100%;
   font-family: 'Poppins-Semi-Bold';
`;