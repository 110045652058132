const PreferredMethod = [
    {
        index:0,
        label:"- Select -",
        value:"null"
    },
    {
        index:1,
        label:"Cell Phone",
        value:"Cell Phone"
    },{
        index:2,
        label:"Home Phone",
        value:"Home Phone"
    },{
        index:3,   
        label:"Business Phone",
        value:"Business Phone"
    }
]
export default PreferredMethod;