import React, { useRef, useState } from "react"
import styled from "styled-components";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RichText from "./Core/RichText";

const ContentObject = ({data}) => {

    let Description = data.description;
    const [active, setActive] = useState(false);
    const content = useRef(null);
    const [height, setHeight] = useState("0px");

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }
    return ( 
        <>       
                {data.button_style === "expand-btn" ?(
                  <>
                      <div className="FAQbody">
                        <div className={`accordion ${active ? "active" : ""}`} onClick={toggleAccordion}>
                            <p className="FAQquestion" style={{paddingLeft:"5%",paddingRight:"5%"}}>{data.headline}</p><span>{active ? (<><FontAwesomeIcon icon={faChevronUp} /></>):(<><FontAwesomeIcon icon={faChevronDown} /></>)}</span>
                        </div>
                        <div ref={content} style={{ maxHeight: `${height}`, padding:"0 7% 0% 7%"}} className="accordion__content" >
                        <div className="FAQanswer "/>
                              <RichText Description ={Description}/>
                              <br/>
                        </div>
                        <a href={data.link_url} target={data.link_target}>{data.button_text}</a>
                      </div>
                  </>
                ):(
                  <>
                        <div className="p-4 " style={{width:"100%",height:"100%"}}>
                            <div className="content-object-heading">{data.headline}</div>
                            <div className="sub-heading-2 content-object-sub-heading">{data.subheadline}</div>
                            <DescriptionMO><RichText Description={Description} /></DescriptionMO>
                            <a style={{marginTop:"40px"}} className={data.button_style}  href={data.link_url} target={data.link_target}>{data.button_text}</a>
                        </div>
                  </>
                )}

        </> 
)
}
 
export default ContentObject;


const DescriptionMO = styled.div`
  p{font-size:100%;
    text-align: left;}
  font-family:"Poppins-Regular";
`;
