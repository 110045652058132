import RichText from "../Core/RichText";
import { useEffect, useState } from "react";


const ContentItemText = (props) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    return (

        <div className="contact-list-body">
            <div className="flex-start gap-1">
                <img className="content-list-main-icon" src={props.data.Icon.filename} alt={props.data.Icon.alt} />
                <div className="content-list-main-title">{props.data.Title}</div>
            </div><br />
            <div>
                {
                    props.data.Item.map((item) => {
                        return (
                            <>
                                <div className="content-list-item-body" key={item._uid}>
                                    <div className="col-lg-3">
                                        <div className="flex-center"><img className="content-list-icon" src={item.Icon.filename} alt={item.Icon.alt} /></div>
                                        <div className="content-list-text">{item.Title}</div>
                                    </div>
                                    {
                                        windowSize.innerWidth < 600 && (
                                            <div className="flex">
                                                <span className="divider"></span>
                                            </div>
                                        )
                                    }
                                    {
                                        item.Content.map((data, index) => {
                                            return (
                                                <div key={data._uid} className="flex-basis-3 content-list-text-item">
                                                    <div className="content-list-item-title py-1">{data.Title}</div>
                                                    <div>
                                                        <RichText Description={data.Description} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div><br />

                            </>)
                    })
                }
            </div>
        </div>
    )
}

export default ContentItemText