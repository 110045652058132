import React, { useState } from 'react';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import { RemoveInputData } from '../../../../Data/RequestQuoteInput';

const RemoveModal = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () =>{setShow(false)};
    const handleShow = (e) => {e.preventDefault(); setShow(true)};


    function removeCurrentInput(i,e){
        e.preventDefault();
        let removeInput = [...props.state];
        removeInput.splice(i,1);
        props.setState(removeInput);
       handleClose();
    }
    return ( <>
                {
                    RemoveInputData.map((data) => (
                        data.Show === props.show && 
                        <>
                            <button onClick={handleShow} style={{ width:"auto", backgroundColor:"transparent"}}><div className='trash-icon'/></button>
                          
                            <Modal
                            aria-labelledby="contained-modal-title-vcenter"
                            centered dialogClassName="col-lg-5 pl-2 pr-4  border-radius-2"
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}><br/>
                            <Header className='row py-4 justify-content-center'>{`${data.Header} ${props.id + 1}`}</Header>
                            <div className="pl-5 pr-5">
                            <Message className="col-lg-10 offset-lg-1 pl-4  justify-content-center pb-1">
                                    {data.Message}
                            </Message>
                            </div>
                            <div className="row justify-content-center py-4">
                                    <div className='pb-2 pl-2'>
                                            <input type="button" className="white-sm-pill-btn" onClick={handleClose} value={ `Wait, let's go back`} />
                                    </div>
                                    <div className="px-1 pl-3">
                                            <input type="button" className='primary-sm-pill-btn' onClick={e => removeCurrentInput(props.id,e)}   value={`Yes, Delete it`}  /> 
                                    </div>
                                </div><br/>
                            </Modal>
                        </>
                    ))
                }
                
            </> 
            
        );
}
 
export default RemoveModal;


const Header = styled.div `
    font-size:25px;
    font-family:'Poppins-Bold';
    line-height: 35px;
`;
const Message = styled.div `
    min-height: 70px;
    color: #222222;
    font-family: Poppins;
    font-size: 120%;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;
    
`;
 