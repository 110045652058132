import { useEffect, useState } from "react";
import CardCTA from "./Cards/CardCTA";
import { DBMessage } from "../utils/DBMessage";
import { useAuth } from "../utils/AuthProvider";

const Header = (props) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const auth = useAuth();

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const mobile = {
        backgroundSize: "100% 100%",
        backgroundPosition: "center ",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${props.data.hero_image[0].background_image_mobile?.filename})`
    }
  
    return (
        <>
            <div className={windowSize.innerWidth < 650 ? 'header-image-mobile' : 'header-image'}
                style={windowSize.innerWidth < 650 ?
                    (mobile)
                    : (props.data.hero_image[0].uses_overlay ? (
                        { background: `url(${props.data.hero_image[0].overlay_image.filename}) no-repeat bottom center , url(${props.data.hero_image[0].background_image.filename}) no-repeat top center`, backgroundSize: "auto, auto" })
                        : ({ background: `url(${props.data.hero_image[0].background_image.filename}) no-repeat top center` }))
                }>
                <div className="hero-text-body">
                    <h5 className="hero-subheadline">{props.data.hero_image[0].subheadline}</h5>
                    <h1 className="hero-headline">{props.data.hero_image[0].headline}</h1>
                </div>
                {
                    auth.NotificationMessage !== "" &&
                    <DBMessage messageHTML={auth.NotificationMessage} style={"alert-info"}/>
                }
                {
                    props.data.call_to_action_card.length !== 0 &&
                    (
                        <div className="offset-lg-7 offset-sm-5 cardCTA ">
                            <CardCTA data={props.data?.call_to_action_card} />
                        </div>
                    )
                }
            </div>
        </>
    )
}
export default Header