import React from "react"

const AddressLine = ({data}) => {
    
    return ( 
        <>
            <div className="row col-lg-12 offset-1 pb-4">
                <div className="col-lg-4">
                    <div className="row" style={{fontFamily:"Poppins-Bold"}}>
                        {data.action_verb}
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <div className="">
                          {data.address}  
                        </div>
                    </div>
                </div>
            </div>
        </> 
    )
}
 
export default AddressLine