export const PersonalInfoInput = [
    {
        label:"First Name",
        required:"Yes",
        inputStyle:"form-input-sm pl-2",
        style:"row col-lg-6 mr-3"
    },{
        label:"Last Name",
        required:"Yes",
        inputStyle:"form-input-sm pl-2",
        style:"row col-lg-6"
    }

]

export const NewDriverModal = [
    {
        label:"First Name",
        required:"Yes",
        inputStyle:"form-input-sm pl-2",
        type:"text"
    },{
        label:"Last Name",
        required:"Yes",
        inputStyle:"form-input-sm pl-2",
        type:"text"
    },{
        label:"Birthdate",
        required:"Yes",
        inputStyle:"form-input-lg pl-2",
        type:"date"
    },{
        Question:"Yes",
        label:"Has this Driver had any accidents in the last three years?"
    },{
        label:"Yes",
        type:"button",
        inputStyle:"col-5 justify-content-center contactBtn"
    },{
        label:"No",
        type:"button",
        inputStyle:"mb-4 col-5  justify-content-center contactBtn"
    }

]

export const NewVehicleModal =[
   
    {
        label:"Year",
        required:"YesCar",
        inputStyle:" needed form-input-sm pl-2",
        type:"number"

    },{
        label:"Make",
        required:"YesCar",
        inputStyle:" needed form-input-sm pl-2",
        type:"text"
    },{
        label:"Model",
        required:"YesCar",
        inputStyle:" needed form-input-sm pl-2",
        type:"text"
    }

]

export const RemoveInputData = [
    {
        Show:"Vehicle",
        Header:"Delete Vehicle",
        Message:"The information will be deleted for this vehicle and you can't undo this action."

    },
    {
        Show:"Driver",
        Header:"Delete Driver",
        Message:"The information will be deleted for this driver and you can't undo this action."
    }

]