export const FORM_INITIAL_STATE = {
    PolicyPrefix: 'CAL',
    PolicyNumber: '',
    PaymentAmount: 0,
    PaymentType: 'Commercial',
    LastName: 'null',
};
export const CALL_INITIAL_STATE = {
    NoRedirectMessage: false,
    ResponseMessage:''
};
/**
 * Purpose: Update the state and return the new version of the state
 * @param {any} state
 * @param {any} action
 */
export const formReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_INPUT":
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        default: return state;
    }
}

export const responseReducer = (state, action) => {

    switch (action.type) {
        case 'SUCCESS':
            return {
                ...state,
                NoRedirectMessage: true,
                ResponseMessage: action.payload
            };
        case 'ERROR':
            return {
                ...state,
                NoRedirectMessage: false,
                ResponseMessage: action.payload
            };
        default:
            return state;
    }
}