import { useStoryblok } from "@storyblok/react";

const Phone = () => {
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const slug = "lonestar/lonestar-phone-info";
    const story = useStoryblok(slug, {version: storyblokVersion});

    if(!story?.content){
        return <div></div>
    }

    return (
          (<a style={{fontFamily:"inherit", color:"inherit"}} href={`tel:${story.content.phone_number}`}>{ story.content.phone_number}</a>) 
    )

}
 
export default Phone 