import TextSection from "../Core/TextSection"
const MediaObjectItem = ({ blok }) => {

    let Headline = blok?.Heading;
    let SubHeadline = blok?.Sub_Heading;
    let Description = blok?.Description;
    let Image = blok?.Image?.filename;
    let Alt = blok.Image?.alt;
    let ImagePosition = blok?.Image_Position;
    let LinkTarget = blok?.link_target;

    return (
        <>
            <div className=" flex-center wrap align-items-center" >
                <div className="flex-wrap wrap align-items-center px-4" style={{ display: "flex" }}>
                    <div className="flex-center wrap" style={{ maxWidth: "1300px" }}>
                    {ImagePosition === "right" ? (
                        <>

                            <div className="col-lg-7 col-md-7 col-sm-12 align-items-center mo-text" style={{ display: "grid" }}>
                                <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} Story={blok}/>
                            </div>
                            <div className="col-lg-5 mo-image align-items-center">
                                <img src={Image} alt="Icon" className="mobile-MO-img " style={{ maxWidth: "350px", borderRadius: "30px", backgroundSize: "60%" }} />
                            </div>

                        </>
                    ) : ImagePosition === "top" ? (
                        <>
                            <div className="mobile-adjust grid">
                                <div className=" align-items-start py-2">
                                    <img src={Image} alt="Icon" className="mobile-MO-img " style={{ maxWidth: "350px", borderRadius: "30px", backgroundSize: "60%" }} />
                                </div>
                            </div>
                            <div>
                                <div className="flex-start ">
                                    <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} Story={blok}/>
                                </div>
                            </div>

                        </>
                    ) : ImagePosition === "bottom" ? (
                        <>
                            <div className="col-lg-6 col-md-7 col-sm-12 m-auto  mobile-spacing-cntr-m" style={{ paddingTop: "20px", paddingBottom: "0%" }}>
                                <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} Story={blok}/><br />
                                <div className="row justify-content-center media-object-background-section" >
                                    <div>
                                        <img src={Image} alt={Alt} className="media-object-img-bottom" />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : ImagePosition === "left" && (
                        <>
                            <div className="container-flex ">
                                <div className="col-flex align-items-center justify-content-center parent m-auto p-2" style={{ maxWidth: "500px", display: "grid" }}>
                                  
                                    {blok.Image.filename && (<img className="media-object-img image2" alt="Media Object Main" src={Image} />)}
                                </div>
                                <div className="col-flex m-auto p-3" style={{ textAlign: "left" }}>
                                    <TextSection SubHeadline={SubHeadline} Headline={Headline} Description={Description} Story={blok}/>
                                </div>
                            </div>
                        </>
                    )}
                    </div>
                </div>
            </div>

        </>
    )
}

export default MediaObjectItem;