import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useEffect, useState} from "react"

export default function ColSection({blok}) {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  return (
    <div className={`${blok.background_color}`}>
      <div className='flex-center component-spacing' {...storyblokEditable(blok)}>
            <div className={windowSize.innerWidth < 550 ? `flex-center flex-wrap-mobile ${blok.aling_content} p-3` : `flex-center gap-5 p-3 ${blok.aling_content}`}>
                  <div className={blok.column_2.length > 0 ? blok.column_1_size : 'flex-basis-10 mobile-width-350'}>
              {
                  blok.column_1.map((nestedBlok) => {
                      return nestedBlok?.Header?.map((nestedBlok) => {
                          return <div key={nestedBlok._uid}>
                              <StoryblokComponent blok={nestedBlok} />
                          </div>
                      })
                  })
              }
              {
                  blok.column_1.map((nestedBlok) => {
                      if (nestedBlok.Cards) {
                          return nestedBlok?.Cards.map((nestedBlok, index) => {
                              return <div key={nestedBlok._uid}>
                                  <StoryblokComponent blok={nestedBlok} />
                                  {

                                      blok.column_1[0].Cards.length - 1 !== index && <span className=" flex divider my-2" style={{ width: "70%" }}></span>
                                  }
                              </div>
                          })
                      } else {
                         return <div key={nestedBlok._uid}>
                                  <StoryblokComponent blok={nestedBlok} />
                              </div>
                      }
                  })
              }
          </div>
          {
              blok.column_2.length > 0 && 
                      <div className={blok.column_1_size == 'flex-basis-3-full' ? 'flex-basis-7-full' : blok.column_1_size == 'flex-basis-6-full' ? 'flex-basis-4-full' :`flex-basis-5-full`}>
                  {
                      blok.column_2.map((nestedBlok) => {
                      return    <div key={nestedBlok._uid}>
                              <StoryblokComponent blok={nestedBlok} />
                          </div>
                      })
                  }
              </div>
          }
          </div>
          </div>
      </div>
  )
}
